const PCCS_Color_Calc_R206 = {
1: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_L",
61: "R_L",
66: "R_L",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "RP_R",
101: "RP_R",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_L",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
6: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_L",
61: "R_L",
66: "R_L",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "RP_R",
101: "RP_R",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_L",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
11: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_L",
61: "R_L",
66: "R_L",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "RP_R",
101: "RP_R",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_L",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
16: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_L",
61: "R_L",
66: "R_L",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "RP_R",
101: "RP_R",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_L",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
21: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_L",
61: "R_L",
66: "R_L",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "RP_R",
101: "RP_R",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_L",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
26: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_L",
66: "R_L",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "RP_R",
101: "RP_R",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_L",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
31: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_L",
66: "R_L",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "RP_R",
101: "RP_R",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_L",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
36: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_L",
66: "R_L",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "RP_R",
101: "RP_R",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_L",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
41: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_R",
66: "R_L",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "R_L",
101: "RP_R",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_R",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
46: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_R",
66: "R_L",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "R_L",
101: "RP_R",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_R",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
51: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_R",
66: "R_L",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "R_L",
101: "RP_R",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_R",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
56: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_R",
66: "R_R",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "R_L",
101: "RP_R",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_R",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
61: {1: "R_R",
6: "R_R",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_R",
66: "R_R",
71: "R_L",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "R_L",
101: "R_L",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_R",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
66: {1: "YR_L",
6: "YR_L",
11: "R_R",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_R",
66: "R_R",
71: "R_R",
76: "R_L",
81: "R_L",
86: "R_L",
91: "R_L",
96: "R_L",
101: "R_L",
106: "RP_R",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_R",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
71: {1: "YR_L",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_R",
66: "R_R",
71: "R_R",
76: "R_R",
81: "R_L",
86: "R_L",
91: "R_L",
96: "R_L",
101: "R_L",
106: "R_L",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_R",
136: "RP_L",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
76: {1: "YR_L",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "YR_L",
26: "YR_L",
31: "R_R",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_R",
66: "R_R",
71: "R_R",
76: "R_R",
81: "R_L",
86: "R_L",
91: "R_L",
96: "R_L",
101: "R_L",
106: "R_L",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_R",
136: "RP_R",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "N",
241: "N",
246: "N",
251: "N",
},
81: {1: "YR_L",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "YR_L",
26: "YR_L",
31: "YR_L",
36: "YR_L",
41: "R_R",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_R",
66: "R_R",
71: "R_R",
76: "R_R",
81: "R_R",
86: "R_L",
91: "R_L",
96: "R_L",
101: "R_L",
106: "R_L",
111: "RP_R",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_R",
136: "RP_R",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_R",
241: "N",
246: "N",
251: "N",
},
86: {1: "YR_L",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "YR_L",
26: "YR_L",
31: "YR_L",
36: "YR_L",
41: "YR_L",
46: "R_R",
51: "R_R",
56: "R_R",
61: "R_R",
66: "R_R",
71: "R_R",
76: "R_R",
81: "R_R",
86: "R_R",
91: "R_L",
96: "R_L",
101: "R_L",
106: "R_L",
111: "R_L",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_R",
136: "RP_R",
141: "RP_L",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_R",
241: "P_R",
246: "P_R",
251: "N",
},
91: {1: "YR_L",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "YR_L",
26: "YR_L",
31: "YR_L",
36: "YR_L",
41: "YR_L",
46: "YR_L",
51: "YR_L",
56: "R_R",
61: "R_R",
66: "R_R",
71: "R_R",
76: "R_R",
81: "R_R",
86: "R_R",
91: "R_L",
96: "R_L",
101: "R_L",
106: "R_L",
111: "R_L",
116: "RP_R",
121: "RP_R",
126: "RP_R",
131: "RP_R",
136: "RP_R",
141: "RP_R",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_R",
241: "P_R",
246: "P_R",
251: "P_R",
},
96: {1: "YR_L",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "YR_L",
26: "YR_L",
31: "YR_L",
36: "YR_L",
41: "YR_L",
46: "YR_L",
51: "YR_L",
56: "YR_L",
61: "R_R",
66: "R_R",
71: "R_R",
76: "R_R",
81: "R_R",
86: "R_R",
91: "R_R",
96: "R_L",
101: "R_L",
106: "R_L",
111: "R_L",
116: "R_L",
121: "RP_R",
126: "RP_R",
131: "RP_R",
136: "RP_R",
141: "RP_R",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_R",
241: "P_R",
246: "P_R",
251: "P_R",
},
101: {1: "YR_L",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "YR_L",
26: "YR_L",
31: "YR_L",
36: "YR_L",
41: "YR_L",
46: "YR_L",
51: "YR_L",
56: "YR_L",
61: "YR_L",
66: "YR_L",
71: "R_R",
76: "R_R",
81: "R_R",
86: "R_R",
91: "R_R",
96: "R_R",
101: "R_L",
106: "R_L",
111: "R_L",
116: "R_L",
121: "R_L",
126: "RP_R",
131: "RP_R",
136: "RP_R",
141: "RP_R",
146: "RP_L",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "P_R",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_R",
241: "P_R",
246: "N",
251: "N",
},
106: {1: "YR_L",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "YR_L",
26: "YR_L",
31: "YR_L",
36: "YR_L",
41: "YR_L",
46: "YR_L",
51: "YR_L",
56: "YR_L",
61: "YR_L",
66: "YR_L",
71: "YR_L",
76: "R_R",
81: "R_R",
86: "R_R",
91: "R_R",
96: "R_R",
101: "R_R",
106: "R_L",
111: "R_L",
116: "R_L",
121: "R_L",
126: "RP_R",
131: "RP_R",
136: "RP_R",
141: "RP_R",
146: "RP_R",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "RP_L",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
111: {1: "YR_R",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "YR_L",
26: "YR_L",
31: "YR_L",
36: "YR_L",
41: "YR_L",
46: "YR_L",
51: "YR_L",
56: "YR_L",
61: "YR_L",
66: "YR_L",
71: "YR_L",
76: "YR_L",
81: "R_R",
86: "R_R",
91: "R_R",
96: "R_R",
101: "R_R",
106: "R_R",
111: "R_L",
116: "R_L",
121: "R_L",
126: "R_L",
131: "RP_R",
136: "RP_R",
141: "RP_R",
146: "RP_R",
151: "RP_L",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "RP_L",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
116: {1: "YR_R",
6: "YR_R",
11: "YR_R",
16: "YR_R",
21: "YR_R",
26: "YR_R",
31: "YR_R",
36: "YR_L",
41: "YR_L",
46: "YR_L",
51: "YR_L",
56: "YR_L",
61: "YR_L",
66: "YR_L",
71: "YR_L",
76: "YR_L",
81: "YR_L",
86: "YR_L",
91: "R_R",
96: "R_R",
101: "R_R",
106: "R_R",
111: "R_R",
116: "R_L",
121: "R_L",
126: "R_L",
131: "RP_R",
136: "RP_R",
141: "RP_R",
146: "RP_R",
151: "RP_R",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "RP_L",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
121: {1: "YR_R",
6: "YR_R",
11: "YR_R",
16: "YR_R",
21: "YR_R",
26: "YR_R",
31: "YR_R",
36: "YR_R",
41: "YR_R",
46: "YR_R",
51: "YR_L",
56: "YR_L",
61: "YR_L",
66: "YR_L",
71: "YR_L",
76: "YR_L",
81: "YR_L",
86: "YR_L",
91: "YR_L",
96: "R_R",
101: "R_R",
106: "R_R",
111: "R_R",
116: "R_R",
121: "R_L",
126: "R_L",
131: "R_L",
136: "RP_R",
141: "RP_R",
146: "RP_R",
151: "RP_R",
156: "RP_L",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "RP_L",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
126: {1: "YR_R",
6: "YR_R",
11: "YR_R",
16: "YR_R",
21: "YR_R",
26: "YR_R",
31: "YR_R",
36: "YR_R",
41: "YR_R",
46: "YR_R",
51: "YR_R",
56: "YR_R",
61: "YR_R",
66: "YR_L",
71: "YR_L",
76: "YR_L",
81: "YR_L",
86: "YR_L",
91: "YR_L",
96: "YR_L",
101: "R_R",
106: "R_R",
111: "R_R",
116: "R_R",
121: "R_R",
126: "R_L",
131: "R_L",
136: "R_L",
141: "RP_R",
146: "RP_R",
151: "RP_R",
156: "RP_R",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "RP_L",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "N",
241: "N",
246: "N",
251: "N",
},
131: {1: "YR_R",
6: "YR_R",
11: "YR_R",
16: "YR_R",
21: "YR_R",
26: "YR_R",
31: "YR_R",
36: "YR_R",
41: "YR_R",
46: "YR_R",
51: "YR_R",
56: "YR_R",
61: "YR_R",
66: "YR_R",
71: "YR_R",
76: "YR_L",
81: "YR_L",
86: "YR_L",
91: "YR_L",
96: "YR_L",
101: "YR_L",
106: "YR_L",
111: "R_R",
116: "R_R",
121: "R_R",
126: "R_R",
131: "R_L",
136: "R_L",
141: "R_L",
146: "RP_R",
151: "RP_R",
156: "RP_R",
161: "RP_L",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "RP_L",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_R",
241: "P_R",
246: "N",
251: "N",
},
136: {1: "YR_R",
6: "YR_R",
11: "YR_R",
16: "YR_R",
21: "YR_R",
26: "YR_R",
31: "YR_R",
36: "YR_R",
41: "YR_R",
46: "YR_R",
51: "YR_R",
56: "YR_R",
61: "YR_R",
66: "YR_R",
71: "YR_R",
76: "YR_R",
81: "YR_R",
86: "YR_L",
91: "YR_L",
96: "YR_L",
101: "YR_L",
106: "YR_L",
111: "YR_L",
116: "R_R",
121: "R_R",
126: "R_R",
131: "R_R",
136: "R_L",
141: "R_L",
146: "RP_R",
151: "RP_R",
156: "RP_R",
161: "RP_R",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "RP_L",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_R",
241: "P_R",
246: "P_R",
251: "N",
},
141: {1: "YR_R",
6: "YR_R",
11: "YR_R",
16: "YR_R",
21: "YR_R",
26: "YR_R",
31: "YR_R",
36: "YR_R",
41: "YR_R",
46: "YR_R",
51: "YR_R",
56: "YR_R",
61: "YR_R",
66: "YR_R",
71: "YR_R",
76: "YR_R",
81: "YR_R",
86: "YR_R",
91: "YR_R",
96: "YR_L",
101: "YR_L",
106: "YR_L",
111: "YR_L",
116: "YR_L",
121: "R_R",
126: "R_R",
131: "R_R",
136: "R_R",
141: "R_L",
146: "R_L",
151: "RP_R",
156: "RP_R",
161: "RP_R",
166: "RP_L",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "RP_L",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_R",
241: "P_R",
246: "P_L",
251: "N",
},
146: {1: "Y_L",
6: "Y_L",
11: "Y_L",
16: "Y_L",
21: "Y_L",
26: "Y_L",
31: "Y_L",
36: "Y_L",
41: "Y_L",
46: "YR_R",
51: "YR_R",
56: "YR_R",
61: "YR_R",
66: "YR_R",
71: "YR_R",
76: "YR_R",
81: "YR_R",
86: "YR_R",
91: "YR_R",
96: "YR_R",
101: "YR_R",
106: "YR_L",
111: "YR_L",
116: "YR_L",
121: "YR_L",
126: "YR_L",
131: "R_R",
136: "R_R",
141: "R_R",
146: "R_L",
151: "R_L",
156: "RP_R",
161: "RP_R",
166: "RP_R",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "RP_L",
191: "P_R",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_R",
241: "P_R",
246: "P_L",
251: "N",
},
151: {1: "Y_L",
6: "Y_L",
11: "Y_L",
16: "Y_L",
21: "Y_L",
26: "Y_L",
31: "Y_L",
36: "Y_L",
41: "Y_L",
46: "Y_L",
51: "Y_L",
56: "Y_L",
61: "Y_L",
66: "YR_R",
71: "YR_R",
76: "YR_R",
81: "YR_R",
86: "YR_R",
91: "YR_R",
96: "YR_R",
101: "YR_R",
106: "YR_R",
111: "YR_R",
116: "YR_L",
121: "YR_L",
126: "YR_L",
131: "YR_L",
136: "R_R",
141: "R_R",
146: "R_R",
151: "R_L",
156: "R_L",
161: "RP_R",
166: "RP_R",
171: "RP_L",
176: "RP_L",
181: "RP_L",
186: "RP_L",
191: "RP_L",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_R",
241: "P_R",
246: "P_L",
251: "N",
},
156: {1: "Y_L",
6: "Y_L",
11: "Y_L",
16: "Y_L",
21: "Y_L",
26: "Y_L",
31: "Y_L",
36: "Y_L",
41: "Y_L",
46: "Y_L",
51: "Y_L",
56: "Y_L",
61: "Y_L",
66: "Y_L",
71: "Y_L",
76: "Y_L",
81: "Y_L",
86: "YR_R",
91: "YR_R",
96: "YR_R",
101: "YR_R",
106: "YR_R",
111: "YR_R",
116: "YR_R",
121: "YR_R",
126: "YR_L",
131: "YR_L",
136: "YR_L",
141: "R_R",
146: "R_R",
151: "R_R",
156: "R_L",
161: "R_L",
166: "RP_R",
171: "RP_R",
176: "RP_L",
181: "RP_L",
186: "RP_L",
191: "RP_L",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_R",
241: "P_L",
246: "N",
251: "N",
},
161: {1: "Y_L",
6: "Y_L",
11: "Y_L",
16: "Y_L",
21: "Y_L",
26: "Y_L",
31: "Y_L",
36: "Y_L",
41: "Y_L",
46: "Y_L",
51: "Y_L",
56: "Y_L",
61: "Y_L",
66: "Y_L",
71: "Y_L",
76: "Y_L",
81: "Y_L",
86: "Y_L",
91: "Y_L",
96: "Y_L",
101: "YR_R",
106: "YR_R",
111: "YR_R",
116: "YR_R",
121: "YR_R",
126: "YR_R",
131: "YR_R",
136: "YR_L",
141: "YR_L",
146: "YR_L",
151: "R_R",
156: "R_R",
161: "R_L",
166: "RP_R",
171: "RP_R",
176: "RP_L",
181: "RP_L",
186: "RP_L",
191: "RP_L",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_R",
241: "P_L",
246: "N",
251: "N",
},
166: {1: "Y_L",
6: "Y_L",
11: "Y_L",
16: "Y_L",
21: "Y_L",
26: "Y_L",
31: "Y_L",
36: "Y_L",
41: "Y_L",
46: "Y_L",
51: "Y_L",
56: "Y_L",
61: "Y_L",
66: "Y_L",
71: "Y_L",
76: "Y_L",
81: "Y_L",
86: "Y_L",
91: "Y_L",
96: "Y_L",
101: "Y_L",
106: "Y_L",
111: "YR_R",
116: "YR_R",
121: "YR_R",
126: "YR_R",
131: "YR_R",
136: "YR_R",
141: "YR_L",
146: "YR_L",
151: "YR_L",
156: "R_R",
161: "R_R",
166: "R_L",
171: "RP_R",
176: "RP_R",
181: "RP_L",
186: "RP_L",
191: "RP_L",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_L",
241: "N",
246: "N",
251: "N",
},
171: {1: "Y_L",
6: "Y_L",
11: "Y_L",
16: "Y_L",
21: "Y_L",
26: "Y_L",
31: "Y_L",
36: "Y_L",
41: "Y_L",
46: "Y_L",
51: "Y_L",
56: "Y_L",
61: "Y_L",
66: "Y_L",
71: "Y_L",
76: "Y_L",
81: "Y_L",
86: "Y_L",
91: "Y_L",
96: "Y_L",
101: "Y_L",
106: "Y_L",
111: "Y_L",
116: "Y_L",
121: "Y_L",
126: "YR_R",
131: "YR_R",
136: "YR_R",
141: "YR_R",
146: "YR_R",
151: "YR_L",
156: "YR_L",
161: "R_R",
166: "R_R",
171: "R_L",
176: "RP_R",
181: "RP_R",
186: "RP_L",
191: "RP_L",
196: "P_R",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_R",
236: "P_L",
241: "P_L",
246: "N",
251: "N",
},
176: {1: "Y_R",
6: "Y_R",
11: "Y_R",
16: "Y_R",
21: "Y_R",
26: "Y_R",
31: "Y_R",
36: "Y_R",
41: "Y_R",
46: "Y_R",
51: "Y_R",
56: "Y_R",
61: "Y_L",
66: "Y_L",
71: "Y_L",
76: "Y_L",
81: "Y_L",
86: "Y_L",
91: "Y_L",
96: "Y_L",
101: "Y_L",
106: "Y_L",
111: "Y_L",
116: "Y_L",
121: "Y_L",
126: "Y_L",
131: "Y_L",
136: "YR_R",
141: "YR_R",
146: "YR_R",
151: "YR_R",
156: "YR_R",
161: "YR_L",
166: "YR_L",
171: "R_R",
176: "R_L",
181: "RP_R",
186: "RP_L",
191: "RP_L",
196: "RP_L",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_L",
236: "P_L",
241: "P_L",
246: "P_L",
251: "P_L",
},
181: {1: "Y_R",
6: "Y_R",
11: "Y_R",
16: "Y_R",
21: "Y_R",
26: "Y_R",
31: "Y_R",
36: "Y_R",
41: "Y_R",
46: "Y_R",
51: "Y_R",
56: "Y_R",
61: "Y_R",
66: "Y_R",
71: "Y_R",
76: "Y_R",
81: "Y_R",
86: "Y_R",
91: "Y_L",
96: "Y_L",
101: "Y_L",
106: "Y_L",
111: "Y_L",
116: "Y_L",
121: "Y_L",
126: "Y_L",
131: "Y_L",
136: "Y_L",
141: "Y_L",
146: "Y_L",
151: "YR_R",
156: "YR_R",
161: "YR_R",
166: "YR_L",
171: "YR_L",
176: "R_R",
181: "R_L",
186: "RP_R",
191: "RP_L",
196: "RP_L",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_R",
231: "P_L",
236: "P_L",
241: "P_L",
246: "P_L",
251: "P_L",
},
186: {1: "Y_R",
6: "Y_R",
11: "Y_R",
16: "Y_R",
21: "Y_R",
26: "Y_R",
31: "Y_R",
36: "Y_R",
41: "Y_R",
46: "Y_R",
51: "Y_R",
56: "Y_R",
61: "Y_R",
66: "Y_R",
71: "Y_R",
76: "Y_R",
81: "Y_R",
86: "Y_R",
91: "Y_R",
96: "Y_R",
101: "Y_R",
106: "Y_R",
111: "Y_R",
116: "Y_L",
121: "Y_L",
126: "Y_L",
131: "Y_L",
136: "Y_L",
141: "Y_L",
146: "Y_L",
151: "Y_L",
156: "Y_L",
161: "YR_R",
166: "YR_R",
171: "YR_R",
176: "YR_L",
181: "R_R",
186: "R_L",
191: "RP_R",
196: "RP_L",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_R",
226: "P_L",
231: "P_L",
236: "P_L",
241: "P_L",
246: "P_L",
251: "P_L",
},
191: {1: "Y_R",
6: "Y_R",
11: "Y_R",
16: "Y_R",
21: "Y_R",
26: "Y_R",
31: "Y_R",
36: "Y_R",
41: "Y_R",
46: "Y_R",
51: "Y_R",
56: "Y_R",
61: "Y_R",
66: "Y_R",
71: "Y_R",
76: "Y_R",
81: "Y_R",
86: "Y_R",
91: "Y_R",
96: "Y_R",
101: "Y_R",
106: "Y_R",
111: "Y_R",
116: "Y_R",
121: "Y_R",
126: "Y_R",
131: "Y_R",
136: "Y_R",
141: "Y_L",
146: "Y_L",
151: "Y_L",
156: "Y_L",
161: "Y_L",
166: "Y_L",
171: "Y_L",
176: "YR_R",
181: "YR_R",
186: "YR_L",
191: "R_L",
196: "RP_L",
201: "P_R",
206: "P_R",
211: "P_R",
216: "P_R",
221: "P_L",
226: "P_L",
231: "P_L",
236: "P_L",
241: "P_L",
246: "P_L",
251: "P_L",
},
196: {1: "Y_R",
6: "Y_R",
11: "Y_R",
16: "Y_R",
21: "Y_R",
26: "Y_R",
31: "Y_R",
36: "Y_R",
41: "Y_R",
46: "Y_R",
51: "Y_R",
56: "Y_R",
61: "Y_R",
66: "Y_R",
71: "Y_R",
76: "Y_R",
81: "Y_R",
86: "Y_R",
91: "Y_R",
96: "Y_R",
101: "Y_R",
106: "Y_R",
111: "Y_R",
116: "Y_R",
121: "Y_R",
126: "Y_R",
131: "Y_R",
136: "Y_R",
141: "Y_R",
146: "Y_R",
151: "Y_R",
156: "Y_R",
161: "Y_R",
166: "Y_L",
171: "Y_L",
176: "Y_L",
181: "Y_L",
186: "YR_R",
191: "YR_L",
196: "R_L",
201: "RP_L",
206: "P_R",
211: "P_R",
216: "P_L",
221: "P_L",
226: "P_L",
231: "P_L",
236: "P_L",
241: "P_L",
246: "P_L",
251: "N",
},
201: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_L",
56: "GY_L",
61: "GY_L",
66: "GY_L",
71: "GY_L",
76: "GY_L",
81: "GY_L",
86: "GY_L",
91: "GY_L",
96: "GY_L",
101: "GY_L",
106: "GY_L",
111: "GY_L",
116: "GY_L",
121: "Y_R",
126: "Y_R",
131: "Y_R",
136: "Y_R",
141: "Y_R",
146: "Y_R",
151: "Y_R",
156: "Y_R",
161: "Y_R",
166: "Y_R",
171: "Y_R",
176: "Y_R",
181: "Y_R",
186: "Y_L",
191: "Y_L",
196: "YR_R",
201: "R_L",
206: "P_R",
211: "P_R",
216: "P_L",
221: "P_L",
226: "P_L",
231: "P_L",
236: "P_L",
241: "P_L",
246: "P_L",
251: "P_L",
},
206: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_L",
56: "GY_L",
61: "GY_L",
66: "GY_L",
71: "GY_L",
76: "GY_L",
81: "GY_L",
86: "GY_L",
91: "GY_L",
96: "GY_L",
101: "GY_L",
106: "GY_L",
111: "GY_L",
116: "GY_L",
121: "GY_L",
126: "GY_L",
131: "GY_L",
136: "GY_L",
141: "GY_L",
146: "GY_L",
151: "GY_L",
156: "GY_L",
161: "GY_L",
166: "GY_L",
171: "GY_L",
176: "GY_L",
181: "GY_L",
186: "GY_L",
191: "GY_L",
196: "GY_L",
201: "GY_L",
206: "N",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "N",
231: "N",
236: "PB_R",
241: "P_L",
246: "P_L",
251: "P_L",
},
211: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_L",
56: "GY_L",
61: "GY_L",
66: "GY_L",
71: "GY_L",
76: "GY_L",
81: "GY_L",
86: "GY_L",
91: "GY_L",
96: "GY_L",
101: "GY_L",
106: "GY_L",
111: "GY_L",
116: "GY_L",
121: "GY_L",
126: "GY_L",
131: "GY_L",
136: "GY_L",
141: "GY_L",
146: "GY_L",
151: "GY_L",
156: "GY_L",
161: "GY_L",
166: "GY_L",
171: "GY_L",
176: "GY_L",
181: "GY_L",
186: "GY_R",
191: "GY_R",
196: "GY_R",
201: "GY_R",
206: "G_L",
211: "B_L",
216: "PB_L",
221: "PB_R",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
216: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_L",
56: "GY_L",
61: "GY_L",
66: "GY_L",
71: "GY_L",
76: "GY_L",
81: "GY_L",
86: "GY_L",
91: "GY_L",
96: "GY_L",
101: "GY_L",
106: "GY_L",
111: "GY_L",
116: "GY_L",
121: "GY_L",
126: "GY_L",
131: "GY_L",
136: "GY_L",
141: "GY_L",
146: "GY_L",
151: "GY_L",
156: "GY_L",
161: "GY_L",
166: "GY_R",
171: "GY_R",
176: "GY_R",
181: "GY_R",
186: "GY_R",
191: "GY_R",
196: "GY_R",
201: "GY_R",
206: "G_L",
211: "G_R",
216: "B_L",
221: "PB_L",
226: "PB_L",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
221: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_L",
56: "GY_L",
61: "GY_L",
66: "GY_L",
71: "GY_L",
76: "GY_L",
81: "GY_L",
86: "GY_L",
91: "GY_L",
96: "GY_L",
101: "GY_L",
106: "GY_L",
111: "GY_L",
116: "GY_L",
121: "GY_L",
126: "GY_L",
131: "GY_L",
136: "GY_L",
141: "GY_L",
146: "GY_L",
151: "GY_R",
156: "GY_R",
161: "GY_R",
166: "GY_R",
171: "GY_R",
176: "GY_R",
181: "GY_R",
186: "GY_R",
191: "GY_R",
196: "GY_R",
201: "GY_R",
206: "G_L",
211: "G_L",
216: "BG_L",
221: "B_L",
226: "B_R",
231: "PB_L",
236: "N",
241: "N",
246: "N",
251: "N",
},
226: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_L",
56: "GY_L",
61: "GY_L",
66: "GY_L",
71: "GY_L",
76: "GY_L",
81: "GY_L",
86: "GY_L",
91: "GY_L",
96: "GY_L",
101: "GY_L",
106: "GY_L",
111: "GY_L",
116: "GY_L",
121: "GY_L",
126: "GY_L",
131: "GY_R",
136: "GY_R",
141: "GY_R",
146: "GY_R",
151: "GY_R",
156: "GY_R",
161: "GY_R",
166: "GY_R",
171: "GY_R",
176: "GY_R",
181: "GY_R",
186: "GY_R",
191: "GY_R",
196: "GY_R",
201: "GY_R",
206: "G_L",
211: "G_L",
216: "G_R",
221: "BG_L",
226: "B_L",
231: "B_R",
236: "PB_L",
241: "N",
246: "N",
251: "N",
},
231: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_L",
56: "GY_L",
61: "GY_L",
66: "GY_L",
71: "GY_L",
76: "GY_L",
81: "GY_L",
86: "GY_L",
91: "GY_L",
96: "GY_L",
101: "GY_L",
106: "GY_L",
111: "GY_R",
116: "GY_R",
121: "GY_R",
126: "GY_R",
131: "GY_R",
136: "GY_R",
141: "GY_R",
146: "GY_R",
151: "GY_R",
156: "GY_R",
161: "GY_R",
166: "GY_R",
171: "GY_R",
176: "GY_R",
181: "GY_R",
186: "GY_R",
191: "GY_R",
196: "GY_R",
201: "GY_R",
206: "G_L",
211: "G_L",
216: "G_R",
221: "G_R",
226: "BG_R",
231: "B_L",
236: "B_L",
241: "B_R",
246: "N",
251: "N",
},
236: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_L",
56: "GY_L",
61: "GY_L",
66: "GY_L",
71: "GY_L",
76: "GY_L",
81: "GY_L",
86: "GY_L",
91: "GY_R",
96: "GY_R",
101: "GY_R",
106: "GY_R",
111: "GY_R",
116: "GY_R",
121: "GY_R",
126: "GY_R",
131: "GY_R",
136: "GY_R",
141: "GY_R",
146: "GY_R",
151: "GY_R",
156: "GY_R",
161: "GY_R",
166: "GY_R",
171: "GY_R",
176: "GY_R",
181: "GY_R",
186: "GY_R",
191: "GY_R",
196: "GY_R",
201: "G_L",
206: "G_L",
211: "G_L",
216: "G_L",
221: "G_R",
226: "BG_L",
231: "BG_R",
236: "B_L",
241: "B_L",
246: "B_R",
251: "N",
},
241: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_L",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "GY_R",
91: "GY_R",
96: "GY_R",
101: "GY_R",
106: "GY_R",
111: "GY_R",
116: "GY_R",
121: "GY_R",
126: "GY_R",
131: "GY_R",
136: "GY_R",
141: "GY_R",
146: "GY_R",
151: "GY_R",
156: "GY_R",
161: "GY_R",
166: "GY_R",
171: "GY_R",
176: "GY_R",
181: "GY_R",
186: "GY_R",
191: "GY_R",
196: "GY_R",
201: "G_L",
206: "G_L",
211: "G_L",
216: "G_L",
221: "G_R",
226: "G_R",
231: "BG_L",
236: "BG_R",
241: "B_L",
246: "B_L",
251: "B_R",
},
246: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "GY_R",
91: "GY_R",
96: "GY_R",
101: "GY_R",
106: "GY_R",
111: "GY_R",
116: "GY_R",
121: "GY_R",
126: "GY_R",
131: "GY_R",
136: "GY_R",
141: "GY_R",
146: "GY_R",
151: "GY_R",
156: "GY_R",
161: "GY_R",
166: "GY_R",
171: "GY_R",
176: "GY_R",
181: "GY_R",
186: "GY_R",
191: "GY_R",
196: "GY_R",
201: "G_L",
206: "G_L",
211: "G_L",
216: "G_L",
221: "G_L",
226: "G_R",
231: "G_R",
236: "BG_L",
241: "BG_R",
246: "B_L",
251: "B_L",
},
251: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "GY_R",
91: "GY_R",
96: "GY_R",
101: "GY_R",
106: "GY_R",
111: "GY_R",
116: "GY_R",
121: "GY_R",
126: "GY_R",
131: "GY_R",
136: "GY_R",
141: "GY_R",
146: "GY_R",
151: "GY_R",
156: "GY_R",
161: "GY_R",
166: "GY_R",
171: "GY_R",
176: "GY_R",
181: "GY_R",
186: "GY_R",
191: "GY_R",
196: "GY_R",
201: "G_L",
206: "G_L",
211: "G_L",
216: "G_L",
221: "G_L",
226: "G_R",
231: "G_R",
236: "BG_L",
241: "BG_L",
246: "BG_R",
251: "B_L",
}}

export default PCCS_Color_Calc_R206
    