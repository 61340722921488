<template>
  <v-main>
    <v-container class="m-auto">
      <div v-if="suggestedColors.length !== 0">
        <div v-for="c of suggestedColors" v-bind:key="c.label"
             class="flex">
          <div class="detail_box col-3 p-0">
            <suggest-image size="2rem" :angles="[...c.angles, 0]"/>

          </div>
          <div class="detail_box col-2 p-0"
               :style="{background: getRGBHex(targetColor)}"></div>
          <div class="detail_box col-7 p-0">
            <div v-bind:class="'col-4 p-0 h-full grid grid-cols-' + c.colors.length">
              <div
                v-for="(rgb, index) in c.colors"
                v-bind:key="index"
                @click="updateSuggestionColorIndex(c.label, index)"
                :style="{background: getRGBHex(rgb), borderColor: '#f00', borderWidth: index === selectedSuggestionIndex[c.label] ? 'thin' : ''}">
              </div>
            </div>
            <div class="col-8 p-0"
                 style="display: flex;
                        justify-content: center;
                        align-items: center;">
              {{ getColorStrings(c.colors[selectedSuggestionIndex[c.label]]) }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        候補色が見つかりません。
      </div>
    </v-container>
    <v-footer>
      <v-spacer/>
      <v-btn
        color="default"
        @click="closeWindow()"
      >
        <h3>閉じる</h3>
      </v-btn>
      <v-spacer/>
    </v-footer>
  </v-main>
</template>

<script>
import SuggestImage from "@/components/SuggestImage.vue";
import {suggestRGB} from "@/utils/suggest/suggest";
import chromajs from "chroma-js";

export default {
  components: {SuggestImage},
  data: () => {
    return {
      suggestedColors: undefined,
      selectedSuggestionIndex: {},
    }
  },
  created() {
    this.suggestColors()
  },
  computed: {
    targetColor() {
      return {
        r: parseInt(this.$route.query.r) || 255,
        g: parseInt(this.$route.query.g) || 0,
        b: parseInt(this.$route.query.b) || 0,
        a: 255
      };
    },
    strict() {
      return parseInt(this.$route.query.strict) || 0
    },
    background() {
      return parseInt(this.$route.query.background) || 0
    },
    judgeMode() {
      return parseInt(this.$route.query.judgeMode) || 0
    },
    colorDisplayMode() {
      return this.$route.query.colorDisplayMode || "lab"
    }
  },
  methods: {
    getColorStrings(color) {
      if (color === null || color === undefined) return ""

      const chroma = chromajs(color.r, color.g, color.b)
      switch (this.colorDisplayMode) {
        case "lab":
          var lab = chromajs(color.r, color.g, color.b).lab()
          return `L: ${lab[0].toFixed(0)} a:${lab[1].toFixed(0)} b: ${lab[2].toFixed(0)}`
        case "cmyk":
          var cmyk = chroma.cmyk();
          return `C: ${cmyk[0].toFixed(2) * 100} M:  ${cmyk[1].toFixed(2) * 100} Y: ${cmyk[2].toFixed(2) * 100} K: ${cmyk[3].toFixed(2) * 100}`
        case "rgb":
          return `R: ${color.r} G: ${color.g} B: ${color.b}`
      }
    },
    getRGBHex(color) {
      if (color === null || color === undefined) return ""
      let chroma = chromajs(color.r, color.g, color.b)
      return chroma.hex()
    },
    suggestColors() {
      this.suggestedColors = suggestRGB(this.targetColor, this.strict, this.background, this.judgeMode)
    },
    updateSuggestionColorIndex(label, index) {
      this.$set(this.selectedSuggestionIndex, label, index)
    },
    closeWindow() {
      if (window.opener) {
        window.close();
      } else {
        alert("このウィンドウは閉じられません（新しいタブとして開かれている可能性があります）。");
      }
    }
  }
}
</script>

<style>
.detail_box {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid;
  border-color: black;
}
</style>
