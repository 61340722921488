export const StrictMode = Object.freeze({
  Yurume: {
    label: "ゆるめ",
    value: 0
  },
  Futu: {
    label: "ふつう",
    value: 1
  },
  Kitume: {
    label: "きつめ",
    value: 2
  }
})

export const BackgroundMode = Object.freeze({
  Mei: {
    label: "明",
    value: 0
  },
  Chu: {
    label: "中",
    value: 1
  },
  An: {
    label: "暗",
    value: 2
  },
  Zentai: {
    label: "全体",
    value: 3
  }
})


export const JudgeMode = Object.freeze({
  Jitsuyou: {
    label: "実用レベル", // ◯△→ ◯, 〇x → △, △x → x
    value: 0,
  },
  Saiteigen: {
    label: "寛容",  // ◯△→ ◯, 〇x → ◯,  △x → △
    value: 1,
  },
  ChoGenmitsu: {
    label: "厳密",  // ◯△→ △, 〇x → x,  △x → x
    value: 2,
  },
})
