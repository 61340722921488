<template>
  <v-footer padless fixed>
    <v-toolbar style="height:100%" class="overflow-x-scroll" align-start>
      <div style="display: flex">
        <div class="my-auto mx-2 hidden md:block">
          シミュレーション
        </div>
        <v-select
          style="max-width: 150px"
          v-model="mode"
          @change="onChangeMode"
          :items="[
        {'text': 'C型', 'value': 0},
        {'text': 'P型②', 'value': 6},
        {'text': 'D型②', 'value': 7},
        {'text': '高齢者②', 'value': 8},
      ]"
          hide-details="auto"
        >
          <template v-slot:selection="{ item }">
            <span class="d-flex justify-center" style="width: 100%">
              {{ item.text }}
            </span>
          </template>
        </v-select>
      </div>

      <div style="display: flex">
        <div class="my-auto mx-2 hidden md:block">
          判定基準
        </div>
        <v-select
          style="max-width: 150px"
          v-model="strict"
          :items="Object.entries(StrictMode).map(([_, sm]) => {return {'obj': _,'text': sm.label, 'value': sm.value}})"
          hide-details="auto"
        >
          <template v-slot:selection="{ item }">
            <span class="d-flex justify-center" style="width: 100%">
              {{ item.text }}
            </span>
          </template>
        </v-select>
      </div>

      <div style="display: flex">
        <div class="my-auto mx-2 hidden md:block">
          背景
        </div>
        <v-select
          style="max-width: 150px"
          v-model="background"
          :items="Object.entries(BackgroundMode).map(([_, sm]) => {return {'obj': _,'text': sm.label, 'value': sm.value}})"
          hide-details="auto"
        >
          <template v-slot:selection="{ item }">
            <span class="d-flex justify-center" style="width: 100%">
              {{ item.text }}
            </span>
          </template>
        </v-select>
      </div>

      <div style="display: flex">
        <div class="my-auto mx-2 hidden md:block">
          表示
        </div>
        <v-select
          style="max-width: 150px"
          v-model="colorDisplayMode"
          :items="[
            {'text': 'Lab', 'value': 'lab'},
            {'text': 'CMYK', 'value': 'cmyk'},
            {'text': 'RGB', 'value': 'rgb'},
          ]"
          hide-details="auto"
        >
          <template v-slot:selection="{ item }">
            <span class="d-flex justify-center" style="width: 100%">
              {{ item.text }}
            </span>
          </template>
        </v-select>
      </div>

      <div style="display: flex">
        <div class="my-auto mx-2 hidden md:block">
          判定方法
        </div>
        <v-select
          style="max-width: 150px"
          v-model="judgeMode"
          :items="Object.entries(JudgeMode).map(([_, sm]) => {return {'obj': _,'text': sm.label, 'value': sm.value}})"
          hide-details="auto"
        >
          <template v-slot:selection="{ item }">
            <span class="d-flex justify-center" style="width: 100%">
              {{ item.text }}
            </span>
          </template>
        </v-select>
      </div>

      <v-spacer></v-spacer>
      <v-btn icon
             class="mx-2"
             @click="onClickCameraButton"
             elevation="2"
             large
      >
        <v-icon size="24px">mdi-camera</v-icon>
      </v-btn>
      <v-btn icon
             @click="onClickUploadButton"
             elevation="2"
             large
      >
        <v-icon size="24px">mdi-image</v-icon>
      </v-btn>
    </v-toolbar>
    <input
      style="display: none"
      ref="input_file"
      type="file"
      accept="image/jpeg, image/jpg, image/png"
      @change="onCaptureImage"
    >
    <input
      style="display: none"
      ref="input_camera"
      type="file"
      accept="image/*"
      capture="user"
      @change="onCaptureImage"
    >
  </v-footer>
</template>

<script>
import {BackgroundMode, JudgeMode, StrictMode} from "@/utils/judge/threshold/judge_data";

export default {
  name: 'FooterView',

  data: () => ({
    mode: 0,
    strict: StrictMode.Futu.value,
    background: BackgroundMode.Zentai.value,
    colorDisplayMode: "lab",
    judgeMode: JudgeMode.Jitsuyou.value
  }),
  mounted: function () {
  },
  watch: {
    mode: function (mode) {
      this.$store.commit('setMode', mode)
    },
    strict: function (strict) {
      this.$store.commit('setStrict', strict)
    },
    background: function (background) {
      this.$store.commit('setBackground', background)
    },
    judgeMode: function (judgeMode) {
      this.$store.commit('setJudgeMode', judgeMode)
    },
    colorDisplayMode: function (colorDisplayMode) {
      this.$store.commit('setColorDisplayMode', colorDisplayMode)
    }
  },
  computed: {
    JudgeMode() {
      return JudgeMode
    },
    BackgroundMode() {
      return BackgroundMode
    },
    StrictMode() {
      return StrictMode
    }
  },
  methods: {
    onChangeMode(value) {
      console.log('onChangeMode')
      this.setMode(value)
    },
    setMode(value) {
      // シミュレーションモードを設定
      console.log('setMode')
      this.$store.commit('setMode', value)
    },
    // アップロードボタンが押された
    // https://blog.capilano-fw.com/?p=8216
    onClickUploadButton() {
      console.log('onClickUploadButton')
      this.$refs.input_file.click()
    },
    // カメラボタンが押された
    onClickCameraButton() {
      console.log('onClickCameraButton')
      this.$refs.input_camera.click()
    },
    // inputでファイルが選択された
    onCaptureImage(value) {
      console.log('onCaptureImage')
      const file = value.target.files[0]
      if (!file) {
        console.log("Error")
        return
      }
      console.log("Success")
      this.$store.commit('setImageFile', file)
    }
  }
}
</script>

