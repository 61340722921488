const PCCS_Color_Calc_R86 = {
1: {1: "N",
6: "YR_L",
11: "YR_L",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_L",
41: "R_L",
46: "R_L",
51: "RP_R",
56: "RP_R",
61: "RP_R",
66: "RP_L",
71: "RP_L",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_R",
106: "P_R",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "P_L",
166: "P_L",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
6: {1: "N",
6: "N",
11: "YR_L",
16: "R_R",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_L",
41: "R_L",
46: "R_L",
51: "RP_R",
56: "RP_R",
61: "RP_R",
66: "RP_L",
71: "RP_L",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_R",
106: "P_R",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "P_L",
166: "P_L",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
11: {1: "YR_L",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_L",
41: "R_L",
46: "R_L",
51: "RP_R",
56: "RP_R",
61: "RP_R",
66: "RP_L",
71: "RP_L",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_R",
106: "P_R",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "P_L",
166: "P_L",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
16: {1: "YR_L",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "R_R",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_L",
46: "R_L",
51: "RP_R",
56: "RP_R",
61: "RP_R",
66: "RP_L",
71: "RP_L",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_R",
106: "P_R",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "P_L",
166: "P_L",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
21: {1: "YR_L",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "YR_L",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_L",
46: "R_L",
51: "RP_R",
56: "RP_R",
61: "RP_R",
66: "RP_L",
71: "RP_L",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_R",
106: "P_L",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "P_L",
166: "P_L",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
26: {1: "YR_L",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "YR_L",
26: "R_R",
31: "R_R",
36: "R_R",
41: "R_L",
46: "R_L",
51: "R_L",
56: "RP_R",
61: "RP_R",
66: "RP_L",
71: "RP_L",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_R",
106: "P_L",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "P_L",
166: "P_L",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
31: {1: "N",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "YR_L",
26: "YR_L",
31: "R_R",
36: "R_R",
41: "R_L",
46: "R_L",
51: "R_L",
56: "RP_R",
61: "RP_R",
66: "RP_L",
71: "RP_L",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_R",
106: "P_L",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "P_L",
166: "P_L",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
36: {1: "YR_R",
6: "YR_L",
11: "YR_L",
16: "YR_L",
21: "YR_L",
26: "YR_L",
31: "YR_L",
36: "R_R",
41: "R_R",
46: "R_L",
51: "R_L",
56: "RP_R",
61: "RP_R",
66: "RP_L",
71: "RP_L",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_R",
106: "P_L",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "P_L",
166: "P_L",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
41: {1: "YR_R",
6: "YR_R",
11: "YR_R",
16: "YR_L",
21: "YR_L",
26: "YR_L",
31: "YR_L",
36: "R_R",
41: "R_R",
46: "R_R",
51: "R_L",
56: "RP_R",
61: "RP_R",
66: "RP_L",
71: "RP_L",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_R",
106: "P_L",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "P_L",
166: "P_L",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
46: {1: "YR_R",
6: "YR_R",
11: "YR_R",
16: "YR_R",
21: "YR_R",
26: "YR_L",
31: "YR_L",
36: "YR_L",
41: "R_R",
46: "R_R",
51: "R_L",
56: "R_L",
61: "RP_R",
66: "RP_R",
71: "RP_L",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_R",
106: "P_L",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "P_L",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
51: {1: "YR_R",
6: "YR_R",
11: "YR_R",
16: "YR_R",
21: "YR_R",
26: "YR_R",
31: "YR_R",
36: "YR_L",
41: "YR_L",
46: "R_R",
51: "R_R",
56: "R_L",
61: "RP_R",
66: "RP_R",
71: "RP_L",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_L",
106: "P_L",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "P_L",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
56: {1: "Y_L",
6: "Y_L",
11: "Y_L",
16: "YR_R",
21: "YR_R",
26: "YR_R",
31: "YR_R",
36: "YR_R",
41: "YR_R",
46: "YR_L",
51: "YR_L",
56: "R_R",
61: "R_L",
66: "RP_R",
71: "RP_L",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_L",
106: "P_L",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "N",
},
61: {1: "Y_L",
6: "Y_L",
11: "Y_L",
16: "Y_L",
21: "Y_L",
26: "Y_L",
31: "Y_L",
36: "YR_R",
41: "YR_R",
46: "YR_R",
51: "YR_L",
56: "YR_L",
61: "R_R",
66: "RP_R",
71: "RP_R",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_R",
101: "P_L",
106: "P_L",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "P_L",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "N",
246: "N",
251: "N",
},
66: {1: "Y_L",
6: "Y_L",
11: "Y_L",
16: "Y_L",
21: "Y_L",
26: "Y_L",
31: "Y_L",
36: "Y_L",
41: "Y_L",
46: "YR_R",
51: "YR_R",
56: "YR_R",
61: "YR_L",
66: "R_L",
71: "RP_R",
76: "RP_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_L",
101: "P_L",
106: "P_L",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "P_L",
151: "P_L",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
71: {1: "Y_R",
6: "Y_R",
11: "Y_R",
16: "Y_R",
21: "Y_R",
26: "Y_R",
31: "Y_L",
36: "Y_L",
41: "Y_L",
46: "Y_L",
51: "Y_L",
56: "YR_R",
61: "YR_R",
66: "YR_L",
71: "R_L",
76: "RP_R",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_L",
101: "P_L",
106: "P_L",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "P_L",
141: "P_L",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
76: {1: "Y_R",
6: "Y_R",
11: "Y_R",
16: "Y_R",
21: "Y_R",
26: "Y_R",
31: "Y_R",
36: "Y_R",
41: "Y_R",
46: "Y_R",
51: "Y_L",
56: "Y_L",
61: "Y_L",
66: "Y_L",
71: "YR_R",
76: "R_L",
81: "RP_L",
86: "P_R",
91: "P_R",
96: "P_L",
101: "P_L",
106: "P_L",
111: "P_L",
116: "P_L",
121: "P_L",
126: "P_L",
131: "P_L",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
81: {1: "Y_R",
6: "Y_R",
11: "Y_R",
16: "Y_R",
21: "Y_R",
26: "Y_R",
31: "Y_R",
36: "Y_R",
41: "Y_R",
46: "Y_R",
51: "Y_R",
56: "Y_R",
61: "Y_R",
66: "Y_R",
71: "Y_L",
76: "Y_L",
81: "R_L",
86: "P_R",
91: "P_L",
96: "P_L",
101: "P_L",
106: "P_L",
111: "P_L",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
86: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_L",
56: "GY_L",
61: "GY_L",
66: "GY_L",
71: "GY_L",
76: "GY_L",
81: "GY_L",
86: "N",
91: "PB_R",
96: "PB_R",
101: "PB_R",
106: "PB_R",
111: "PB_R",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "N",
241: "N",
246: "N",
251: "N",
},
91: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_L",
56: "GY_L",
61: "GY_L",
66: "GY_L",
71: "GY_L",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "BG_R",
96: "PB_L",
101: "PB_R",
106: "PB_R",
111: "PB_R",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "N",
},
96: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_L",
56: "GY_L",
61: "GY_L",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_R",
96: "BG_R",
101: "B_R",
106: "PB_L",
111: "PB_L",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
101: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_L",
41: "GY_L",
46: "GY_L",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_R",
101: "BG_R",
106: "B_R",
111: "PB_L",
116: "PB_L",
121: "PB_L",
126: "PB_L",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
106: {1: "GY_L",
6: "GY_L",
11: "GY_L",
16: "GY_L",
21: "GY_L",
26: "GY_L",
31: "GY_L",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_R",
101: "BG_L",
106: "BG_R",
111: "B_L",
116: "B_R",
121: "PB_L",
126: "PB_L",
131: "PB_L",
136: "PB_L",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "N",
},
111: {1: "GY_L",
6: "GY_L",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_R",
106: "BG_L",
111: "BG_R",
116: "B_L",
121: "B_R",
126: "B_R",
131: "PB_L",
136: "PB_L",
141: "PB_L",
146: "PB_L",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "N",
241: "N",
246: "N",
251: "N",
},
116: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_R",
106: "G_R",
111: "BG_L",
116: "BG_R",
121: "B_L",
126: "B_R",
131: "B_R",
136: "PB_L",
141: "PB_L",
146: "PB_L",
151: "PB_L",
156: "PB_L",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
121: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_R",
111: "BG_L",
116: "BG_L",
121: "BG_R",
126: "B_L",
131: "B_R",
136: "B_R",
141: "B_R",
146: "PB_L",
151: "PB_L",
156: "PB_L",
161: "PB_L",
166: "PB_L",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
126: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_R",
116: "BG_L",
121: "BG_L",
126: "BG_R",
131: "B_L",
136: "B_L",
141: "B_R",
146: "B_R",
151: "PB_L",
156: "PB_L",
161: "PB_L",
166: "PB_L",
171: "PB_L",
176: "PB_L",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "N",
246: "N",
251: "N",
},
131: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_R",
116: "G_R",
121: "BG_L",
126: "BG_L",
131: "BG_R",
136: "B_L",
141: "B_L",
146: "B_R",
151: "B_R",
156: "B_R",
161: "PB_L",
166: "PB_L",
171: "PB_L",
176: "PB_L",
181: "PB_L",
186: "PB_L",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "N",
251: "N",
},
136: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_R",
121: "G_R",
126: "BG_L",
131: "BG_R",
136: "BG_R",
141: "B_L",
146: "B_L",
151: "B_R",
156: "B_R",
161: "B_R",
166: "PB_L",
171: "PB_L",
176: "PB_L",
181: "PB_L",
186: "PB_L",
191: "PB_L",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
141: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_R",
121: "G_R",
126: "BG_L",
131: "BG_L",
136: "BG_R",
141: "BG_R",
146: "B_L",
151: "B_L",
156: "B_R",
161: "B_R",
166: "B_R",
171: "B_R",
176: "PB_L",
181: "PB_L",
186: "PB_L",
191: "PB_L",
196: "PB_L",
201: "PB_L",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
146: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_R",
126: "G_R",
131: "BG_L",
136: "BG_L",
141: "BG_R",
146: "BG_R",
151: "B_L",
156: "B_L",
161: "B_L",
166: "B_R",
171: "B_R",
176: "B_R",
181: "PB_L",
186: "PB_L",
191: "PB_L",
196: "PB_L",
201: "PB_L",
206: "PB_L",
211: "PB_L",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "N",
},
151: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_R",
126: "G_R",
131: "G_R",
136: "BG_L",
141: "BG_L",
146: "BG_R",
151: "BG_R",
156: "B_L",
161: "B_L",
166: "B_L",
171: "B_R",
176: "B_R",
181: "B_R",
186: "PB_L",
191: "PB_L",
196: "PB_L",
201: "PB_L",
206: "PB_L",
211: "PB_L",
216: "PB_L",
221: "PB_L",
226: "PB_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
156: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_R",
131: "G_R",
136: "G_R",
141: "BG_L",
146: "BG_L",
151: "BG_R",
156: "BG_R",
161: "B_L",
166: "B_L",
171: "B_L",
176: "B_R",
181: "B_R",
186: "B_R",
191: "B_R",
196: "PB_L",
201: "PB_L",
206: "PB_L",
211: "PB_L",
216: "PB_L",
221: "PB_L",
226: "PB_L",
231: "PB_R",
236: "N",
241: "N",
246: "N",
251: "N",
},
161: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_R",
136: "G_R",
141: "BG_L",
146: "BG_L",
151: "BG_L",
156: "BG_R",
161: "BG_R",
166: "B_L",
171: "B_L",
176: "B_L",
181: "B_R",
186: "B_R",
191: "B_R",
196: "B_R",
201: "PB_L",
206: "PB_L",
211: "PB_L",
216: "PB_L",
221: "PB_L",
226: "PB_L",
231: "PB_L",
236: "PB_L",
241: "PB_R",
246: "N",
251: "N",
},
166: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_R",
136: "G_R",
141: "G_R",
146: "BG_L",
151: "BG_L",
156: "BG_L",
161: "BG_R",
166: "BG_R",
171: "B_L",
176: "B_L",
181: "B_L",
186: "B_R",
191: "B_R",
196: "B_R",
201: "B_R",
206: "B_R",
211: "PB_L",
216: "PB_L",
221: "PB_L",
226: "PB_L",
231: "PB_L",
236: "PB_L",
241: "PB_L",
246: "PB_L",
251: "N",
},
171: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_R",
141: "G_R",
146: "G_R",
151: "BG_L",
156: "BG_L",
161: "BG_L",
166: "BG_R",
171: "BG_R",
176: "B_L",
181: "B_L",
186: "B_L",
191: "B_L",
196: "B_R",
201: "B_R",
206: "B_R",
211: "B_R",
216: "PB_L",
221: "PB_L",
226: "PB_L",
231: "PB_L",
236: "PB_L",
241: "PB_L",
246: "PB_L",
251: "PB_L",
},
176: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_R",
141: "G_R",
146: "G_R",
151: "G_R",
156: "BG_L",
161: "BG_L",
166: "BG_L",
171: "BG_R",
176: "BG_R",
181: "BG_R",
186: "B_L",
191: "B_L",
196: "B_L",
201: "B_R",
206: "B_R",
211: "B_R",
216: "B_R",
221: "PB_L",
226: "PB_L",
231: "PB_L",
236: "PB_L",
241: "PB_L",
246: "PB_L",
251: "PB_L",
},
181: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_R",
146: "G_R",
151: "G_R",
156: "BG_L",
161: "BG_L",
166: "BG_L",
171: "BG_R",
176: "BG_R",
181: "BG_R",
186: "BG_R",
191: "B_L",
196: "B_L",
201: "B_L",
206: "B_R",
211: "B_R",
216: "B_R",
221: "B_R",
226: "B_R",
231: "PB_L",
236: "PB_L",
241: "PB_L",
246: "PB_L",
251: "PB_L",
},
186: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_R",
151: "G_R",
156: "G_R",
161: "BG_L",
166: "BG_L",
171: "BG_L",
176: "BG_R",
181: "BG_R",
186: "BG_R",
191: "BG_R",
196: "B_L",
201: "B_L",
206: "B_L",
211: "B_R",
216: "B_R",
221: "B_R",
226: "B_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
191: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "GY_R",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_R",
151: "G_R",
156: "G_R",
161: "G_R",
166: "BG_L",
171: "BG_L",
176: "BG_L",
181: "BG_R",
186: "BG_R",
191: "BG_R",
196: "BG_R",
201: "B_L",
206: "B_L",
211: "B_L",
216: "B_R",
221: "B_R",
226: "B_R",
231: "B_R",
236: "B_R",
241: "N",
246: "N",
251: "N",
},
196: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_R",
156: "G_R",
161: "G_R",
166: "G_R",
171: "BG_L",
176: "BG_L",
181: "BG_L",
186: "BG_R",
191: "BG_R",
196: "BG_R",
201: "B_L",
206: "B_L",
211: "B_L",
216: "B_L",
221: "B_R",
226: "B_R",
231: "B_R",
236: "B_R",
241: "B_R",
246: "N",
251: "N",
},
201: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_R",
161: "G_R",
166: "G_R",
171: "BG_L",
176: "BG_L",
181: "BG_L",
186: "BG_L",
191: "BG_R",
196: "BG_R",
201: "BG_R",
206: "B_L",
211: "B_L",
216: "B_L",
221: "B_L",
226: "B_L",
231: "B_R",
236: "B_R",
241: "B_R",
246: "B_R",
251: "N",
},
206: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_R",
161: "G_R",
166: "G_R",
171: "G_R",
176: "BG_L",
181: "BG_L",
186: "BG_L",
191: "BG_L",
196: "BG_R",
201: "BG_R",
206: "BG_R",
211: "B_L",
216: "B_L",
221: "B_L",
226: "B_L",
231: "B_L",
236: "B_R",
241: "B_R",
246: "B_R",
251: "B_R",
},
211: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_R",
166: "G_R",
171: "G_R",
176: "G_R",
181: "BG_L",
186: "BG_L",
191: "BG_L",
196: "BG_L",
201: "BG_R",
206: "BG_R",
211: "BG_R",
216: "B_L",
221: "B_L",
226: "B_L",
231: "B_L",
236: "B_L",
241: "B_R",
246: "B_R",
251: "B_R",
},
216: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_R",
171: "G_R",
176: "G_R",
181: "G_R",
186: "BG_L",
191: "BG_L",
196: "BG_L",
201: "BG_R",
206: "BG_R",
211: "BG_R",
216: "BG_R",
221: "B_L",
226: "B_L",
231: "B_L",
236: "B_L",
241: "B_L",
246: "B_R",
251: "B_R",
},
221: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_R",
171: "G_R",
176: "G_R",
181: "G_R",
186: "G_R",
191: "BG_L",
196: "BG_L",
201: "BG_L",
206: "BG_R",
211: "BG_R",
216: "BG_R",
221: "BG_R",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
226: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_L",
171: "G_R",
176: "G_R",
181: "G_R",
186: "G_R",
191: "BG_L",
196: "BG_L",
201: "BG_L",
206: "BG_L",
211: "BG_R",
216: "BG_R",
221: "BG_R",
226: "BG_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
231: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_L",
171: "G_L",
176: "G_R",
181: "G_R",
186: "G_R",
191: "G_R",
196: "BG_L",
201: "BG_L",
206: "BG_L",
211: "BG_L",
216: "BG_R",
221: "BG_R",
226: "BG_R",
231: "BG_R",
236: "N",
241: "N",
246: "N",
251: "N",
},
236: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_L",
171: "G_L",
176: "G_R",
181: "G_R",
186: "G_R",
191: "G_R",
196: "G_R",
201: "BG_L",
206: "BG_L",
211: "BG_L",
216: "BG_L",
221: "BG_R",
226: "BG_R",
231: "BG_R",
236: "BG_R",
241: "N",
246: "N",
251: "N",
},
241: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_L",
171: "G_L",
176: "G_L",
181: "G_R",
186: "G_R",
191: "G_R",
196: "G_R",
201: "G_R",
206: "BG_L",
211: "BG_L",
216: "BG_L",
221: "BG_L",
226: "BG_R",
231: "BG_R",
236: "BG_R",
241: "BG_R",
246: "N",
251: "N",
},
246: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_L",
171: "G_L",
176: "G_L",
181: "G_L",
186: "G_R",
191: "G_R",
196: "G_R",
201: "G_R",
206: "BG_L",
211: "BG_L",
216: "BG_L",
221: "BG_L",
226: "BG_L",
231: "BG_R",
236: "BG_R",
241: "BG_R",
246: "BG_R",
251: "N",
},
251: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "GY_R",
46: "GY_R",
51: "GY_R",
56: "GY_R",
61: "GY_R",
66: "GY_R",
71: "GY_R",
76: "GY_R",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_L",
171: "G_L",
176: "G_L",
181: "G_L",
186: "G_R",
191: "G_R",
196: "G_R",
201: "G_R",
206: "G_R",
211: "BG_L",
216: "BG_L",
221: "BG_L",
226: "BG_L",
231: "BG_R",
236: "BG_R",
241: "BG_R",
246: "BG_R",
251: "BG_R",
}}

export default PCCS_Color_Calc_R86
    