<template>
  <div style=" height:48px;width:100%;">
    <div v-if="clickCount === 0">
      <v-alert
        border="left"
        colored-border
        color="deep-purple accent-4"
        elevation="2"
        style="z-index: 1000;"
      >
        <h3>１点目を指定してください</h3>
      </v-alert>
    </div>
    <div v-else-if="clickCount === 1">
      <v-alert
        border="left"
        colored-border
        color="deep-purple accent-4"
        elevation="2"
        style="z-index: 1000"
      >
        <h3>２点目を指定してください</h3>
      </v-alert>
    </div>
    <div v-else-if="clickCount === 2">
      <v-alert
        border="left"
        colored-border
        color="deep-purple accent-4"
        elevation="2"
        style="z-index: 1000;"
      >
        <h3>判定結果</h3>
      </v-alert>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InformationView',

    data: () => ({
    }),
    mounted: function () {
    },
    watch:{
    },
    computed: {
      clickCount: {
        get() { return this.$store.getters.clickCount },
        set(value) { this.$store.commit('setClickCount', value) },
      }
    },
    methods: {
    }
  }
</script>
