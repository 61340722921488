<template>
  <canvas ref="myCanvas" :style="{ width: size, height: size }"></canvas>
</template>

<script>
export default {
  props: {
    size: {
      type: String, // "1rem" などの単位付きでサイズを指定
      required: true
    },
    angles: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.drawLines();
  },
  methods: {
    drawLines() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext('2d');

      // ピクセルでサイズを取得
      const canvasWidth = canvas.clientWidth;
      const canvasHeight = canvas.clientHeight;

      // canvasの描画サイズを明示的に設定
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      // キャンバスの中心
      const centerX = canvasWidth / 2;
      const centerY = canvasHeight / 2;

      // キャンバスサイズの40%をlineLengthとして計算
      const lineLength = Math.min(canvasWidth, canvasHeight) * 0.4;

      // 円の半径はlineLengthと同じ
      const radius = lineLength;

      // 円を描画
      ctx.beginPath();
      ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI); // 中心に円を描画
      ctx.stroke(); // 輪郭を描画

      // 配列の角度に基づいて複数の線を描画
      this.angles.forEach(angle => {
        // 真上を0度とするため、90度を引いて角度を調整
        const adjustedAngle = angle - 90;

        // 角度をラジアンに変換
        const angleInRadians = adjustedAngle * (Math.PI / 180);

        // 終点座標を計算
        const xEnd = centerX + lineLength * Math.cos(angleInRadians);
        const yEnd = centerY + lineLength * Math.sin(angleInRadians);

        // 線を描画（円の中心から放射状に）
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);  // 中心から始める
        ctx.lineTo(xEnd, yEnd);        // 終点まで線を引く
        ctx.stroke();                  // 描画
      });
    }
  }
};
</script>

<style scoped>
canvas {
  display: block;
}
</style>
