const PCCS_Color_Calc_R6 = {
1: {1: "YR_L",
6: "P_R",
11: "P_L",
16: "P_L",
21: "PB_R",
26: "PB_R",
31: "PB_R",
36: "PB_R",
41: "N",
46: "N",
51: "N",
56: "N",
61: "N",
66: "N",
71: "N",
76: "N",
81: "N",
86: "N",
91: "N",
96: "N",
101: "N",
106: "N",
111: "N",
116: "N",
121: "N",
126: "N",
131: "N",
136: "N",
141: "N",
146: "N",
151: "N",
156: "N",
161: "N",
166: "N",
171: "N",
176: "N",
181: "N",
186: "N",
191: "N",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
6: {1: "GY_L",
6: "N",
11: "PB_R",
16: "PB_R",
21: "PB_R",
26: "PB_R",
31: "PB_R",
36: "PB_R",
41: "PB_R",
46: "PB_R",
51: "PB_R",
56: "PB_R",
61: "PB_R",
66: "PB_R",
71: "PB_R",
76: "PB_R",
81: "N",
86: "N",
91: "N",
96: "N",
101: "N",
106: "N",
111: "N",
116: "N",
121: "N",
126: "N",
131: "N",
136: "N",
141: "N",
146: "N",
151: "N",
156: "N",
161: "N",
166: "N",
171: "N",
176: "N",
181: "N",
186: "N",
191: "N",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
11: {1: "N",
6: "GY_R",
11: "BG_R",
16: "PB_L",
21: "PB_L",
26: "PB_R",
31: "PB_R",
36: "PB_R",
41: "PB_R",
46: "PB_R",
51: "PB_R",
56: "PB_R",
61: "PB_R",
66: "PB_R",
71: "PB_R",
76: "PB_R",
81: "PB_R",
86: "PB_R",
91: "PB_R",
96: "PB_R",
101: "PB_R",
106: "PB_R",
111: "N",
116: "N",
121: "N",
126: "N",
131: "N",
136: "N",
141: "N",
146: "N",
151: "N",
156: "N",
161: "N",
166: "N",
171: "N",
176: "N",
181: "N",
186: "N",
191: "N",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
16: {1: "GY_R",
6: "GY_R",
11: "G_L",
16: "BG_R",
21: "B_R",
26: "PB_L",
31: "PB_L",
36: "PB_R",
41: "PB_R",
46: "PB_R",
51: "PB_R",
56: "PB_R",
61: "PB_R",
66: "PB_R",
71: "PB_R",
76: "PB_R",
81: "PB_R",
86: "PB_R",
91: "PB_R",
96: "PB_R",
101: "PB_R",
106: "PB_R",
111: "PB_R",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "N",
141: "N",
146: "N",
151: "N",
156: "N",
161: "N",
166: "N",
171: "N",
176: "N",
181: "N",
186: "N",
191: "N",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
21: {1: "GY_R",
6: "GY_R",
11: "G_L",
16: "G_R",
21: "BG_L",
26: "B_L",
31: "B_R",
36: "PB_L",
41: "PB_L",
46: "PB_R",
51: "PB_R",
56: "PB_R",
61: "PB_R",
66: "PB_R",
71: "PB_R",
76: "PB_R",
81: "PB_R",
86: "PB_R",
91: "PB_R",
96: "PB_R",
101: "PB_R",
106: "PB_R",
111: "PB_R",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "N",
166: "N",
171: "N",
176: "N",
181: "N",
186: "N",
191: "N",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
26: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "G_L",
21: "G_R",
26: "BG_L",
31: "B_L",
36: "B_R",
41: "PB_L",
46: "PB_L",
51: "PB_L",
56: "PB_R",
61: "PB_R",
66: "PB_R",
71: "PB_R",
76: "PB_R",
81: "PB_R",
86: "PB_R",
91: "PB_R",
96: "PB_R",
101: "PB_R",
106: "PB_R",
111: "PB_R",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "N",
156: "N",
161: "N",
166: "N",
171: "N",
176: "N",
181: "N",
186: "N",
191: "N",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
31: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "G_L",
21: "G_L",
26: "BG_L",
31: "BG_L",
36: "B_L",
41: "B_R",
46: "B_R",
51: "PB_L",
56: "PB_L",
61: "PB_R",
66: "PB_R",
71: "PB_R",
76: "PB_R",
81: "PB_R",
86: "PB_R",
91: "PB_R",
96: "PB_R",
101: "PB_R",
106: "PB_R",
111: "PB_R",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "N",
151: "N",
156: "N",
161: "N",
166: "N",
171: "N",
176: "N",
181: "N",
186: "N",
191: "N",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
36: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "G_L",
26: "G_R",
31: "BG_L",
36: "BG_L",
41: "BG_R",
46: "B_R",
51: "B_R",
56: "PB_L",
61: "PB_L",
66: "PB_L",
71: "PB_R",
76: "PB_R",
81: "PB_R",
86: "PB_R",
91: "PB_R",
96: "PB_R",
101: "PB_R",
106: "PB_R",
111: "PB_R",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "N",
161: "N",
166: "N",
171: "N",
176: "N",
181: "N",
186: "N",
191: "N",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
41: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "G_L",
26: "G_L",
31: "G_R",
36: "BG_L",
41: "BG_R",
46: "BG_R",
51: "B_L",
56: "B_R",
61: "PB_L",
66: "PB_L",
71: "PB_L",
76: "PB_L",
81: "PB_R",
86: "PB_R",
91: "PB_R",
96: "PB_R",
101: "PB_R",
106: "PB_R",
111: "PB_R",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "N",
181: "N",
186: "N",
191: "N",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
46: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "G_L",
26: "G_L",
31: "G_L",
36: "G_R",
41: "BG_L",
46: "BG_R",
51: "BG_R",
56: "B_L",
61: "B_R",
66: "B_R",
71: "PB_L",
76: "PB_L",
81: "PB_L",
86: "PB_L",
91: "PB_R",
96: "PB_R",
101: "PB_R",
106: "PB_R",
111: "PB_R",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "N",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
51: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "G_L",
26: "G_L",
31: "G_L",
36: "G_R",
41: "G_R",
46: "BG_L",
51: "BG_R",
56: "BG_R",
61: "B_L",
66: "B_R",
71: "B_R",
76: "PB_L",
81: "PB_L",
86: "PB_L",
91: "PB_L",
96: "PB_R",
101: "PB_R",
106: "PB_R",
111: "PB_R",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
56: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "G_L",
31: "G_L",
36: "G_L",
41: "G_R",
46: "G_R",
51: "BG_L",
56: "BG_R",
61: "BG_R",
66: "B_L",
71: "B_R",
76: "B_R",
81: "PB_L",
86: "PB_L",
91: "PB_L",
96: "PB_L",
101: "PB_L",
106: "PB_R",
111: "PB_R",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "N",
191: "N",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
61: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "G_L",
31: "G_L",
36: "G_L",
41: "G_L",
46: "G_R",
51: "G_R",
56: "BG_L",
61: "BG_R",
66: "BG_R",
71: "B_L",
76: "B_R",
81: "B_R",
86: "B_R",
91: "PB_L",
96: "PB_L",
101: "PB_L",
106: "PB_L",
111: "PB_R",
116: "PB_R",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "N",
201: "N",
206: "N",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
66: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "G_L",
31: "G_L",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_R",
56: "BG_L",
61: "BG_L",
66: "BG_R",
71: "BG_R",
76: "B_L",
81: "B_L",
86: "B_R",
91: "B_R",
96: "PB_L",
101: "PB_L",
106: "PB_L",
111: "PB_L",
116: "PB_L",
121: "PB_R",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "N",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
71: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "G_L",
31: "G_L",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_R",
56: "G_R",
61: "BG_L",
66: "BG_L",
71: "BG_R",
76: "BG_R",
81: "B_L",
86: "B_L",
91: "B_R",
96: "B_R",
101: "PB_L",
106: "PB_L",
111: "PB_L",
116: "PB_L",
121: "PB_L",
126: "PB_R",
131: "PB_R",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
76: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "G_L",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_R",
61: "G_R",
66: "BG_L",
71: "BG_L",
76: "BG_R",
81: "BG_R",
86: "B_L",
91: "B_L",
96: "B_R",
101: "B_R",
106: "B_R",
111: "PB_L",
116: "PB_L",
121: "PB_L",
126: "PB_L",
131: "PB_L",
136: "PB_R",
141: "PB_R",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "N",
246: "N",
251: "N",
},
81: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "G_L",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_R",
66: "G_R",
71: "BG_L",
76: "BG_L",
81: "BG_R",
86: "BG_R",
91: "B_L",
96: "B_L",
101: "B_R",
106: "B_R",
111: "B_R",
116: "PB_L",
121: "PB_L",
126: "PB_L",
131: "PB_L",
136: "PB_L",
141: "PB_L",
146: "PB_R",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
86: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "G_L",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_R",
66: "G_R",
71: "G_R",
76: "BG_L",
81: "BG_L",
86: "BG_R",
91: "BG_R",
96: "B_L",
101: "B_L",
106: "B_R",
111: "B_R",
116: "B_R",
121: "PB_L",
126: "PB_L",
131: "PB_L",
136: "PB_L",
141: "PB_L",
146: "PB_L",
151: "PB_R",
156: "PB_R",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
91: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "G_L",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_R",
71: "G_R",
76: "BG_L",
81: "BG_L",
86: "BG_L",
91: "BG_R",
96: "BG_R",
101: "B_L",
106: "B_L",
111: "B_R",
116: "B_R",
121: "B_R",
126: "B_R",
131: "PB_L",
136: "PB_L",
141: "PB_L",
146: "PB_L",
151: "PB_L",
156: "PB_L",
161: "PB_R",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "N",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
96: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "G_L",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_R",
76: "G_R",
81: "BG_L",
86: "BG_L",
91: "BG_L",
96: "BG_R",
101: "BG_R",
106: "B_L",
111: "B_L",
116: "B_L",
121: "B_R",
126: "B_R",
131: "B_R",
136: "PB_L",
141: "PB_L",
146: "PB_L",
151: "PB_L",
156: "PB_L",
161: "PB_L",
166: "PB_R",
171: "PB_R",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
101: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "G_L",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_R",
81: "G_R",
86: "BG_L",
91: "BG_L",
96: "BG_L",
101: "BG_R",
106: "BG_R",
111: "B_L",
116: "B_L",
121: "B_L",
126: "B_R",
131: "B_R",
136: "B_R",
141: "PB_L",
146: "PB_L",
151: "PB_L",
156: "PB_L",
161: "PB_L",
166: "PB_L",
171: "PB_L",
176: "PB_R",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "N",
241: "N",
246: "N",
251: "N",
},
106: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "G_L",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_R",
81: "G_R",
86: "G_R",
91: "BG_L",
96: "BG_L",
101: "BG_L",
106: "BG_R",
111: "BG_R",
116: "B_L",
121: "B_L",
126: "B_L",
131: "B_R",
136: "B_R",
141: "B_R",
146: "PB_L",
151: "PB_L",
156: "PB_L",
161: "PB_L",
166: "PB_L",
171: "PB_L",
176: "PB_L",
181: "PB_R",
186: "PB_R",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "N",
},
111: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "G_L",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_R",
86: "G_R",
91: "G_R",
96: "BG_L",
101: "BG_L",
106: "BG_L",
111: "BG_R",
116: "BG_R",
121: "B_L",
126: "B_L",
131: "B_L",
136: "B_R",
141: "B_R",
146: "B_R",
151: "B_R",
156: "PB_L",
161: "PB_L",
166: "PB_L",
171: "PB_L",
176: "PB_L",
181: "PB_L",
186: "PB_L",
191: "PB_R",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
116: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_R",
91: "G_R",
96: "G_R",
101: "BG_L",
106: "BG_L",
111: "BG_L",
116: "BG_R",
121: "BG_R",
126: "B_L",
131: "B_L",
136: "B_L",
141: "B_R",
146: "B_R",
151: "B_R",
156: "B_R",
161: "PB_L",
166: "PB_L",
171: "PB_L",
176: "PB_L",
181: "PB_L",
186: "PB_L",
191: "PB_L",
196: "PB_R",
201: "PB_R",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "N",
},
121: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_R",
91: "G_R",
96: "G_R",
101: "BG_L",
106: "BG_L",
111: "BG_L",
116: "BG_R",
121: "BG_R",
126: "BG_R",
131: "B_L",
136: "B_L",
141: "B_L",
146: "B_R",
151: "B_R",
156: "B_R",
161: "B_R",
166: "PB_L",
171: "PB_L",
176: "PB_L",
181: "PB_L",
186: "PB_L",
191: "PB_L",
196: "PB_L",
201: "PB_L",
206: "PB_R",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
126: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_R",
96: "G_R",
101: "G_R",
106: "BG_L",
111: "BG_L",
116: "BG_L",
121: "BG_R",
126: "BG_R",
131: "BG_R",
136: "B_L",
141: "B_L",
146: "B_L",
151: "B_R",
156: "B_R",
161: "B_R",
166: "B_R",
171: "PB_L",
176: "PB_L",
181: "PB_L",
186: "PB_L",
191: "PB_L",
196: "PB_L",
201: "PB_L",
206: "PB_L",
211: "PB_R",
216: "PB_R",
221: "PB_R",
226: "PB_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
131: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_R",
101: "G_R",
106: "G_R",
111: "BG_L",
116: "BG_L",
121: "BG_L",
126: "BG_R",
131: "BG_R",
136: "BG_R",
141: "B_L",
146: "B_L",
151: "B_L",
156: "B_L",
161: "B_R",
166: "B_R",
171: "B_R",
176: "B_R",
181: "PB_L",
186: "PB_L",
191: "PB_L",
196: "PB_L",
201: "PB_L",
206: "PB_L",
211: "PB_L",
216: "PB_L",
221: "PB_R",
226: "PB_R",
231: "PB_R",
236: "N",
241: "N",
246: "N",
251: "N",
},
136: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_R",
106: "G_R",
111: "G_R",
116: "BG_L",
121: "BG_L",
126: "BG_L",
131: "BG_R",
136: "BG_R",
141: "BG_R",
146: "B_L",
151: "B_L",
156: "B_L",
161: "B_L",
166: "B_R",
171: "B_R",
176: "B_R",
181: "B_R",
186: "PB_L",
191: "PB_L",
196: "PB_L",
201: "PB_L",
206: "PB_L",
211: "PB_L",
216: "PB_L",
221: "PB_L",
226: "PB_R",
231: "PB_R",
236: "PB_R",
241: "PB_R",
246: "N",
251: "N",
},
141: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_R",
106: "G_R",
111: "G_R",
116: "G_R",
121: "BG_L",
126: "BG_L",
131: "BG_L",
136: "BG_R",
141: "BG_R",
146: "BG_R",
151: "B_L",
156: "B_L",
161: "B_L",
166: "B_L",
171: "B_R",
176: "B_R",
181: "B_R",
186: "B_R",
191: "PB_L",
196: "PB_L",
201: "PB_L",
206: "PB_L",
211: "PB_L",
216: "PB_L",
221: "PB_L",
226: "PB_L",
231: "PB_L",
236: "PB_R",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
146: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_R",
111: "G_R",
116: "G_R",
121: "BG_L",
126: "BG_L",
131: "BG_L",
136: "BG_L",
141: "BG_R",
146: "BG_R",
151: "BG_R",
156: "B_L",
161: "B_L",
166: "B_L",
171: "B_L",
176: "B_R",
181: "B_R",
186: "B_R",
191: "B_R",
196: "PB_L",
201: "PB_L",
206: "PB_L",
211: "PB_L",
216: "PB_L",
221: "PB_L",
226: "PB_L",
231: "PB_L",
236: "PB_L",
241: "PB_R",
246: "PB_R",
251: "PB_R",
},
151: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "G_L",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_R",
116: "G_R",
121: "G_R",
126: "BG_L",
131: "BG_L",
136: "BG_L",
141: "BG_L",
146: "BG_R",
151: "BG_R",
156: "BG_R",
161: "B_L",
166: "B_L",
171: "B_L",
176: "B_L",
181: "B_R",
186: "B_R",
191: "B_R",
196: "B_R",
201: "B_R",
206: "PB_L",
211: "PB_L",
216: "PB_L",
221: "PB_L",
226: "PB_L",
231: "PB_L",
236: "PB_L",
241: "PB_L",
246: "PB_L",
251: "PB_R",
},
156: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_R",
116: "G_R",
121: "G_R",
126: "G_R",
131: "BG_L",
136: "BG_L",
141: "BG_L",
146: "BG_L",
151: "BG_R",
156: "BG_R",
161: "BG_R",
166: "B_L",
171: "B_L",
176: "B_L",
181: "B_L",
186: "B_R",
191: "B_R",
196: "B_R",
201: "B_R",
206: "B_R",
211: "PB_L",
216: "PB_L",
221: "PB_L",
226: "PB_L",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
161: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_R",
121: "G_R",
126: "G_R",
131: "G_R",
136: "BG_L",
141: "BG_L",
146: "BG_L",
151: "BG_L",
156: "BG_R",
161: "BG_R",
166: "BG_R",
171: "B_L",
176: "B_L",
181: "B_L",
186: "B_L",
191: "B_R",
196: "B_R",
201: "B_R",
206: "B_R",
211: "B_R",
216: "PB_L",
221: "PB_L",
226: "PB_L",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
166: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_R",
126: "G_R",
131: "G_R",
136: "G_R",
141: "BG_L",
146: "BG_L",
151: "BG_L",
156: "BG_L",
161: "BG_R",
166: "BG_R",
171: "BG_R",
176: "B_L",
181: "B_L",
186: "B_L",
191: "B_L",
196: "B_R",
201: "B_R",
206: "B_R",
211: "B_R",
216: "B_R",
221: "PB_L",
226: "PB_L",
231: "PB_L",
236: "PB_L",
241: "N",
246: "N",
251: "N",
},
171: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_R",
131: "G_R",
136: "G_R",
141: "BG_L",
146: "BG_L",
151: "BG_L",
156: "BG_L",
161: "BG_R",
166: "BG_R",
171: "BG_R",
176: "BG_R",
181: "B_L",
186: "B_L",
191: "B_L",
196: "B_L",
201: "B_R",
206: "B_R",
211: "B_R",
216: "B_R",
221: "B_R",
226: "B_R",
231: "PB_L",
236: "PB_L",
241: "PB_L",
246: "N",
251: "N",
},
176: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_R",
131: "G_R",
136: "G_R",
141: "G_R",
146: "BG_L",
151: "BG_L",
156: "BG_L",
161: "BG_L",
166: "BG_R",
171: "BG_R",
176: "BG_R",
181: "BG_R",
186: "B_L",
191: "B_L",
196: "B_L",
201: "B_L",
206: "B_L",
211: "B_R",
216: "B_R",
221: "B_R",
226: "B_R",
231: "B_R",
236: "PB_L",
241: "PB_L",
246: "PB_L",
251: "PB_L",
},
181: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_R",
136: "G_R",
141: "G_R",
146: "G_R",
151: "BG_L",
156: "BG_L",
161: "BG_L",
166: "BG_L",
171: "BG_R",
176: "BG_R",
181: "BG_R",
186: "BG_R",
191: "B_L",
196: "B_L",
201: "B_L",
206: "B_L",
211: "B_L",
216: "B_R",
221: "B_R",
226: "B_R",
231: "B_R",
236: "B_R",
241: "PB_L",
246: "PB_L",
251: "PB_L",
},
186: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_R",
141: "G_R",
146: "G_R",
151: "G_R",
156: "BG_L",
161: "BG_L",
166: "BG_L",
171: "BG_L",
176: "BG_R",
181: "BG_R",
186: "BG_R",
191: "BG_R",
196: "B_L",
201: "B_L",
206: "B_L",
211: "B_L",
216: "B_L",
221: "B_R",
226: "B_R",
231: "B_R",
236: "B_R",
241: "B_R",
246: "PB_L",
251: "PB_L",
},
191: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_R",
141: "G_R",
146: "G_R",
151: "G_R",
156: "G_R",
161: "BG_L",
166: "BG_L",
171: "BG_L",
176: "BG_L",
181: "BG_R",
186: "BG_R",
191: "BG_R",
196: "BG_R",
201: "B_L",
206: "B_L",
211: "B_L",
216: "B_L",
221: "B_L",
226: "B_R",
231: "B_R",
236: "B_R",
241: "B_R",
246: "N",
251: "N",
},
196: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_R",
146: "G_R",
151: "G_R",
156: "G_R",
161: "BG_L",
166: "BG_L",
171: "BG_L",
176: "BG_L",
181: "BG_L",
186: "BG_R",
191: "BG_R",
196: "BG_R",
201: "BG_R",
206: "B_L",
211: "B_L",
216: "B_L",
221: "B_L",
226: "B_L",
231: "B_R",
236: "B_R",
241: "B_R",
246: "B_R",
251: "N",
},
201: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_R",
151: "G_R",
156: "G_R",
161: "G_R",
166: "BG_L",
171: "BG_L",
176: "BG_L",
181: "BG_L",
186: "BG_L",
191: "BG_R",
196: "BG_R",
201: "BG_R",
206: "BG_R",
211: "B_L",
216: "B_L",
221: "B_L",
226: "B_L",
231: "B_L",
236: "B_R",
241: "B_R",
246: "B_R",
251: "B_R",
},
206: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_R",
151: "G_R",
156: "G_R",
161: "G_R",
166: "G_R",
171: "BG_L",
176: "BG_L",
181: "BG_L",
186: "BG_L",
191: "BG_R",
196: "BG_R",
201: "BG_R",
206: "BG_R",
211: "BG_R",
216: "B_L",
221: "B_L",
226: "B_L",
231: "B_L",
236: "B_L",
241: "B_R",
246: "B_R",
251: "B_R",
},
211: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_R",
156: "G_R",
161: "G_R",
166: "G_R",
171: "G_R",
176: "BG_L",
181: "BG_L",
186: "BG_L",
191: "BG_L",
196: "BG_R",
201: "BG_R",
206: "BG_R",
211: "BG_R",
216: "BG_R",
221: "B_L",
226: "B_L",
231: "B_L",
236: "B_L",
241: "B_L",
246: "B_R",
251: "B_R",
},
216: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_R",
161: "G_R",
166: "G_R",
171: "G_R",
176: "G_R",
181: "BG_L",
186: "BG_L",
191: "BG_L",
196: "BG_L",
201: "BG_R",
206: "BG_R",
211: "BG_R",
216: "BG_R",
221: "BG_R",
226: "B_L",
231: "B_L",
236: "B_L",
241: "B_L",
246: "B_L",
251: "B_R",
},
221: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_R",
166: "G_R",
171: "G_R",
176: "G_R",
181: "G_R",
186: "BG_L",
191: "BG_L",
196: "BG_L",
201: "BG_L",
206: "BG_R",
211: "BG_R",
216: "BG_R",
221: "BG_R",
226: "BG_R",
231: "B_L",
236: "B_L",
241: "B_L",
246: "N",
251: "N",
},
226: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_R",
166: "G_R",
171: "G_R",
176: "G_R",
181: "G_R",
186: "BG_L",
191: "BG_L",
196: "BG_L",
201: "BG_L",
206: "BG_L",
211: "BG_R",
216: "BG_R",
221: "N",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
231: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "GY_R",
41: "G_L",
46: "G_L",
51: "G_L",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_R",
171: "G_R",
176: "G_R",
181: "G_R",
186: "G_R",
191: "BG_L",
196: "BG_L",
201: "BG_L",
206: "BG_L",
211: "BG_L",
216: "BG_R",
221: "BG_R",
226: "N",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
236: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "N",
41: "N",
46: "N",
51: "N",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "N",
136: "G_L",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_L",
171: "G_R",
176: "G_R",
181: "G_R",
186: "G_R",
191: "G_R",
196: "BG_L",
201: "BG_L",
206: "BG_L",
211: "BG_L",
216: "BG_L",
221: "BG_R",
226: "BG_R",
231: "N",
236: "N",
241: "N",
246: "N",
251: "N",
},
241: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "N",
41: "N",
46: "N",
51: "N",
56: "G_L",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "N",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_L",
171: "G_R",
176: "G_R",
181: "G_R",
186: "G_R",
191: "G_R",
196: "G_R",
201: "BG_L",
206: "BG_L",
211: "BG_L",
216: "BG_L",
221: "BG_L",
226: "BG_R",
231: "BG_R",
236: "N",
241: "N",
246: "N",
251: "N",
},
246: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "N",
41: "N",
46: "N",
51: "N",
56: "N",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "N",
141: "G_L",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_L",
171: "G_L",
176: "G_R",
181: "G_R",
186: "G_R",
191: "G_R",
196: "G_R",
201: "G_R",
206: "BG_L",
211: "BG_L",
216: "BG_L",
221: "BG_L",
226: "BG_R",
231: "BG_R",
236: "BG_R",
241: "N",
246: "N",
251: "N",
},
251: {1: "GY_R",
6: "GY_R",
11: "GY_R",
16: "GY_R",
21: "GY_R",
26: "GY_R",
31: "GY_R",
36: "N",
41: "N",
46: "N",
51: "N",
56: "N",
61: "G_L",
66: "G_L",
71: "G_L",
76: "G_L",
81: "G_L",
86: "G_L",
91: "G_L",
96: "G_L",
101: "G_L",
106: "G_L",
111: "G_L",
116: "G_L",
121: "G_L",
126: "G_L",
131: "G_L",
136: "G_L",
141: "N",
146: "G_L",
151: "G_L",
156: "G_L",
161: "G_L",
166: "G_L",
171: "G_L",
176: "G_L",
181: "G_R",
186: "G_R",
191: "G_R",
196: "G_R",
201: "G_R",
206: "BG_L",
211: "BG_L",
216: "BG_L",
221: "BG_L",
226: "BG_L",
231: "BG_R",
236: "BG_R",
241: "BG_R",
246: "N",
251: "N",
}}

export default PCCS_Color_Calc_R6
    