export const chroma_Mei_Chu_count = 11
export const chroma_Mei_Chu_ranking = [
{label: "030", count: 9, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 238, g: 77, b: 88, hex: "#ee4d58"}},
{label: "050", count: 9, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 197, g: 115, b: 56, hex: "#c57338"}},
{label: "076", count: 9, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 157, g: 135, b: 43, hex: "#9d872b"}},
{label: "154", count: 8, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 133, g: 138, b: 137, hex: "#858a89"}},
{label: "077", count: 7, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 157, g: 135, b: 32, hex: "#9d8720"}},
{label: "135", count: 7, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 72, g: 152, b: 118, hex: "#489876"}},
{label: "137", count: 7, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 56, g: 154, b: 114, hex: "#389a72"}},
{label: "138", count: 7, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 44, g: 155, b: 112, hex: "#2c9b70"}},
{label: "153", count: 7, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 167, b: 85, hex: "#00a755"}},
{label: "011", count: 6, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 178, g: 122, b: 119, hex: "#b27a77"}},
{label: "031", count: 6, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 242, g: 71, b: 84, hex: "#f24754"}},
{label: "063", count: 6, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 139, g: 137, b: 132, hex: "#8b8984"}},
{label: "075", count: 6, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 156, g: 135, b: 52, hex: "#9c8734"}},
{label: "095", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 137, g: 138, b: 126, hex: "#898a7e"}},
{label: "098", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 132, g: 140, b: 110, hex: "#848c6e"}},
{label: "151", count: 6, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 165, b: 89, hex: "#00a559"}},
{label: "009", count: 5, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 169, g: 126, b: 124, hex: "#a97e7c"}},
{label: "052", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 204, g: 111, b: 44, hex: "#cc6f2c"}},
{label: "064", count: 5, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 141, g: 136, b: 125, hex: "#8d887d"}},
{label: "074", count: 5, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 155, g: 135, b: 58, hex: "#9b873a"}},
{label: "136", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 63, g: 153, b: 116, hex: "#3f9974"}},
{label: "149", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 164, b: 93, hex: "#00a45d"}},
{label: "150", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 164, b: 91, hex: "#00a45b"}},
{label: "152", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 166, b: 87, hex: "#00a657"}},
{label: "167", count: 5, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 19, g: 154, b: 144, hex: "#139a90"}},
{label: "010", count: 4, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 172, g: 124, b: 122, hex: "#ac7c7a"}},
{label: "028", count: 4, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 233, g: 83, b: 91, hex: "#e9535b"}},
{label: "029", count: 4, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 235, g: 80, b: 89, hex: "#eb5059"}},
{label: "047", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 189, g: 119, b: 69, hex: "#bd7745"}},
{label: "048", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 193, g: 217, b: 65, hex: "#c1d941"}},
{label: "053", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 205, g: 110, b: 39, hex: "#cd6e27"}},
{label: "059", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 220, g: 100, b: 0, hex: "#dc6400"}},
{label: "060", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 222, g: 98, b: 0, hex: "#de6200"}},
{label: "061", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 225, g: 95, b: 0, hex: "#e15f00"}},
{label: "062", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 228, g: 93, b: 0, hex: "#e45d00"}},
{label: "066", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 144, g: 136, b: 112, hex: "#908870"}},
{label: "096", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 135, g: 139, b: 121, hex: "#878b79"}},
{label: "169", count: 4, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 156, b: 146, hex: "#009c92"}},
{label: "032", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 245, g: 68, b: 83, hex: "#f54453"}},
{label: "046", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 186, g: 120, b: 75, hex: "#ba784b"}},
{label: "049", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 194, g: 116, b: 60, hex: "#c2743c"}},
{label: "051", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 201, g: 113, b: 50, hex: "#c97132"}},
{label: "058", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 218, g: 101, b: 0, hex: "#da6500"}},
{label: "078", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 158, g: 135, b: 21, hex: "#9e8715"}},
{label: "079", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 158, g: 135, b: 0, hex: "#9e8700"}},
{label: "083", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 161, g: 134, b: 0, hex: "#a18600"}},
{label: "097", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 134, g: 139, b: 116, hex: "#868b74"}},
{label: "099", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 132, g: 140, b: 105, hex: "#848c69"}},
{label: "103", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 127, g: 143, b: 81, hex: "#7f8f51"}},
{label: "114", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 108, g: 149, b: 0, hex: "#6c9500"}},
{label: "115", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 106, g: 150, b: 0, hex: "#6a9600"}},
{label: "116", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 103, g: 151, b: 0, hex: "#679700"}},
{label: "117", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 101, g: 151, b: 0, hex: "#659700"}},
{label: "119", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 95, g: 152, b: 0, hex: "#5f9800"}},
{label: "134", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 80, g: 150, b: 120, hex: "#509678"}},
{label: "148", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 163, b: 95, hex: "#00a35f"}},
{label: "156", count: 3, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 123, g: 141, b: 138, hex: "#7b8d8a"}},
{label: "007", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 162, g: 129, b: 125, hex: "#a2817d"}},
{label: "008", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 165, g: 127, b: 125, hex: "#a57f7d"}},
{label: "025", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 223, g: 93, b: 95, hex: "#df5d5f"}},
{label: "027", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 230, g: 86, b: 92, hex: "#e6565c"}},
{label: "033", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 141, g: 136, b: 133, hex: "#8d8885"}},
{label: "043", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 177, g: 124, b: 89, hex: "#b17c59"}},
{label: "045", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 184, g: 121, b: 80, hex: "#b87950"}},
{label: "056", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 213, g: 105, b: 17, hex: "#d56911"}},
{label: "065", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 142, g: 136, b: 119, hex: "#8e8877"}},
{label: "067", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 146, g: 136, b: 105, hex: "#928869"}},
{label: "072", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 153, g: 135, b: 72, hex: "#998748"}},
{label: "073", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 154, g: 135, b: 67, hex: "#9a8743"}},
{label: "081", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 159, g: 135, b: 0, hex: "#9f8700"}},
{label: "084", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "087", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "100", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 130, g: 141, b: 99, hex: "#828d63"}},
{label: "104", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 126, g: 144, b: 75, hex: "#7e904b"}},
{label: "105", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 125, g: 144, b: 69, hex: "#7d9045"}},
{label: "112", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 112, g: 148, b: 5, hex: "#709405"}},
{label: "147", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 162, b: 97, hex: "#00a261"}},
{label: "157", count: 2, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 117, g: 142, b: 138, hex: "#758e8a"}},
{label: "168", count: 2, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 155, b: 146, hex: "#009b92"}},
{label: "006", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 159, g: 130, b: 127, hex: "#9f827f"}},
{label: "024", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 220, g: 95, b: 97, hex: "#dc5f61"}},
{label: "034", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 145, g: 135, b: 128, hex: "#918780"}},
{label: "036", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 154, g: 132, b: 120, hex: "#9a8478"}},
{label: "037", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 157, g: 132, b: 115, hex: "#9d8473"}},
{label: "044", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 181, g: 123, b: 83, hex: "#b57b53"}},
{label: "057", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 215, g: 103, b: 3, hex: "#d76703"}},
{label: "080", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 159, g: 135, b: 0, hex: "#9f8700"}},
{label: "082", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 160, g: 135, b: 0, hex: "#a08700"}},
{label: "085", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "086", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "093", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 164, g: 133, b: 0, hex: "#a48500"}},
{label: "094", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 137, g: 137, b: 132, hex: "#898984"}},
{label: "101", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 129, g: 142, b: 94, hex: "#818e5e"}},
{label: "106", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 122, g: 145, b: 63, hex: "#7a913f"}},
{label: "113", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 109, g: 149, b: 0, hex: "#6d9500"}},
{label: "118", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 97, g: 152, b: 0, hex: "#619800"}},
{label: "120", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 93, g: 152, b: 0, hex: "#5d9800"}},
{label: "122", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 87, g: 154, b: 0, hex: "#579a00"}},
{label: "133", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 85, g: 150, b: 120, hex: "#559678"}},
{label: "146", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 162, b: 99, hex: "#00a263"}},
{label: "155", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 127, g: 140, b: 137, hex: "#7f8c89"}},
{label: "158", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 110, g: 144, b: 140, hex: "#6e908c"}},
{label: "163", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 74, g: 150, b: 143, hex: "#4a968f"}},
{label: "164", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 67, g: 151, b: 143, hex: "#43978f"}},
{label: "165", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 55, g: 152, b: 143, hex: "#37988f"}},
{label: "166", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 43, g: 153, b: 144, hex: "#2b9990"}},
{label: "170", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 157, b: 146, hex: "#009d92"}},
{label: "172", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 158, b: 147, hex: "#009e93"}},
{label: "175", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 161, b: 149, hex: "#00a195"}},
{label: "001", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 136, g: 136, b: 135, hex: "#888887"}},
{label: "002", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 141, g: 136, b: 133, hex: "#8d8885"}},
{label: "003", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 146, g: 134, b: 132, hex: "#928684"}},
{label: "004", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 149, g: 133, b: 130, hex: "#958582"}},
{label: "005", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 146, g: 131, b: 128, hex: "#928380"}},
{label: "012", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 181, g: 120, b: 117, hex: "#b57875"}},
{label: "013", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 184, g: 119, b: 116, hex: "#b87774"}},
{label: "014", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 187, g: 117, b: 114, hex: "#bb7572"}},
{label: "015", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 190, g: 116, b: 112, hex: "#be7470"}},
{label: "016", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 195, g: 113, b: 111, hex: "#c3716f"}},
{label: "017", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 198, g: 111, b: 109, hex: "#c66f6d"}},
{label: "018", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 201, g: 109, b: 108, hex: "#c96d6c"}},
{label: "019", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 204, g: 107, b: 106, hex: "#cc6b6a"}},
{label: "020", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 207, g: 105, b: 105, hex: "#cf6969"}},
{label: "021", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 212, g: 102, b: 101, hex: "#d46665"}},
{label: "022", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 215, g: 100, b: 100, hex: "#d76464"}},
{label: "023", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 217, g: 98, b: 98, hex: "#d96262"}},
{label: "026", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 227, g: 89, b: 94, hex: "#e3595e"}},
{label: "035", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 150, g: 134, b: 123, hex: "#96867b"}},
{label: "038", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 161, g: 130, b: 111, hex: "#a1826f"}},
{label: "039", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 165, g: 129, b: 106, hex: "#a5816a"}},
{label: "040", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 168, g: 128, b: 101, hex: "#a88065"}},
{label: "041", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 171, g: 127, b: 97, hex: "#ab7f61"}},
{label: "042", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 175, g: 125, b: 92, hex: "#af7d5c"}},
{label: "054", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 208, g: 108, b: 32, hex: "#d06c20"}},
{label: "055", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 211, g: 106, b: 27, hex: "#d36a1b"}},
{label: "068", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 147, g: 136, b: 100, hex: "#938864"}},
{label: "069", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 148, g: 136, b: 93, hex: "#94885d"}},
{label: "070", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 149, g: 136, b: 87, hex: "#958857"}},
{label: "071", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 151, g: 136, b: 80, hex: "#978850"}},
{label: "088", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "089", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "090", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "091", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "092", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "102", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 129, g: 142, b: 86, hex: "#818e56"}},
{label: "107", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 122, g: 145, b: 56, hex: "#7a9138"}},
{label: "108", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 119, g: 146, b: 49, hex: "#779231"}},
{label: "109", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 118, g: 147, b: 42, hex: "#76932a"}},
{label: "110", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 117, g: 147, b: 33, hex: "#759321"}},
{label: "111", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 113, g: 148, b: 22, hex: "#719416"}},
{label: "121", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 89, g: 153, b: 0, hex: "#599900"}},
{label: "123", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 83, g: 154, b: 0, hex: "#539a00"}},
{label: "124", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 134, g: 138, b: 135, hex: "#868a87"}},
{label: "125", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 128, g: 139, b: 135, hex: "#808b87"}},
{label: "126", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 123, g: 141, b: 133, hex: "#7b8d85"}},
{label: "127", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 120, g: 142, b: 131, hex: "#788e83"}},
{label: "128", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 114, g: 143, b: 129, hex: "#728f81"}},
{label: "129", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 109, g: 145, b: 127, hex: "#6d917f"}},
{label: "130", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 103, g: 146, b: 126, hex: "#67927e"}},
{label: "131", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 99, g: 147, b: 124, hex: "#63937c"}},
{label: "132", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 92, g: 148, b: 122, hex: "#5c947a"}},
{label: "139", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 27, g: 156, b: 110, hex: "#1b9c6e"}},
{label: "140", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 7, g: 156, b: 108, hex: "#079c6c"}},
{label: "141", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 157, b: 107, hex: "#009d6b"}},
{label: "142", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 158, b: 105, hex: "#009e69"}},
{label: "143", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 159, b: 103, hex: "#009f67"}},
{label: "144", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 160, b: 103, hex: "#00a067"}},
{label: "145", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 161, b: 101, hex: "#00a165"}},
{label: "159", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 105, g: 145, b: 140, hex: "#69918c"}},
{label: "160", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 98, g: 146, b: 140, hex: "#62928c"}},
{label: "161", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 92, g: 147, b: 141, hex: "#5c938d"}},
{label: "162", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 84, g: 148, b: 141, hex: "#54948d"}},
{label: "171", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 157, b: 147, hex: "#009d93"}},
{label: "173", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 159, b: 149, hex: "#009f95"}},
{label: "174", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 160, b: 149, hex: "#00a095"}},
{label: "176", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 162, b: 151, hex: "#00a297"}},
{label: "177", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 163, b: 151, hex: "#00a397"}},
{label: "178", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 163, b: 152, hex: "#00a398"}},
{label: "179", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 164, b: 152, hex: "#00a498"}},
{label: "180", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 165, b: 152, hex: "#00a598"}},
]
export const chroma_Mei_Mei_count = 17
export const chroma_Mei_Mei_ranking = [
{label: "150", count: 15, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 164, b: 91, hex: "#00a45b"}},
{label: "153", count: 15, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 167, b: 85, hex: "#00a755"}},
{label: "168", count: 15, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 155, b: 146, hex: "#009b92"}},
{label: "050", count: 14, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 197, g: 115, b: 56, hex: "#c57338"}},
{label: "064", count: 14, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 141, g: 136, b: 125, hex: "#8d887d"}},
{label: "136", count: 14, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 63, g: 153, b: 116, hex: "#3f9974"}},
{label: "137", count: 14, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 56, g: 154, b: 114, hex: "#389a72"}},
{label: "052", count: 13, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 204, g: 111, b: 44, hex: "#cc6f2c"}},
{label: "061", count: 13, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 225, g: 95, b: 0, hex: "#e15f00"}},
{label: "116", count: 13, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 103, g: 151, b: 0, hex: "#679700"}},
{label: "151", count: 13, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 165, b: 89, hex: "#00a559"}},
{label: "167", count: 13, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 19, g: 154, b: 144, hex: "#139a90"}},
{label: "030", count: 12, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 238, g: 77, b: 88, hex: "#ee4d58"}},
{label: "031", count: 12, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 242, g: 71, b: 84, hex: "#f24754"}},
{label: "098", count: 12, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 132, g: 140, b: 110, hex: "#848c6e"}},
{label: "152", count: 12, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 166, b: 87, hex: "#00a657"}},
{label: "010", count: 11, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 172, g: 124, b: 122, hex: "#ac7c7a"}},
{label: "029", count: 11, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 235, g: 80, b: 89, hex: "#eb5059"}},
{label: "051", count: 11, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 201, g: 113, b: 50, hex: "#c97132"}},
{label: "063", count: 11, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 139, g: 137, b: 132, hex: "#8b8984"}},
{label: "096", count: 11, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 135, g: 139, b: 121, hex: "#878b79"}},
{label: "135", count: 11, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 72, g: 152, b: 118, hex: "#489876"}},
{label: "154", count: 11, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 133, g: 138, b: 137, hex: "#858a89"}},
{label: "053", count: 10, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 205, g: 110, b: 39, hex: "#cd6e27"}},
{label: "062", count: 10, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 228, g: 93, b: 0, hex: "#e45d00"}},
{label: "077", count: 10, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 157, g: 135, b: 32, hex: "#9d8720"}},
{label: "117", count: 10, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 101, g: 151, b: 0, hex: "#659700"}},
{label: "134", count: 10, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 80, g: 150, b: 120, hex: "#509678"}},
{label: "049", count: 9, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 194, g: 116, b: 60, hex: "#c2743c"}},
{label: "060", count: 9, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 222, g: 98, b: 0, hex: "#de6200"}},
{label: "076", count: 9, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 157, g: 135, b: 43, hex: "#9d872b"}},
{label: "133", count: 9, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 85, g: 150, b: 120, hex: "#559678"}},
{label: "149", count: 9, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 164, b: 93, hex: "#00a45d"}},
{label: "065", count: 8, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 142, g: 136, b: 119, hex: "#8e8877"}},
{label: "078", count: 8, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 158, g: 135, b: 21, hex: "#9e8715"}},
{label: "097", count: 8, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 134, g: 139, b: 116, hex: "#868b74"}},
{label: "104", count: 8, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 126, g: 144, b: 75, hex: "#7e904b"}},
{label: "138", count: 8, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 44, g: 155, b: 112, hex: "#2c9b70"}},
{label: "155", count: 8, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 127, g: 140, b: 137, hex: "#7f8c89"}},
{label: "166", count: 8, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 43, g: 153, b: 144, hex: "#2b9990"}},
{label: "169", count: 8, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 156, b: 146, hex: "#009c92"}},
{label: "047", count: 7, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 189, g: 119, b: 69, hex: "#bd7745"}},
{label: "074", count: 7, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 155, g: 135, b: 58, hex: "#9b873a"}},
{label: "103", count: 7, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 127, g: 143, b: 81, hex: "#7f8f51"}},
{label: "165", count: 7, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 55, g: 152, b: 143, hex: "#37988f"}},
{label: "011", count: 6, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 178, g: 122, b: 119, hex: "#b27a77"}},
{label: "058", count: 6, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 218, g: 101, b: 0, hex: "#da6500"}},
{label: "075", count: 6, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 156, g: 135, b: 52, hex: "#9c8734"}},
{label: "079", count: 6, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 158, g: 135, b: 0, hex: "#9e8700"}},
{label: "095", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 137, g: 138, b: 126, hex: "#898a7e"}},
{label: "099", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 132, g: 140, b: 105, hex: "#848c69"}},
{label: "115", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 106, g: 150, b: 0, hex: "#6a9600"}},
{label: "130", count: 6, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 103, g: 146, b: 126, hex: "#67927e"}},
{label: "156", count: 6, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 123, g: 141, b: 138, hex: "#7b8d8a"}},
{label: "009", count: 5, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 169, g: 126, b: 124, hex: "#a97e7c"}},
{label: "032", count: 5, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 245, g: 68, b: 83, hex: "#f54453"}},
{label: "045", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 184, g: 121, b: 80, hex: "#b87950"}},
{label: "066", count: 5, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 144, g: 136, b: 112, hex: "#908870"}},
{label: "085", count: 5, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "094", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 137, g: 137, b: 132, hex: "#898984"}},
{label: "132", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 92, g: 148, b: 122, hex: "#5c947a"}},
{label: "139", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 27, g: 156, b: 110, hex: "#1b9c6e"}},
{label: "147", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 162, b: 97, hex: "#00a261"}},
{label: "148", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 163, b: 95, hex: "#00a35f"}},
{label: "028", count: 4, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 233, g: 83, b: 91, hex: "#e9535b"}},
{label: "046", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 186, g: 120, b: 75, hex: "#ba784b"}},
{label: "048", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 193, g: 217, b: 65, hex: "#c1d941"}},
{label: "067", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 146, g: 136, b: 105, hex: "#928869"}},
{label: "102", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 129, g: 142, b: 86, hex: "#818e56"}},
{label: "106", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 122, g: 145, b: 63, hex: "#7a913f"}},
{label: "107", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 122, g: 145, b: 56, hex: "#7a9138"}},
{label: "112", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 112, g: 148, b: 5, hex: "#709405"}},
{label: "114", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 108, g: 149, b: 0, hex: "#6c9500"}},
{label: "118", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 97, g: 152, b: 0, hex: "#619800"}},
{label: "120", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 93, g: 152, b: 0, hex: "#5d9800"}},
{label: "140", count: 4, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 7, g: 156, b: 108, hex: "#079c6c"}},
{label: "157", count: 4, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 117, g: 142, b: 138, hex: "#758e8a"}},
{label: "034", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 145, g: 135, b: 128, hex: "#918780"}},
{label: "082", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 160, g: 135, b: 0, hex: "#a08700"}},
{label: "101", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 129, g: 142, b: 94, hex: "#818e5e"}},
{label: "113", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 109, g: 149, b: 0, hex: "#6d9500"}},
{label: "119", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 95, g: 152, b: 0, hex: "#5f9800"}},
{label: "170", count: 3, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 157, b: 146, hex: "#009d92"}},
{label: "027", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 230, g: 86, b: 92, hex: "#e6565c"}},
{label: "038", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 161, g: 130, b: 111, hex: "#a1826f"}},
{label: "042", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 175, g: 125, b: 92, hex: "#af7d5c"}},
{label: "057", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 215, g: 103, b: 3, hex: "#d76703"}},
{label: "059", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 220, g: 100, b: 0, hex: "#dc6400"}},
{label: "072", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 153, g: 135, b: 72, hex: "#998748"}},
{label: "080", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 159, g: 135, b: 0, hex: "#9f8700"}},
{label: "081", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 159, g: 135, b: 0, hex: "#9f8700"}},
{label: "083", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 161, g: 134, b: 0, hex: "#a18600"}},
{label: "105", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 125, g: 144, b: 69, hex: "#7d9045"}},
{label: "123", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 83, g: 154, b: 0, hex: "#539a00"}},
{label: "125", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 128, g: 139, b: 135, hex: "#808b87"}},
{label: "127", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 120, g: 142, b: 131, hex: "#788e83"}},
{label: "129", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 109, g: 145, b: 127, hex: "#6d917f"}},
{label: "131", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 99, g: 147, b: 124, hex: "#63937c"}},
{label: "006", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 159, g: 130, b: 127, hex: "#9f827f"}},
{label: "008", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 165, g: 127, b: 125, hex: "#a57f7d"}},
{label: "025", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 223, g: 93, b: 95, hex: "#df5d5f"}},
{label: "033", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 141, g: 136, b: 133, hex: "#8d8885"}},
{label: "056", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 213, g: 105, b: 17, hex: "#d56911"}},
{label: "069", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 148, g: 136, b: 93, hex: "#94885d"}},
{label: "073", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 154, g: 135, b: 67, hex: "#9a8743"}},
{label: "084", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "087", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "092", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "100", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 130, g: 141, b: 99, hex: "#828d63"}},
{label: "121", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 89, g: 153, b: 0, hex: "#599900"}},
{label: "124", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 134, g: 138, b: 135, hex: "#868a87"}},
{label: "126", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 123, g: 141, b: 133, hex: "#7b8d85"}},
{label: "146", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 162, b: 99, hex: "#00a263"}},
{label: "158", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 110, g: 144, b: 140, hex: "#6e908c"}},
{label: "164", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 67, g: 151, b: 143, hex: "#43978f"}},
{label: "001", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 136, g: 136, b: 135, hex: "#888887"}},
{label: "002", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 141, g: 136, b: 133, hex: "#8d8885"}},
{label: "003", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 146, g: 134, b: 132, hex: "#928684"}},
{label: "004", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 149, g: 133, b: 130, hex: "#958582"}},
{label: "005", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 146, g: 131, b: 128, hex: "#928380"}},
{label: "007", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 162, g: 129, b: 125, hex: "#a2817d"}},
{label: "012", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 181, g: 120, b: 117, hex: "#b57875"}},
{label: "013", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 184, g: 119, b: 116, hex: "#b87774"}},
{label: "014", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 187, g: 117, b: 114, hex: "#bb7572"}},
{label: "015", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 190, g: 116, b: 112, hex: "#be7470"}},
{label: "016", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 195, g: 113, b: 111, hex: "#c3716f"}},
{label: "017", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 198, g: 111, b: 109, hex: "#c66f6d"}},
{label: "018", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 201, g: 109, b: 108, hex: "#c96d6c"}},
{label: "019", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 204, g: 107, b: 106, hex: "#cc6b6a"}},
{label: "020", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 207, g: 105, b: 105, hex: "#cf6969"}},
{label: "021", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 212, g: 102, b: 101, hex: "#d46665"}},
{label: "022", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 215, g: 100, b: 100, hex: "#d76464"}},
{label: "023", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 217, g: 98, b: 98, hex: "#d96262"}},
{label: "024", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 220, g: 95, b: 97, hex: "#dc5f61"}},
{label: "026", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 227, g: 89, b: 94, hex: "#e3595e"}},
{label: "035", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 150, g: 134, b: 123, hex: "#96867b"}},
{label: "036", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 154, g: 132, b: 120, hex: "#9a8478"}},
{label: "037", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 157, g: 132, b: 115, hex: "#9d8473"}},
{label: "039", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 165, g: 129, b: 106, hex: "#a5816a"}},
{label: "040", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 168, g: 128, b: 101, hex: "#a88065"}},
{label: "041", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 171, g: 127, b: 97, hex: "#ab7f61"}},
{label: "043", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 177, g: 124, b: 89, hex: "#b17c59"}},
{label: "044", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 181, g: 123, b: 83, hex: "#b57b53"}},
{label: "054", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 208, g: 108, b: 32, hex: "#d06c20"}},
{label: "055", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 211, g: 106, b: 27, hex: "#d36a1b"}},
{label: "068", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 147, g: 136, b: 100, hex: "#938864"}},
{label: "070", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 149, g: 136, b: 87, hex: "#958857"}},
{label: "071", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 151, g: 136, b: 80, hex: "#978850"}},
{label: "086", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "088", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "089", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "090", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "091", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "093", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 164, g: 133, b: 0, hex: "#a48500"}},
{label: "108", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 119, g: 146, b: 49, hex: "#779231"}},
{label: "109", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 118, g: 147, b: 42, hex: "#76932a"}},
{label: "110", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 117, g: 147, b: 33, hex: "#759321"}},
{label: "111", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 113, g: 148, b: 22, hex: "#719416"}},
{label: "122", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 87, g: 154, b: 0, hex: "#579a00"}},
{label: "128", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 114, g: 143, b: 129, hex: "#728f81"}},
{label: "141", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 157, b: 107, hex: "#009d6b"}},
{label: "142", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 158, b: 105, hex: "#009e69"}},
{label: "143", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 159, b: 103, hex: "#009f67"}},
{label: "144", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 160, b: 103, hex: "#00a067"}},
{label: "145", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 161, b: 101, hex: "#00a165"}},
{label: "159", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 105, g: 145, b: 140, hex: "#69918c"}},
{label: "160", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 98, g: 146, b: 140, hex: "#62928c"}},
{label: "161", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 92, g: 147, b: 141, hex: "#5c938d"}},
{label: "162", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 84, g: 148, b: 141, hex: "#54948d"}},
{label: "163", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 74, g: 150, b: 143, hex: "#4a968f"}},
{label: "171", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 157, b: 147, hex: "#009d93"}},
{label: "172", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 158, b: 147, hex: "#009e93"}},
{label: "173", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 159, b: 149, hex: "#009f95"}},
{label: "174", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 160, b: 149, hex: "#00a095"}},
{label: "175", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 161, b: 149, hex: "#00a195"}},
{label: "176", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 162, b: 151, hex: "#00a297"}},
{label: "177", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 163, b: 151, hex: "#00a397"}},
{label: "178", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 163, b: 152, hex: "#00a398"}},
{label: "179", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 164, b: 152, hex: "#00a498"}},
{label: "180", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 165, b: 152, hex: "#00a598"}},
]
export const chroma_Mei_An_count = 13
export const chroma_Mei_An_ranking = [
{label: "135", count: 10, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 72, g: 152, b: 118, hex: "#489876"}},
{label: "031", count: 9, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 242, g: 71, b: 84, hex: "#f24754"}},
{label: "152", count: 9, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 166, b: 87, hex: "#00a657"}},
{label: "050", count: 8, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 197, g: 115, b: 56, hex: "#c57338"}},
{label: "076", count: 8, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 157, g: 135, b: 43, hex: "#9d872b"}},
{label: "136", count: 8, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 63, g: 153, b: 116, hex: "#3f9974"}},
{label: "151", count: 8, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 165, b: 89, hex: "#00a559"}},
{label: "167", count: 8, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 19, g: 154, b: 144, hex: "#139a90"}},
{label: "168", count: 8, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 155, b: 146, hex: "#009b92"}},
{label: "009", count: 7, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 169, g: 126, b: 124, hex: "#a97e7c"}},
{label: "010", count: 7, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 172, g: 124, b: 122, hex: "#ac7c7a"}},
{label: "030", count: 7, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 238, g: 77, b: 88, hex: "#ee4d58"}},
{label: "062", count: 7, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 228, g: 93, b: 0, hex: "#e45d00"}},
{label: "063", count: 7, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 139, g: 137, b: 132, hex: "#8b8984"}},
{label: "077", count: 7, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 157, g: 135, b: 32, hex: "#9d8720"}},
{label: "097", count: 7, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 134, g: 139, b: 116, hex: "#868b74"}},
{label: "137", count: 7, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 56, g: 154, b: 114, hex: "#389a72"}},
{label: "138", count: 7, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 44, g: 155, b: 112, hex: "#2c9b70"}},
{label: "052", count: 6, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 204, g: 111, b: 44, hex: "#cc6f2c"}},
{label: "059", count: 6, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 220, g: 100, b: 0, hex: "#dc6400"}},
{label: "064", count: 6, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 141, g: 136, b: 125, hex: "#8d887d"}},
{label: "075", count: 6, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 156, g: 135, b: 52, hex: "#9c8734"}},
{label: "150", count: 6, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 164, b: 91, hex: "#00a45b"}},
{label: "153", count: 6, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 167, b: 85, hex: "#00a755"}},
{label: "154", count: 6, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 133, g: 138, b: 137, hex: "#858a89"}},
{label: "169", count: 6, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 156, b: 146, hex: "#009c92"}},
{label: "028", count: 5, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 233, g: 83, b: 91, hex: "#e9535b"}},
{label: "032", count: 5, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 245, g: 68, b: 83, hex: "#f54453"}},
{label: "049", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 194, g: 116, b: 60, hex: "#c2743c"}},
{label: "061", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 225, g: 95, b: 0, hex: "#e15f00"}},
{label: "078", count: 5, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 158, g: 135, b: 21, hex: "#9e8715"}},
{label: "096", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 135, g: 139, b: 121, hex: "#878b79"}},
{label: "099", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 132, g: 140, b: 105, hex: "#848c69"}},
{label: "117", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 101, g: 151, b: 0, hex: "#659700"}},
{label: "118", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 97, g: 152, b: 0, hex: "#619800"}},
{label: "134", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 80, g: 150, b: 120, hex: "#509678"}},
{label: "149", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 164, b: 93, hex: "#00a45d"}},
{label: "155", count: 5, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 127, g: 140, b: 137, hex: "#7f8c89"}},
{label: "170", count: 5, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 157, b: 146, hex: "#009d92"}},
{label: "011", count: 4, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 178, g: 122, b: 119, hex: "#b27a77"}},
{label: "048", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 193, g: 217, b: 65, hex: "#c1d941"}},
{label: "051", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 201, g: 113, b: 50, hex: "#c97132"}},
{label: "060", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 222, g: 98, b: 0, hex: "#de6200"}},
{label: "065", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 142, g: 136, b: 119, hex: "#8e8877"}},
{label: "066", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 144, g: 136, b: 112, hex: "#908870"}},
{label: "080", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 159, g: 135, b: 0, hex: "#9f8700"}},
{label: "082", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 160, g: 135, b: 0, hex: "#a08700"}},
{label: "083", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 161, g: 134, b: 0, hex: "#a18600"}},
{label: "098", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 132, g: 140, b: 110, hex: "#848c6e"}},
{label: "102", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 129, g: 142, b: 86, hex: "#818e56"}},
{label: "103", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 127, g: 143, b: 81, hex: "#7f8f51"}},
{label: "105", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 125, g: 144, b: 69, hex: "#7d9045"}},
{label: "106", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 122, g: 145, b: 63, hex: "#7a913f"}},
{label: "114", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 108, g: 149, b: 0, hex: "#6c9500"}},
{label: "116", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 103, g: 151, b: 0, hex: "#679700"}},
{label: "126", count: 4, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 123, g: 141, b: 133, hex: "#7b8d85"}},
{label: "133", count: 4, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 85, g: 150, b: 120, hex: "#559678"}},
{label: "148", count: 4, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 163, b: 95, hex: "#00a35f"}},
{label: "156", count: 4, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 123, g: 141, b: 138, hex: "#7b8d8a"}},
{label: "166", count: 4, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 43, g: 153, b: 144, hex: "#2b9990"}},
{label: "006", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 159, g: 130, b: 127, hex: "#9f827f"}},
{label: "007", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 162, g: 129, b: 125, hex: "#a2817d"}},
{label: "008", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 165, g: 127, b: 125, hex: "#a57f7d"}},
{label: "012", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 181, g: 120, b: 117, hex: "#b57875"}},
{label: "029", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 235, g: 80, b: 89, hex: "#eb5059"}},
{label: "033", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 141, g: 136, b: 133, hex: "#8d8885"}},
{label: "043", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 177, g: 124, b: 89, hex: "#b17c59"}},
{label: "046", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 186, g: 120, b: 75, hex: "#ba784b"}},
{label: "053", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 205, g: 110, b: 39, hex: "#cd6e27"}},
{label: "057", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 215, g: 103, b: 3, hex: "#d76703"}},
{label: "058", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 218, g: 101, b: 0, hex: "#da6500"}},
{label: "067", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 146, g: 136, b: 105, hex: "#928869"}},
{label: "074", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 155, g: 135, b: 58, hex: "#9b873a"}},
{label: "081", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 159, g: 135, b: 0, hex: "#9f8700"}},
{label: "084", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "085", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "086", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "087", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "094", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 137, g: 137, b: 132, hex: "#898984"}},
{label: "095", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 137, g: 138, b: 126, hex: "#898a7e"}},
{label: "100", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 130, g: 141, b: 99, hex: "#828d63"}},
{label: "101", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 129, g: 142, b: 94, hex: "#818e5e"}},
{label: "107", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 122, g: 145, b: 56, hex: "#7a9138"}},
{label: "119", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 95, g: 152, b: 0, hex: "#5f9800"}},
{label: "120", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 93, g: 152, b: 0, hex: "#5d9800"}},
{label: "121", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 89, g: 153, b: 0, hex: "#599900"}},
{label: "131", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 99, g: 147, b: 124, hex: "#63937c"}},
{label: "157", count: 3, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 117, g: 142, b: 138, hex: "#758e8a"}},
{label: "015", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 190, g: 116, b: 112, hex: "#be7470"}},
{label: "019", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 204, g: 107, b: 106, hex: "#cc6b6a"}},
{label: "025", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 223, g: 93, b: 95, hex: "#df5d5f"}},
{label: "036", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 154, g: 132, b: 120, hex: "#9a8478"}},
{label: "045", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 184, g: 121, b: 80, hex: "#b87950"}},
{label: "047", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 189, g: 119, b: 69, hex: "#bd7745"}},
{label: "055", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 211, g: 106, b: 27, hex: "#d36a1b"}},
{label: "056", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 213, g: 105, b: 17, hex: "#d56911"}},
{label: "072", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 153, g: 135, b: 72, hex: "#998748"}},
{label: "079", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 158, g: 135, b: 0, hex: "#9e8700"}},
{label: "093", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 164, g: 133, b: 0, hex: "#a48500"}},
{label: "104", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 126, g: 144, b: 75, hex: "#7e904b"}},
{label: "108", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 119, g: 146, b: 49, hex: "#779231"}},
{label: "122", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 87, g: 154, b: 0, hex: "#579a00"}},
{label: "124", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 134, g: 138, b: 135, hex: "#868a87"}},
{label: "125", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 128, g: 139, b: 135, hex: "#808b87"}},
{label: "127", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 120, g: 142, b: 131, hex: "#788e83"}},
{label: "129", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 109, g: 145, b: 127, hex: "#6d917f"}},
{label: "130", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 103, g: 146, b: 126, hex: "#67927e"}},
{label: "132", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 92, g: 148, b: 122, hex: "#5c947a"}},
{label: "141", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 157, b: 107, hex: "#009d6b"}},
{label: "144", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 160, b: 103, hex: "#00a067"}},
{label: "146", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 162, b: 99, hex: "#00a263"}},
{label: "147", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 162, b: 97, hex: "#00a261"}},
{label: "158", count: 2, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 110, g: 144, b: 140, hex: "#6e908c"}},
{label: "164", count: 2, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 67, g: 151, b: 143, hex: "#43978f"}},
{label: "176", count: 2, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 162, b: 151, hex: "#00a297"}},
{label: "001", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 136, g: 136, b: 135, hex: "#888887"}},
{label: "002", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 141, g: 136, b: 133, hex: "#8d8885"}},
{label: "003", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 146, g: 134, b: 132, hex: "#928684"}},
{label: "004", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 149, g: 133, b: 130, hex: "#958582"}},
{label: "005", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 146, g: 131, b: 128, hex: "#928380"}},
{label: "013", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 184, g: 119, b: 116, hex: "#b87774"}},
{label: "014", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 187, g: 117, b: 114, hex: "#bb7572"}},
{label: "016", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 195, g: 113, b: 111, hex: "#c3716f"}},
{label: "017", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 198, g: 111, b: 109, hex: "#c66f6d"}},
{label: "018", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 201, g: 109, b: 108, hex: "#c96d6c"}},
{label: "020", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 207, g: 105, b: 105, hex: "#cf6969"}},
{label: "021", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 212, g: 102, b: 101, hex: "#d46665"}},
{label: "022", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 215, g: 100, b: 100, hex: "#d76464"}},
{label: "023", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 217, g: 98, b: 98, hex: "#d96262"}},
{label: "024", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 220, g: 95, b: 97, hex: "#dc5f61"}},
{label: "026", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 227, g: 89, b: 94, hex: "#e3595e"}},
{label: "027", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 230, g: 86, b: 92, hex: "#e6565c"}},
{label: "034", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 145, g: 135, b: 128, hex: "#918780"}},
{label: "035", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 150, g: 134, b: 123, hex: "#96867b"}},
{label: "037", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 157, g: 132, b: 115, hex: "#9d8473"}},
{label: "038", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 161, g: 130, b: 111, hex: "#a1826f"}},
{label: "039", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 165, g: 129, b: 106, hex: "#a5816a"}},
{label: "040", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 168, g: 128, b: 101, hex: "#a88065"}},
{label: "041", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 171, g: 127, b: 97, hex: "#ab7f61"}},
{label: "042", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 175, g: 125, b: 92, hex: "#af7d5c"}},
{label: "044", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 181, g: 123, b: 83, hex: "#b57b53"}},
{label: "054", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 208, g: 108, b: 32, hex: "#d06c20"}},
{label: "068", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 147, g: 136, b: 100, hex: "#938864"}},
{label: "069", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 148, g: 136, b: 93, hex: "#94885d"}},
{label: "070", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 149, g: 136, b: 87, hex: "#958857"}},
{label: "073", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 154, g: 135, b: 67, hex: "#9a8743"}},
{label: "088", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "089", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "090", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "092", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "109", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 118, g: 147, b: 42, hex: "#76932a"}},
{label: "110", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 117, g: 147, b: 33, hex: "#759321"}},
{label: "111", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 113, g: 148, b: 22, hex: "#719416"}},
{label: "112", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 112, g: 148, b: 5, hex: "#709405"}},
{label: "115", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 106, g: 150, b: 0, hex: "#6a9600"}},
{label: "123", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 83, g: 154, b: 0, hex: "#539a00"}},
{label: "128", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 114, g: 143, b: 129, hex: "#728f81"}},
{label: "142", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 158, b: 105, hex: "#009e69"}},
{label: "143", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 159, b: 103, hex: "#009f67"}},
{label: "145", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 161, b: 101, hex: "#00a165"}},
{label: "159", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 105, g: 145, b: 140, hex: "#69918c"}},
{label: "160", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 98, g: 146, b: 140, hex: "#62928c"}},
{label: "161", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 92, g: 147, b: 141, hex: "#5c938d"}},
{label: "162", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 84, g: 148, b: 141, hex: "#54948d"}},
{label: "163", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 74, g: 150, b: 143, hex: "#4a968f"}},
{label: "165", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 55, g: 152, b: 143, hex: "#37988f"}},
{label: "171", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 157, b: 147, hex: "#009d93"}},
{label: "172", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 158, b: 147, hex: "#009e93"}},
{label: "173", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 159, b: 149, hex: "#009f95"}},
{label: "174", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 160, b: 149, hex: "#00a095"}},
{label: "175", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 161, b: 149, hex: "#00a195"}},
{label: "177", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 163, b: 151, hex: "#00a397"}},
{label: "178", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 163, b: 152, hex: "#00a398"}},
{label: "179", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 164, b: 152, hex: "#00a498"}},
{label: "180", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 165, b: 152, hex: "#00a598"}},
{label: "071", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 151, g: 136, b: 80, hex: "#978850"}},
{label: "091", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "113", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 109, g: 149, b: 0, hex: "#6d9500"}},
{label: "139", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 27, g: 156, b: 110, hex: "#1b9c6e"}},
{label: "140", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 7, g: 156, b: 108, hex: "#079c6c"}},
]
export const chroma_An_Chu_count = 11
export const chroma_An_Chu_ranking = [
{label: "137", count: 11, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 56, g: 154, b: 114, hex: "#389a72"}},
{label: "050", count: 10, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 197, g: 115, b: 56, hex: "#c57338"}},
{label: "151", count: 9, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 165, b: 89, hex: "#00a559"}},
{label: "030", count: 8, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 238, g: 77, b: 88, hex: "#ee4d58"}},
{label: "031", count: 8, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 242, g: 71, b: 84, hex: "#f24754"}},
{label: "052", count: 8, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 204, g: 111, b: 44, hex: "#cc6f2c"}},
{label: "058", count: 8, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 218, g: 101, b: 0, hex: "#da6500"}},
{label: "064", count: 8, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 141, g: 136, b: 125, hex: "#8d887d"}},
{label: "077", count: 8, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 157, g: 135, b: 32, hex: "#9d8720"}},
{label: "135", count: 8, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 72, g: 152, b: 118, hex: "#489876"}},
{label: "153", count: 8, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 167, b: 85, hex: "#00a755"}},
{label: "046", count: 7, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 186, g: 120, b: 75, hex: "#ba784b"}},
{label: "061", count: 7, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 225, g: 95, b: 0, hex: "#e15f00"}},
{label: "063", count: 7, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 139, g: 137, b: 132, hex: "#8b8984"}},
{label: "074", count: 7, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 155, g: 135, b: 58, hex: "#9b873a"}},
{label: "075", count: 7, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 156, g: 135, b: 52, hex: "#9c8734"}},
{label: "149", count: 7, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 164, b: 93, hex: "#00a45d"}},
{label: "168", count: 7, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 155, b: 146, hex: "#009b92"}},
{label: "169", count: 7, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 156, b: 146, hex: "#009c92"}},
{label: "009", count: 6, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 169, g: 126, b: 124, hex: "#a97e7c"}},
{label: "011", count: 6, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 178, g: 122, b: 119, hex: "#b27a77"}},
{label: "059", count: 6, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 220, g: 100, b: 0, hex: "#dc6400"}},
{label: "076", count: 6, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 157, g: 135, b: 43, hex: "#9d872b"}},
{label: "096", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 135, g: 139, b: 121, hex: "#878b79"}},
{label: "098", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 132, g: 140, b: 110, hex: "#848c6e"}},
{label: "136", count: 6, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 63, g: 153, b: 116, hex: "#3f9974"}},
{label: "138", count: 6, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 44, g: 155, b: 112, hex: "#2c9b70"}},
{label: "154", count: 6, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 133, g: 138, b: 137, hex: "#858a89"}},
{label: "166", count: 6, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 43, g: 153, b: 144, hex: "#2b9990"}},
{label: "010", count: 5, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 172, g: 124, b: 122, hex: "#ac7c7a"}},
{label: "028", count: 5, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 233, g: 83, b: 91, hex: "#e9535b"}},
{label: "047", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 189, g: 119, b: 69, hex: "#bd7745"}},
{label: "048", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 193, g: 217, b: 65, hex: "#c1d941"}},
{label: "049", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 194, g: 116, b: 60, hex: "#c2743c"}},
{label: "051", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 201, g: 113, b: 50, hex: "#c97132"}},
{label: "056", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 213, g: 105, b: 17, hex: "#d56911"}},
{label: "078", count: 5, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 158, g: 135, b: 21, hex: "#9e8715"}},
{label: "095", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 137, g: 138, b: 126, hex: "#898a7e"}},
{label: "099", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 132, g: 140, b: 105, hex: "#848c69"}},
{label: "114", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 108, g: 149, b: 0, hex: "#6c9500"}},
{label: "116", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 103, g: 151, b: 0, hex: "#679700"}},
{label: "117", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 101, g: 151, b: 0, hex: "#659700"}},
{label: "148", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 163, b: 95, hex: "#00a35f"}},
{label: "152", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 166, b: 87, hex: "#00a657"}},
{label: "167", count: 5, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 19, g: 154, b: 144, hex: "#139a90"}},
{label: "029", count: 4, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 235, g: 80, b: 89, hex: "#eb5059"}},
{label: "032", count: 4, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 245, g: 68, b: 83, hex: "#f54453"}},
{label: "062", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 228, g: 93, b: 0, hex: "#e45d00"}},
{label: "066", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 144, g: 136, b: 112, hex: "#908870"}},
{label: "072", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 153, g: 135, b: 72, hex: "#998748"}},
{label: "103", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 127, g: 143, b: 81, hex: "#7f8f51"}},
{label: "118", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 97, g: 152, b: 0, hex: "#619800"}},
{label: "119", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 95, g: 152, b: 0, hex: "#5f9800"}},
{label: "133", count: 4, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 85, g: 150, b: 120, hex: "#559678"}},
{label: "134", count: 4, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 80, g: 150, b: 120, hex: "#509678"}},
{label: "150", count: 4, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 164, b: 91, hex: "#00a45b"}},
{label: "158", count: 4, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 110, g: 144, b: 140, hex: "#6e908c"}},
{label: "006", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 159, g: 130, b: 127, hex: "#9f827f"}},
{label: "027", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 230, g: 86, b: 92, hex: "#e6565c"}},
{label: "053", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 205, g: 110, b: 39, hex: "#cd6e27"}},
{label: "057", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 215, g: 103, b: 3, hex: "#d76703"}},
{label: "060", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 222, g: 98, b: 0, hex: "#de6200"}},
{label: "079", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 158, g: 135, b: 0, hex: "#9e8700"}},
{label: "083", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 161, g: 134, b: 0, hex: "#a18600"}},
{label: "084", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "097", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 134, g: 139, b: 116, hex: "#868b74"}},
{label: "101", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 129, g: 142, b: 94, hex: "#818e5e"}},
{label: "102", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 129, g: 142, b: 86, hex: "#818e56"}},
{label: "112", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 112, g: 148, b: 5, hex: "#709405"}},
{label: "122", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 87, g: 154, b: 0, hex: "#579a00"}},
{label: "145", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 161, b: 101, hex: "#00a165"}},
{label: "156", count: 3, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 123, g: 141, b: 138, hex: "#7b8d8a"}},
{label: "157", count: 3, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 117, g: 142, b: 138, hex: "#758e8a"}},
{label: "165", count: 3, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 55, g: 152, b: 143, hex: "#37988f"}},
{label: "007", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 162, g: 129, b: 125, hex: "#a2817d"}},
{label: "012", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 181, g: 120, b: 117, hex: "#b57875"}},
{label: "045", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 184, g: 121, b: 80, hex: "#b87950"}},
{label: "067", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 146, g: 136, b: 105, hex: "#928869"}},
{label: "073", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 154, g: 135, b: 67, hex: "#9a8743"}},
{label: "080", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 159, g: 135, b: 0, hex: "#9f8700"}},
{label: "081", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 159, g: 135, b: 0, hex: "#9f8700"}},
{label: "086", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "094", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 137, g: 137, b: 132, hex: "#898984"}},
{label: "104", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 126, g: 144, b: 75, hex: "#7e904b"}},
{label: "105", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 125, g: 144, b: 69, hex: "#7d9045"}},
{label: "113", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 109, g: 149, b: 0, hex: "#6d9500"}},
{label: "140", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 7, g: 156, b: 108, hex: "#079c6c"}},
{label: "147", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 162, b: 97, hex: "#00a261"}},
{label: "155", count: 2, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 127, g: 140, b: 137, hex: "#7f8c89"}},
{label: "170", count: 2, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 157, b: 146, hex: "#009d92"}},
{label: "002", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 141, g: 136, b: 133, hex: "#8d8885"}},
{label: "005", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 146, g: 131, b: 128, hex: "#928380"}},
{label: "008", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 165, g: 127, b: 125, hex: "#a57f7d"}},
{label: "014", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 187, g: 117, b: 114, hex: "#bb7572"}},
{label: "025", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 223, g: 93, b: 95, hex: "#df5d5f"}},
{label: "026", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 227, g: 89, b: 94, hex: "#e3595e"}},
{label: "033", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 141, g: 136, b: 133, hex: "#8d8885"}},
{label: "034", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 145, g: 135, b: 128, hex: "#918780"}},
{label: "036", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 154, g: 132, b: 120, hex: "#9a8478"}},
{label: "044", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 181, g: 123, b: 83, hex: "#b57b53"}},
{label: "055", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 211, g: 106, b: 27, hex: "#d36a1b"}},
{label: "065", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 142, g: 136, b: 119, hex: "#8e8877"}},
{label: "069", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 148, g: 136, b: 93, hex: "#94885d"}},
{label: "082", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 160, g: 135, b: 0, hex: "#a08700"}},
{label: "090", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "092", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "093", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 164, g: 133, b: 0, hex: "#a48500"}},
{label: "100", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 130, g: 141, b: 99, hex: "#828d63"}},
{label: "106", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 122, g: 145, b: 63, hex: "#7a913f"}},
{label: "107", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 122, g: 145, b: 56, hex: "#7a9138"}},
{label: "109", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 118, g: 147, b: 42, hex: "#76932a"}},
{label: "111", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 113, g: 148, b: 22, hex: "#719416"}},
{label: "115", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 106, g: 150, b: 0, hex: "#6a9600"}},
{label: "120", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 93, g: 152, b: 0, hex: "#5d9800"}},
{label: "124", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 134, g: 138, b: 135, hex: "#868a87"}},
{label: "125", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 128, g: 139, b: 135, hex: "#808b87"}},
{label: "127", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 120, g: 142, b: 131, hex: "#788e83"}},
{label: "128", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 114, g: 143, b: 129, hex: "#728f81"}},
{label: "129", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 109, g: 145, b: 127, hex: "#6d917f"}},
{label: "130", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 103, g: 146, b: 126, hex: "#67927e"}},
{label: "131", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 99, g: 147, b: 124, hex: "#63937c"}},
{label: "132", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 92, g: 148, b: 122, hex: "#5c947a"}},
{label: "141", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 157, b: 107, hex: "#009d6b"}},
{label: "142", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 158, b: 105, hex: "#009e69"}},
{label: "143", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 159, b: 103, hex: "#009f67"}},
{label: "144", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 160, b: 103, hex: "#00a067"}},
{label: "146", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 162, b: 99, hex: "#00a263"}},
{label: "159", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 105, g: 145, b: 140, hex: "#69918c"}},
{label: "171", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 157, b: 147, hex: "#009d93"}},
{label: "001", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 136, g: 136, b: 135, hex: "#888887"}},
{label: "003", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 146, g: 134, b: 132, hex: "#928684"}},
{label: "004", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 149, g: 133, b: 130, hex: "#958582"}},
{label: "013", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 184, g: 119, b: 116, hex: "#b87774"}},
{label: "015", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 190, g: 116, b: 112, hex: "#be7470"}},
{label: "016", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 195, g: 113, b: 111, hex: "#c3716f"}},
{label: "017", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 198, g: 111, b: 109, hex: "#c66f6d"}},
{label: "018", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 201, g: 109, b: 108, hex: "#c96d6c"}},
{label: "019", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 204, g: 107, b: 106, hex: "#cc6b6a"}},
{label: "020", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 207, g: 105, b: 105, hex: "#cf6969"}},
{label: "021", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 212, g: 102, b: 101, hex: "#d46665"}},
{label: "022", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 215, g: 100, b: 100, hex: "#d76464"}},
{label: "023", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 217, g: 98, b: 98, hex: "#d96262"}},
{label: "024", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 220, g: 95, b: 97, hex: "#dc5f61"}},
{label: "035", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 150, g: 134, b: 123, hex: "#96867b"}},
{label: "037", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 157, g: 132, b: 115, hex: "#9d8473"}},
{label: "038", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 161, g: 130, b: 111, hex: "#a1826f"}},
{label: "039", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 165, g: 129, b: 106, hex: "#a5816a"}},
{label: "040", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 168, g: 128, b: 101, hex: "#a88065"}},
{label: "041", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 171, g: 127, b: 97, hex: "#ab7f61"}},
{label: "042", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 175, g: 125, b: 92, hex: "#af7d5c"}},
{label: "043", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 177, g: 124, b: 89, hex: "#b17c59"}},
{label: "054", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 208, g: 108, b: 32, hex: "#d06c20"}},
{label: "068", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 147, g: 136, b: 100, hex: "#938864"}},
{label: "070", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 149, g: 136, b: 87, hex: "#958857"}},
{label: "071", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 151, g: 136, b: 80, hex: "#978850"}},
{label: "085", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "087", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "088", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "089", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "091", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "108", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 119, g: 146, b: 49, hex: "#779231"}},
{label: "110", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 117, g: 147, b: 33, hex: "#759321"}},
{label: "121", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 89, g: 153, b: 0, hex: "#599900"}},
{label: "123", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 83, g: 154, b: 0, hex: "#539a00"}},
{label: "126", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 123, g: 141, b: 133, hex: "#7b8d85"}},
{label: "139", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 27, g: 156, b: 110, hex: "#1b9c6e"}},
{label: "160", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 98, g: 146, b: 140, hex: "#62928c"}},
{label: "161", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 92, g: 147, b: 141, hex: "#5c938d"}},
{label: "162", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 84, g: 148, b: 141, hex: "#54948d"}},
{label: "163", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 74, g: 150, b: 143, hex: "#4a968f"}},
{label: "164", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 67, g: 151, b: 143, hex: "#43978f"}},
{label: "172", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 158, b: 147, hex: "#009e93"}},
{label: "173", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 159, b: 149, hex: "#009f95"}},
{label: "174", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 160, b: 149, hex: "#00a095"}},
{label: "175", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 161, b: 149, hex: "#00a195"}},
{label: "176", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 162, b: 151, hex: "#00a297"}},
{label: "177", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 163, b: 151, hex: "#00a397"}},
{label: "178", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 163, b: 152, hex: "#00a398"}},
{label: "179", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 164, b: 152, hex: "#00a498"}},
{label: "180", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 165, b: 152, hex: "#00a598"}},
]
export const chroma_An_Mei_count = 17
export const chroma_An_Mei_ranking = [
{label: "153", count: 16, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 167, b: 85, hex: "#00a755"}},
{label: "133", count: 15, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 85, g: 150, b: 120, hex: "#559678"}},
{label: "137", count: 15, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 56, g: 154, b: 114, hex: "#389a72"}},
{label: "052", count: 13, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 204, g: 111, b: 44, hex: "#cc6f2c"}},
{label: "076", count: 13, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 157, g: 135, b: 43, hex: "#9d872b"}},
{label: "152", count: 13, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 166, b: 87, hex: "#00a657"}},
{label: "154", count: 13, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 133, g: 138, b: 137, hex: "#858a89"}},
{label: "077", count: 12, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 157, g: 135, b: 32, hex: "#9d8720"}},
{label: "135", count: 12, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 72, g: 152, b: 118, hex: "#489876"}},
{label: "030", count: 11, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 238, g: 77, b: 88, hex: "#ee4d58"}},
{label: "051", count: 11, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 201, g: 113, b: 50, hex: "#c97132"}},
{label: "061", count: 11, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 225, g: 95, b: 0, hex: "#e15f00"}},
{label: "064", count: 11, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 141, g: 136, b: 125, hex: "#8d887d"}},
{label: "096", count: 11, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 135, g: 139, b: 121, hex: "#878b79"}},
{label: "134", count: 11, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 80, g: 150, b: 120, hex: "#509678"}},
{label: "151", count: 11, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 165, b: 89, hex: "#00a559"}},
{label: "167", count: 11, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 19, g: 154, b: 144, hex: "#139a90"}},
{label: "168", count: 11, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 155, b: 146, hex: "#009b92"}},
{label: "047", count: 10, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 189, g: 119, b: 69, hex: "#bd7745"}},
{label: "049", count: 10, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 194, g: 116, b: 60, hex: "#c2743c"}},
{label: "050", count: 10, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 197, g: 115, b: 56, hex: "#c57338"}},
{label: "060", count: 10, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 222, g: 98, b: 0, hex: "#de6200"}},
{label: "062", count: 10, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 228, g: 93, b: 0, hex: "#e45d00"}},
{label: "065", count: 10, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 142, g: 136, b: 119, hex: "#8e8877"}},
{label: "136", count: 10, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 63, g: 153, b: 116, hex: "#3f9974"}},
{label: "010", count: 9, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 172, g: 124, b: 122, hex: "#ac7c7a"}},
{label: "057", count: 9, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 215, g: 103, b: 3, hex: "#d76703"}},
{label: "059", count: 9, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 220, g: 100, b: 0, hex: "#dc6400"}},
{label: "138", count: 9, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 44, g: 155, b: 112, hex: "#2c9b70"}},
{label: "150", count: 9, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 164, b: 91, hex: "#00a45b"}},
{label: "155", count: 9, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 127, g: 140, b: 137, hex: "#7f8c89"}},
{label: "166", count: 9, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 43, g: 153, b: 144, hex: "#2b9990"}},
{label: "009", count: 8, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 169, g: 126, b: 124, hex: "#a97e7c"}},
{label: "031", count: 8, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 242, g: 71, b: 84, hex: "#f24754"}},
{label: "048", count: 8, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 193, g: 217, b: 65, hex: "#c1d941"}},
{label: "053", count: 8, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 205, g: 110, b: 39, hex: "#cd6e27"}},
{label: "063", count: 8, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 139, g: 137, b: 132, hex: "#8b8984"}},
{label: "074", count: 8, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 155, g: 135, b: 58, hex: "#9b873a"}},
{label: "149", count: 8, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 164, b: 93, hex: "#00a45d"}},
{label: "169", count: 8, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 156, b: 146, hex: "#009c92"}},
{label: "029", count: 7, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 235, g: 80, b: 89, hex: "#eb5059"}},
{label: "056", count: 7, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 213, g: 105, b: 17, hex: "#d56911"}},
{label: "058", count: 7, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 218, g: 101, b: 0, hex: "#da6500"}},
{label: "157", count: 7, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 117, g: 142, b: 138, hex: "#758e8a"}},
{label: "028", count: 6, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 233, g: 83, b: 91, hex: "#e9535b"}},
{label: "078", count: 6, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 158, g: 135, b: 21, hex: "#9e8715"}},
{label: "079", count: 6, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 158, g: 135, b: 0, hex: "#9e8700"}},
{label: "099", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 132, g: 140, b: 105, hex: "#848c69"}},
{label: "100", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 130, g: 141, b: 99, hex: "#828d63"}},
{label: "101", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 129, g: 142, b: 94, hex: "#818e5e"}},
{label: "106", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 122, g: 145, b: 63, hex: "#7a913f"}},
{label: "114", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 108, g: 149, b: 0, hex: "#6c9500"}},
{label: "116", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 103, g: 151, b: 0, hex: "#679700"}},
{label: "132", count: 6, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 92, g: 148, b: 122, hex: "#5c947a"}},
{label: "158", count: 6, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 110, g: 144, b: 140, hex: "#6e908c"}},
{label: "032", count: 5, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 245, g: 68, b: 83, hex: "#f54453"}},
{label: "045", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 184, g: 121, b: 80, hex: "#b87950"}},
{label: "046", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 186, g: 120, b: 75, hex: "#ba784b"}},
{label: "055", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 211, g: 106, b: 27, hex: "#d36a1b"}},
{label: "075", count: 5, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 156, g: 135, b: 52, hex: "#9c8734"}},
{label: "080", count: 5, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 159, g: 135, b: 0, hex: "#9f8700"}},
{label: "084", count: 5, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "097", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 134, g: 139, b: 116, hex: "#868b74"}},
{label: "098", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 132, g: 140, b: 110, hex: "#848c6e"}},
{label: "102", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 129, g: 142, b: 86, hex: "#818e56"}},
{label: "107", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 122, g: 145, b: 56, hex: "#7a9138"}},
{label: "113", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 109, g: 149, b: 0, hex: "#6d9500"}},
{label: "115", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 106, g: 150, b: 0, hex: "#6a9600"}},
{label: "139", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 27, g: 156, b: 110, hex: "#1b9c6e"}},
{label: "170", count: 5, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 157, b: 146, hex: "#009d92"}},
{label: "008", count: 4, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 165, g: 127, b: 125, hex: "#a57f7d"}},
{label: "033", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 141, g: 136, b: 133, hex: "#8d8885"}},
{label: "054", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 208, g: 108, b: 32, hex: "#d06c20"}},
{label: "066", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 144, g: 136, b: 112, hex: "#908870"}},
{label: "072", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 153, g: 135, b: 72, hex: "#998748"}},
{label: "081", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 159, g: 135, b: 0, hex: "#9f8700"}},
{label: "083", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 161, g: 134, b: 0, hex: "#a18600"}},
{label: "094", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 137, g: 137, b: 132, hex: "#898984"}},
{label: "095", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 137, g: 138, b: 126, hex: "#898a7e"}},
{label: "103", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 127, g: 143, b: 81, hex: "#7f8f51"}},
{label: "117", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 101, g: 151, b: 0, hex: "#659700"}},
{label: "118", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 97, g: 152, b: 0, hex: "#619800"}},
{label: "128", count: 4, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 114, g: 143, b: 129, hex: "#728f81"}},
{label: "130", count: 4, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 103, g: 146, b: 126, hex: "#67927e"}},
{label: "156", count: 4, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 123, g: 141, b: 138, hex: "#7b8d8a"}},
{label: "011", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 178, g: 122, b: 119, hex: "#b27a77"}},
{label: "027", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 230, g: 86, b: 92, hex: "#e6565c"}},
{label: "043", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 177, g: 124, b: 89, hex: "#b17c59"}},
{label: "044", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 181, g: 123, b: 83, hex: "#b57b53"}},
{label: "073", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 154, g: 135, b: 67, hex: "#9a8743"}},
{label: "082", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 160, g: 135, b: 0, hex: "#a08700"}},
{label: "092", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "112", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 112, g: 148, b: 5, hex: "#709405"}},
{label: "142", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 158, b: 105, hex: "#009e69"}},
{label: "146", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 162, b: 99, hex: "#00a263"}},
{label: "147", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 162, b: 97, hex: "#00a261"}},
{label: "148", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 163, b: 95, hex: "#00a35f"}},
{label: "165", count: 3, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 55, g: 152, b: 143, hex: "#37988f"}},
{label: "067", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 146, g: 136, b: 105, hex: "#928869"}},
{label: "085", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "087", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "104", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 126, g: 144, b: 75, hex: "#7e904b"}},
{label: "111", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 113, g: 148, b: 22, hex: "#719416"}},
{label: "119", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 95, g: 152, b: 0, hex: "#5f9800"}},
{label: "120", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 93, g: 152, b: 0, hex: "#5d9800"}},
{label: "129", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 109, g: 145, b: 127, hex: "#6d917f"}},
{label: "131", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 99, g: 147, b: 124, hex: "#63937c"}},
{label: "140", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 7, g: 156, b: 108, hex: "#079c6c"}},
{label: "012", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 181, g: 120, b: 117, hex: "#b57875"}},
{label: "018", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 201, g: 109, b: 108, hex: "#c96d6c"}},
{label: "026", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 227, g: 89, b: 94, hex: "#e3595e"}},
{label: "086", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "093", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 164, g: 133, b: 0, hex: "#a48500"}},
{label: "105", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 125, g: 144, b: 69, hex: "#7d9045"}},
{label: "109", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 118, g: 147, b: 42, hex: "#76932a"}},
{label: "110", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 117, g: 147, b: 33, hex: "#759321"}},
{label: "121", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 89, g: 153, b: 0, hex: "#599900"}},
{label: "122", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 87, g: 154, b: 0, hex: "#579a00"}},
{label: "123", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 83, g: 154, b: 0, hex: "#539a00"}},
{label: "125", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 128, g: 139, b: 135, hex: "#808b87"}},
{label: "126", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 123, g: 141, b: 133, hex: "#7b8d85"}},
{label: "141", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 157, b: 107, hex: "#009d6b"}},
{label: "144", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 160, b: 103, hex: "#00a067"}},
{label: "145", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 161, b: 101, hex: "#00a165"}},
{label: "164", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 67, g: 151, b: 143, hex: "#43978f"}},
{label: "001", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 136, g: 136, b: 135, hex: "#888887"}},
{label: "002", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 141, g: 136, b: 133, hex: "#8d8885"}},
{label: "003", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 146, g: 134, b: 132, hex: "#928684"}},
{label: "004", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 149, g: 133, b: 130, hex: "#958582"}},
{label: "005", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 146, g: 131, b: 128, hex: "#928380"}},
{label: "006", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 159, g: 130, b: 127, hex: "#9f827f"}},
{label: "007", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 162, g: 129, b: 125, hex: "#a2817d"}},
{label: "013", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 184, g: 119, b: 116, hex: "#b87774"}},
{label: "014", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 187, g: 117, b: 114, hex: "#bb7572"}},
{label: "015", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 190, g: 116, b: 112, hex: "#be7470"}},
{label: "016", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 195, g: 113, b: 111, hex: "#c3716f"}},
{label: "017", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 198, g: 111, b: 109, hex: "#c66f6d"}},
{label: "019", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 204, g: 107, b: 106, hex: "#cc6b6a"}},
{label: "020", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 207, g: 105, b: 105, hex: "#cf6969"}},
{label: "021", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 212, g: 102, b: 101, hex: "#d46665"}},
{label: "022", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 215, g: 100, b: 100, hex: "#d76464"}},
{label: "023", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 217, g: 98, b: 98, hex: "#d96262"}},
{label: "024", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 220, g: 95, b: 97, hex: "#dc5f61"}},
{label: "025", count: 0, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 223, g: 93, b: 95, hex: "#df5d5f"}},
{label: "034", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 145, g: 135, b: 128, hex: "#918780"}},
{label: "035", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 150, g: 134, b: 123, hex: "#96867b"}},
{label: "036", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 154, g: 132, b: 120, hex: "#9a8478"}},
{label: "037", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 157, g: 132, b: 115, hex: "#9d8473"}},
{label: "038", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 161, g: 130, b: 111, hex: "#a1826f"}},
{label: "039", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 165, g: 129, b: 106, hex: "#a5816a"}},
{label: "040", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 168, g: 128, b: 101, hex: "#a88065"}},
{label: "041", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 171, g: 127, b: 97, hex: "#ab7f61"}},
{label: "042", count: 0, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 175, g: 125, b: 92, hex: "#af7d5c"}},
{label: "068", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 147, g: 136, b: 100, hex: "#938864"}},
{label: "069", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 148, g: 136, b: 93, hex: "#94885d"}},
{label: "070", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 149, g: 136, b: 87, hex: "#958857"}},
{label: "071", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 151, g: 136, b: 80, hex: "#978850"}},
{label: "088", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "089", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "090", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "091", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "108", count: 0, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 119, g: 146, b: 49, hex: "#779231"}},
{label: "124", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 134, g: 138, b: 135, hex: "#868a87"}},
{label: "127", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 120, g: 142, b: 131, hex: "#788e83"}},
{label: "143", count: 0, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 159, b: 103, hex: "#009f67"}},
{label: "159", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 105, g: 145, b: 140, hex: "#69918c"}},
{label: "160", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 98, g: 146, b: 140, hex: "#62928c"}},
{label: "161", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 92, g: 147, b: 141, hex: "#5c938d"}},
{label: "162", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 84, g: 148, b: 141, hex: "#54948d"}},
{label: "163", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 74, g: 150, b: 143, hex: "#4a968f"}},
{label: "171", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 157, b: 147, hex: "#009d93"}},
{label: "172", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 158, b: 147, hex: "#009e93"}},
{label: "173", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 159, b: 149, hex: "#009f95"}},
{label: "174", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 160, b: 149, hex: "#00a095"}},
{label: "175", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 161, b: 149, hex: "#00a195"}},
{label: "176", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 162, b: 151, hex: "#00a297"}},
{label: "177", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 163, b: 151, hex: "#00a397"}},
{label: "178", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 163, b: 152, hex: "#00a398"}},
{label: "179", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 164, b: 152, hex: "#00a498"}},
{label: "180", count: 0, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 165, b: 152, hex: "#00a598"}},
]
export const chroma_An_An_count = 13
export const chroma_An_An_ranking = [
{label: "153", count: 9, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 167, b: 85, hex: "#00a755"}},
{label: "154", count: 9, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 133, g: 138, b: 137, hex: "#858a89"}},
{label: "096", count: 8, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 135, g: 139, b: 121, hex: "#878b79"}},
{label: "168", count: 8, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 155, b: 146, hex: "#009b92"}},
{label: "010", count: 7, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 172, g: 124, b: 122, hex: "#ac7c7a"}},
{label: "044", count: 7, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 181, g: 123, b: 83, hex: "#b57b53"}},
{label: "050", count: 7, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 197, g: 115, b: 56, hex: "#c57338"}},
{label: "052", count: 7, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 204, g: 111, b: 44, hex: "#cc6f2c"}},
{label: "058", count: 7, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 218, g: 101, b: 0, hex: "#da6500"}},
{label: "061", count: 7, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 225, g: 95, b: 0, hex: "#e15f00"}},
{label: "062", count: 7, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 228, g: 93, b: 0, hex: "#e45d00"}},
{label: "065", count: 7, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 142, g: 136, b: 119, hex: "#8e8877"}},
{label: "076", count: 7, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 157, g: 135, b: 43, hex: "#9d872b"}},
{label: "077", count: 7, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 157, g: 135, b: 32, hex: "#9d8720"}},
{label: "078", count: 7, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 158, g: 135, b: 21, hex: "#9e8715"}},
{label: "079", count: 7, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 158, g: 135, b: 0, hex: "#9e8700"}},
{label: "148", count: 7, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 163, b: 95, hex: "#00a35f"}},
{label: "149", count: 7, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 164, b: 93, hex: "#00a45d"}},
{label: "152", count: 7, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 166, b: 87, hex: "#00a657"}},
{label: "047", count: 6, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 189, g: 119, b: 69, hex: "#bd7745"}},
{label: "063", count: 6, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 139, g: 137, b: 132, hex: "#8b8984"}},
{label: "064", count: 6, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 141, g: 136, b: 125, hex: "#8d887d"}},
{label: "074", count: 6, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 155, g: 135, b: 58, hex: "#9b873a"}},
{label: "098", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 132, g: 140, b: 110, hex: "#848c6e"}},
{label: "103", count: 6, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 127, g: 143, b: 81, hex: "#7f8f51"}},
{label: "133", count: 6, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 85, g: 150, b: 120, hex: "#559678"}},
{label: "137", count: 6, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 56, g: 154, b: 114, hex: "#389a72"}},
{label: "151", count: 6, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 165, b: 89, hex: "#00a559"}},
{label: "167", count: 6, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 19, g: 154, b: 144, hex: "#139a90"}},
{label: "028", count: 5, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 233, g: 83, b: 91, hex: "#e9535b"}},
{label: "030", count: 5, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 238, g: 77, b: 88, hex: "#ee4d58"}},
{label: "031", count: 5, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 242, g: 71, b: 84, hex: "#f24754"}},
{label: "056", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 213, g: 105, b: 17, hex: "#d56911"}},
{label: "059", count: 5, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 220, g: 100, b: 0, hex: "#dc6400"}},
{label: "075", count: 5, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 156, g: 135, b: 52, hex: "#9c8734"}},
{label: "085", count: 5, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "094", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 137, g: 137, b: 132, hex: "#898984"}},
{label: "119", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 95, g: 152, b: 0, hex: "#5f9800"}},
{label: "122", count: 5, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 87, g: 154, b: 0, hex: "#579a00"}},
{label: "126", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 123, g: 141, b: 133, hex: "#7b8d85"}},
{label: "134", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 80, g: 150, b: 120, hex: "#509678"}},
{label: "135", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 72, g: 152, b: 118, hex: "#489876"}},
{label: "138", count: 5, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 44, g: 155, b: 112, hex: "#2c9b70"}},
{label: "157", count: 5, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 117, g: 142, b: 138, hex: "#758e8a"}},
{label: "009", count: 4, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 169, g: 126, b: 124, hex: "#a97e7c"}},
{label: "011", count: 4, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 178, g: 122, b: 119, hex: "#b27a77"}},
{label: "032", count: 4, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 245, g: 68, b: 83, hex: "#f54453"}},
{label: "045", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 184, g: 121, b: 80, hex: "#b87950"}},
{label: "046", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 186, g: 120, b: 75, hex: "#ba784b"}},
{label: "051", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 201, g: 113, b: 50, hex: "#c97132"}},
{label: "057", count: 4, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 215, g: 103, b: 3, hex: "#d76703"}},
{label: "066", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 144, g: 136, b: 112, hex: "#908870"}},
{label: "084", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "087", count: 4, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "095", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 137, g: 138, b: 126, hex: "#898a7e"}},
{label: "097", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 134, g: 139, b: 116, hex: "#868b74"}},
{label: "099", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 132, g: 140, b: 105, hex: "#848c69"}},
{label: "100", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 130, g: 141, b: 99, hex: "#828d63"}},
{label: "101", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 129, g: 142, b: 94, hex: "#818e5e"}},
{label: "104", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 126, g: 144, b: 75, hex: "#7e904b"}},
{label: "115", count: 4, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 106, g: 150, b: 0, hex: "#6a9600"}},
{label: "136", count: 4, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 63, g: 153, b: 116, hex: "#3f9974"}},
{label: "144", count: 4, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 160, b: 103, hex: "#00a067"}},
{label: "150", count: 4, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 164, b: 91, hex: "#00a45b"}},
{label: "155", count: 4, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 127, g: 140, b: 137, hex: "#7f8c89"}},
{label: "165", count: 4, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 55, g: 152, b: 143, hex: "#37988f"}},
{label: "006", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 159, g: 130, b: 127, hex: "#9f827f"}},
{label: "025", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 223, g: 93, b: 95, hex: "#df5d5f"}},
{label: "027", count: 3, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 230, g: 86, b: 92, hex: "#e6565c"}},
{label: "043", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 177, g: 124, b: 89, hex: "#b17c59"}},
{label: "048", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 193, g: 217, b: 65, hex: "#c1d941"}},
{label: "049", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 194, g: 116, b: 60, hex: "#c2743c"}},
{label: "053", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 205, g: 110, b: 39, hex: "#cd6e27"}},
{label: "055", count: 3, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 211, g: 106, b: 27, hex: "#d36a1b"}},
{label: "080", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 159, g: 135, b: 0, hex: "#9f8700"}},
{label: "081", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 159, g: 135, b: 0, hex: "#9f8700"}},
{label: "082", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 160, g: 135, b: 0, hex: "#a08700"}},
{label: "083", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 161, g: 134, b: 0, hex: "#a18600"}},
{label: "086", count: 3, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "106", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 122, g: 145, b: 63, hex: "#7a913f"}},
{label: "107", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 122, g: 145, b: 56, hex: "#7a9138"}},
{label: "114", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 108, g: 149, b: 0, hex: "#6c9500"}},
{label: "117", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 101, g: 151, b: 0, hex: "#659700"}},
{label: "118", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 97, g: 152, b: 0, hex: "#619800"}},
{label: "121", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 89, g: 153, b: 0, hex: "#599900"}},
{label: "123", count: 3, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 83, g: 154, b: 0, hex: "#539a00"}},
{label: "124", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 134, g: 138, b: 135, hex: "#868a87"}},
{label: "125", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 128, g: 139, b: 135, hex: "#808b87"}},
{label: "130", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 103, g: 146, b: 126, hex: "#67927e"}},
{label: "131", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 99, g: 147, b: 124, hex: "#63937c"}},
{label: "139", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 27, g: 156, b: 110, hex: "#1b9c6e"}},
{label: "141", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 157, b: 107, hex: "#009d6b"}},
{label: "142", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 158, b: 105, hex: "#009e69"}},
{label: "145", count: 3, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 161, b: 101, hex: "#00a165"}},
{label: "156", count: 3, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 123, g: 141, b: 138, hex: "#7b8d8a"}},
{label: "166", count: 3, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 43, g: 153, b: 144, hex: "#2b9990"}},
{label: "169", count: 3, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 156, b: 146, hex: "#009c92"}},
{label: "004", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 149, g: 133, b: 130, hex: "#958582"}},
{label: "007", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 162, g: 129, b: 125, hex: "#a2817d"}},
{label: "024", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 220, g: 95, b: 97, hex: "#dc5f61"}},
{label: "026", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 227, g: 89, b: 94, hex: "#e3595e"}},
{label: "029", count: 2, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 235, g: 80, b: 89, hex: "#eb5059"}},
{label: "033", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 141, g: 136, b: 133, hex: "#8d8885"}},
{label: "036", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 154, g: 132, b: 120, hex: "#9a8478"}},
{label: "038", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 161, g: 130, b: 111, hex: "#a1826f"}},
{label: "040", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 168, g: 128, b: 101, hex: "#a88065"}},
{label: "060", count: 2, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 222, g: 98, b: 0, hex: "#de6200"}},
{label: "067", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 146, g: 136, b: 105, hex: "#928869"}},
{label: "071", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 151, g: 136, b: 80, hex: "#978850"}},
{label: "072", count: 2, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 153, g: 135, b: 72, hex: "#998748"}},
{label: "105", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 125, g: 144, b: 69, hex: "#7d9045"}},
{label: "108", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 119, g: 146, b: 49, hex: "#779231"}},
{label: "109", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 118, g: 147, b: 42, hex: "#76932a"}},
{label: "112", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 112, g: 148, b: 5, hex: "#709405"}},
{label: "116", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 103, g: 151, b: 0, hex: "#679700"}},
{label: "120", count: 2, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 93, g: 152, b: 0, hex: "#5d9800"}},
{label: "127", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 120, g: 142, b: 131, hex: "#788e83"}},
{label: "128", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 114, g: 143, b: 129, hex: "#728f81"}},
{label: "129", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 109, g: 145, b: 127, hex: "#6d917f"}},
{label: "140", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 7, g: 156, b: 108, hex: "#079c6c"}},
{label: "146", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 162, b: 99, hex: "#00a263"}},
{label: "147", count: 2, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 162, b: 97, hex: "#00a261"}},
{label: "158", count: 2, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 110, g: 144, b: 140, hex: "#6e908c"}},
{label: "162", count: 2, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 84, g: 148, b: 141, hex: "#54948d"}},
{label: "163", count: 2, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 74, g: 150, b: 143, hex: "#4a968f"}},
{label: "164", count: 2, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 67, g: 151, b: 143, hex: "#43978f"}},
{label: "171", count: 2, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 157, b: 147, hex: "#009d93"}},
{label: "001", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 136, g: 136, b: 135, hex: "#888887"}},
{label: "002", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 141, g: 136, b: 133, hex: "#8d8885"}},
{label: "003", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 146, g: 134, b: 132, hex: "#928684"}},
{label: "005", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 146, g: 131, b: 128, hex: "#928380"}},
{label: "008", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 165, g: 127, b: 125, hex: "#a57f7d"}},
{label: "012", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 181, g: 120, b: 117, hex: "#b57875"}},
{label: "013", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 184, g: 119, b: 116, hex: "#b87774"}},
{label: "014", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 187, g: 117, b: 114, hex: "#bb7572"}},
{label: "015", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 190, g: 116, b: 112, hex: "#be7470"}},
{label: "016", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 195, g: 113, b: 111, hex: "#c3716f"}},
{label: "017", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 198, g: 111, b: 109, hex: "#c66f6d"}},
{label: "018", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 201, g: 109, b: 108, hex: "#c96d6c"}},
{label: "019", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 204, g: 107, b: 106, hex: "#cc6b6a"}},
{label: "020", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 207, g: 105, b: 105, hex: "#cf6969"}},
{label: "021", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 212, g: 102, b: 101, hex: "#d46665"}},
{label: "022", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 215, g: 100, b: 100, hex: "#d76464"}},
{label: "023", count: 1, color1: {r: 178, g: 122, b: 119, hex: "#b27a77"}, color2: {r: 217, g: 98, b: 98, hex: "#d96262"}},
{label: "034", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 145, g: 135, b: 128, hex: "#918780"}},
{label: "035", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 150, g: 134, b: 123, hex: "#96867b"}},
{label: "037", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 157, g: 132, b: 115, hex: "#9d8473"}},
{label: "039", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 165, g: 129, b: 106, hex: "#a5816a"}},
{label: "041", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 171, g: 127, b: 97, hex: "#ab7f61"}},
{label: "042", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 175, g: 125, b: 92, hex: "#af7d5c"}},
{label: "054", count: 1, color1: {r: 175, g: 125, b: 92, hex: "#af7d5c"}, color2: {r: 208, g: 108, b: 32, hex: "#d06c20"}},
{label: "068", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 147, g: 136, b: 100, hex: "#938864"}},
{label: "069", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 148, g: 136, b: 93, hex: "#94885d"}},
{label: "070", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 149, g: 136, b: 87, hex: "#958857"}},
{label: "073", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 154, g: 135, b: 67, hex: "#9a8743"}},
{label: "088", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 162, g: 134, b: 0, hex: "#a28600"}},
{label: "089", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "090", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "091", count: 1, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "102", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 129, g: 142, b: 86, hex: "#818e56"}},
{label: "110", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 117, g: 147, b: 33, hex: "#759321"}},
{label: "111", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 113, g: 148, b: 22, hex: "#719416"}},
{label: "113", count: 1, color1: {r: 127, g: 143, b: 81, hex: "#7f8f51"}, color2: {r: 109, g: 149, b: 0, hex: "#6d9500"}},
{label: "132", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 92, g: 148, b: 122, hex: "#5c947a"}},
{label: "143", count: 1, color1: {r: 85, g: 150, b: 120, hex: "#559678"}, color2: {r: 0, g: 159, b: 103, hex: "#009f67"}},
{label: "159", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 105, g: 145, b: 140, hex: "#69918c"}},
{label: "160", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 98, g: 146, b: 140, hex: "#62928c"}},
{label: "161", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 92, g: 147, b: 141, hex: "#5c938d"}},
{label: "170", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 157, b: 146, hex: "#009d92"}},
{label: "172", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 158, b: 147, hex: "#009e93"}},
{label: "173", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 159, b: 149, hex: "#009f95"}},
{label: "174", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 160, b: 149, hex: "#00a095"}},
{label: "175", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 161, b: 149, hex: "#00a195"}},
{label: "176", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 162, b: 151, hex: "#00a297"}},
{label: "177", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 163, b: 151, hex: "#00a397"}},
{label: "178", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 163, b: 152, hex: "#00a398"}},
{label: "179", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 164, b: 152, hex: "#00a498"}},
{label: "180", count: 1, color1: {r: 74, g: 150, b: 143, hex: "#4a968f"}, color2: {r: 0, g: 165, b: 152, hex: "#00a598"}},
{label: "092", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 163, g: 134, b: 0, hex: "#a38600"}},
{label: "093", count: 0, color1: {r: 143, g: 140, b: 73, hex: "#8f8c49"}, color2: {r: 164, g: 133, b: 0, hex: "#a48500"}},
]
