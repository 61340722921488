import {BackgroundMode, StrictMode} from "@/utils/judge/threshold/judge_data";

export const D_Threshold_Hue = {}
export const D_Threshold_Brightness = {}
export const D_Threshold_Chroma = {}
D_Threshold_Hue[StrictMode.Yurume.value] = {}
D_Threshold_Brightness[StrictMode.Yurume.value] = {}
D_Threshold_Chroma[StrictMode.Yurume.value] = {}
D_Threshold_Brightness[StrictMode.Yurume.value] [BackgroundMode.An.value] = {
	"R_R": 10, "R_L": 11, "YR_R": 10, "YR_L": 11, "Y_R": 9, "Y_L": 10, "GY_R": 10, "GY_L": 8, "G_R": 7, "G_L": 11, "BG_R": 12, "BG_L": 16, "B_R": 10, "B_L": 19, "PB_R": 13, "PB_L": 10, "P_R": 11, "P_L": 10, "RP_R": 12, "RP_L": 10, "N": 11, 
}
D_Threshold_Brightness[StrictMode.Yurume.value] [BackgroundMode.Chu.value] = {
	"R_R": 9, "R_L": 6, "YR_R": 6, "YR_L": 6, "Y_R": 7, "Y_L": 6, "GY_R": 7, "GY_L": 6, "G_R": 0, "G_L": 7, "BG_R": 7, "BG_L": 7, "B_R": 8, "B_L": 8, "PB_R": 8, "PB_L": 7, "P_R": 6, "P_L": 7, "RP_R": 8, "RP_L": 6, "N": 6, 
}
D_Threshold_Brightness[StrictMode.Yurume.value] [BackgroundMode.Mei.value] = {
	"R_R": 9, "R_L": 8, "YR_R": 9, "YR_L": 8, "Y_R": 10, "Y_L": 7, "GY_R": 8, "GY_L": 9, "G_R": 7, "G_L": 8, "BG_R": 10, "BG_L": 9, "B_R": 9, "B_L": 9, "PB_R": 10, "PB_L": 9, "P_R": 10, "P_L": 8, "RP_R": 10, "RP_L": 7, "N": 8, 
}
D_Threshold_Brightness[StrictMode.Yurume.value] [BackgroundMode.Zentai.value] = {
	"R_R": 9, "R_L": 8, "YR_R": 8, "YR_L": 8, "Y_R": 8, "Y_L": 7, "GY_R": 8, "GY_L": 7, "G_R": 4, "G_L": 8, "BG_R": 9, "BG_L": 10, "B_R": 9, "B_L": 12, "PB_R": 10, "PB_L": 8, "P_R": 9, "P_L": 8, "RP_R": 10, "RP_L": 7, "N": 8, 
}
D_Threshold_Hue[StrictMode.Yurume.value] [BackgroundMode.An.value] = {
	"R_R": 11, "R_L": 13, "YR_R": 13, "YR_L": 19, "Y_R": 20, "Y_L": 8, "GY_R": 18, "GY_L": 14, "G_R": 18, "G_L": 15, "BG_R": 13, "BG_L": 14, "B_R": 13, "B_L": 19, "PB_R": 18, "PB_L": 25, "P_R": 25, "P_L": 12, "RP_R": 13, "RP_L": 13, "N": 15, 
}
D_Threshold_Hue[StrictMode.Yurume.value] [BackgroundMode.Chu.value] = {
	"R_R": 5, "R_L": 4, "YR_R": 5, "YR_L": 18, "Y_R": 17, "Y_L": 8, "GY_R": 14, "GY_L": 12, "G_R": 16, "G_L": 11, "BG_R": 7, "BG_L": 7, "B_R": 14, "B_L": 22, "PB_R": 14, "PB_L": 16, "P_R": 15, "P_L": 9, "RP_R": 11, "RP_L": 11, "N": 11, 
}
D_Threshold_Hue[StrictMode.Yurume.value] [BackgroundMode.Mei.value] = {
	"R_R": 13, "R_L": 15, "YR_R": 11, "YR_L": 26, "Y_R": 22, "Y_L": 10, "GY_R": 25, "GY_L": 15, "G_R": 19, "G_L": 12, "BG_R": 13, "BG_L": 11, "B_R": 17, "B_L": 27, "PB_R": 22, "PB_L": 20, "P_R": 25, "P_L": 14, "RP_R": 15, "RP_L": 16, "N": 17, 
}
D_Threshold_Hue[StrictMode.Yurume.value] [BackgroundMode.Zentai.value] = {
	"R_R": 9, "R_L": 10, "YR_R": 9, "YR_L": 21, "Y_R": 19, "Y_L": 8, "GY_R": 19, "GY_L": 13, "G_R": 17, "G_L": 12, "BG_R": 11, "BG_L": 10, "B_R": 14, "B_L": 22, "PB_R": 18, "PB_L": 20, "P_R": 21, "P_L": 11, "RP_R": 13, "RP_L": 13, "N": 14, 
}
D_Threshold_Hue[StrictMode.Futu.value] = {}
D_Threshold_Brightness[StrictMode.Futu.value] = {}
D_Threshold_Chroma[StrictMode.Futu.value] = {}
D_Threshold_Brightness[StrictMode.Futu.value] [BackgroundMode.An.value] = {
	"R_R": 12, "R_L": 12, "YR_R": 12, "YR_L": 13, "Y_R": 11, "Y_L": 13, "GY_R": 13, "GY_L": 10, "G_R": 9, "G_L": 13, "BG_R": 14, "BG_L": 14, "B_R": 12, "B_L": 23, "PB_R": 15, "PB_L": 11, "P_R": 12, "P_L": 12, "RP_R": 14, "RP_L": 12, "N": 12, 
}
D_Threshold_Brightness[StrictMode.Futu.value] [BackgroundMode.Chu.value] = {
	"R_R": 11, "R_L": 8, "YR_R": 9, "YR_L": 8, "Y_R": 9, "Y_L": 8, "GY_R": 9, "GY_L": 8, "G_R": 5, "G_L": 9, "BG_R": 9, "BG_L": 9, "B_R": 10, "B_L": 9, "PB_R": 10, "PB_L": 9, "P_R": 10, "P_L": 8, "RP_R": 10, "RP_L": 8, "N": 8, 
}
D_Threshold_Brightness[StrictMode.Futu.value] [BackgroundMode.Mei.value] = {
	"R_R": 10, "R_L": 10, "YR_R": 11, "YR_L": 9, "Y_R": 12, "Y_L": 9, "GY_R": 10, "GY_L": 11, "G_R": 9, "G_L": 10, "BG_R": 11, "BG_L": 10, "B_R": 11, "B_L": 11, "PB_R": 12, "PB_L": 10, "P_R": 12, "P_L": 9, "RP_R": 11, "RP_L": 9, "N": 10, 
}
D_Threshold_Brightness[StrictMode.Futu.value] [BackgroundMode.Zentai.value] = {
	"R_R": 11, "R_L": 10, "YR_R": 10, "YR_L": 10, "Y_R": 10, "Y_L": 10, "GY_R": 10, "GY_L": 9, "G_R": 7, "G_L": 10, "BG_R": 11, "BG_L": 11, "B_R": 11, "B_L": 14, "PB_R": 12, "PB_L": 10, "P_R": 11, "P_L": 9, "RP_R": 11, "RP_L": 9, "N": 10, 
}
D_Threshold_Hue[StrictMode.Futu.value] [BackgroundMode.An.value] = {
	"R_R": 14, "R_L": 19, "YR_R": 16, "YR_L": 23, "Y_R": 25, "Y_L": 9, "GY_R": 22, "GY_L": 17, "G_R": 20, "G_L": 18, "BG_R": 16, "BG_L": 17, "B_R": 16, "B_L": 24, "PB_R": 21, "PB_L": 28, "P_R": 30, "P_L": 15, "RP_R": 16, "RP_L": 16, "N": 19, 
}
D_Threshold_Hue[StrictMode.Futu.value] [BackgroundMode.Chu.value] = {
	"R_R": 11, "R_L": 11, "YR_R": 11, "YR_L": 23, "Y_R": 20, "Y_L": 9, "GY_R": 18, "GY_L": 15, "G_R": 19, "G_L": 15, "BG_R": 11, "BG_L": 13, "B_R": 17, "B_L": 28, "PB_R": 18, "PB_L": 20, "P_R": 19, "P_L": 13, "RP_R": 14, "RP_L": 13, "N": 15, 
}
D_Threshold_Hue[StrictMode.Futu.value] [BackgroundMode.Mei.value] = {
	"R_R": 15, "R_L": 19, "YR_R": 15, "YR_L": 33, "Y_R": 27, "Y_L": 12, "GY_R": 32, "GY_L": 18, "G_R": 22, "G_L": 16, "BG_R": 17, "BG_L": 17, "B_R": 21, "B_L": 32, "PB_R": 26, "PB_L": 24, "P_R": 30, "P_L": 17, "RP_R": 19, "RP_L": 19, "N": 21, 
}
D_Threshold_Hue[StrictMode.Futu.value] [BackgroundMode.Zentai.value] = {
	"R_R": 13, "R_L": 16, "YR_R": 14, "YR_L": 26, "Y_R": 24, "Y_L": 10, "GY_R": 24, "GY_L": 16, "G_R": 20, "G_L": 16, "BG_R": 14, "BG_L": 15, "B_R": 18, "B_L": 28, "PB_R": 21, "PB_L": 24, "P_R": 26, "P_L": 15, "RP_R": 16, "RP_L": 16, "N": 18, 
}
D_Threshold_Hue[StrictMode.Kitume.value] = {}
D_Threshold_Brightness[StrictMode.Kitume.value] = {}
D_Threshold_Chroma[StrictMode.Kitume.value] = {}
D_Threshold_Brightness[StrictMode.Kitume.value] [BackgroundMode.An.value] = {
	"R_R": 14, "R_L": 15, "YR_R": 15, "YR_L": 17, "Y_R": 15, "Y_L": 17, "GY_R": 16, "GY_L": 13, "G_R": 12, "G_L": 17, "BG_R": 17, "BG_L": 23, "B_R": 14, "B_L": 27, "PB_R": 18, "PB_L": 14, "P_R": 15, "P_L": 14, "RP_R": 17, "RP_L": 15, "N": 16, 
}
D_Threshold_Brightness[StrictMode.Kitume.value] [BackgroundMode.Chu.value] = {
	"R_R": 13, "R_L": 10, "YR_R": 13, "YR_L": 11, "Y_R": 13, "Y_L": 11, "GY_R": 12, "GY_L": 11, "G_R": 15, "G_L": 12, "BG_R": 12, "BG_L": 12, "B_R": 13, "B_L": 11, "PB_R": 13, "PB_L": 12, "P_R": 13, "P_L": 12, "RP_R": 13, "RP_L": 10, "N": 12, 
}
D_Threshold_Brightness[StrictMode.Kitume.value] [BackgroundMode.Mei.value] = {
	"R_R": 13, "R_L": 13, "YR_R": 13, "YR_L": 11, "Y_R": 15, "Y_L": 12, "GY_R": 13, "GY_L": 13, "G_R": 11, "G_L": 13, "BG_R": 14, "BG_L": 12, "B_R": 13, "B_L": 13, "PB_R": 14, "PB_L": 12, "P_R": 14, "P_L": 11, "RP_R": 13, "RP_L": 11, "N": 12, 
}
D_Threshold_Brightness[StrictMode.Kitume.value] [BackgroundMode.Zentai.value] = {
	"R_R": 13, "R_L": 12, "YR_R": 13, "YR_L": 13, "Y_R": 14, "Y_L": 13, "GY_R": 13, "GY_L": 12, "G_R": 12, "G_L": 14, "BG_R": 14, "BG_L": 15, "B_R": 13, "B_L": 17, "PB_R": 15, "PB_L": 12, "P_R": 14, "P_L": 12, "RP_R": 14, "RP_L": 12, "N": 13, 
}
D_Threshold_Hue[StrictMode.Kitume.value] [BackgroundMode.An.value] = {
	"R_R": 19, "R_L": 28, "YR_R": 20, "YR_L": 28, "Y_R": 33, "Y_L": 12, "GY_R": 29, "GY_L": 22, "G_R": 25, "G_L": 23, "BG_R": 20, "BG_L": 23, "B_R": 20, "B_L": 30, "PB_R": 25, "PB_L": 33, "P_R": 37, "P_L": 20, "RP_R": 21, "RP_L": 20, "N": 24, 
}
D_Threshold_Hue[StrictMode.Kitume.value] [BackgroundMode.Chu.value] = {
	"R_R": 19, "R_L": 20, "YR_R": 19, "YR_L": 32, "Y_R": 24, "Y_L": 12, "GY_R": 25, "GY_L": 21, "G_R": 24, "G_L": 20, "BG_R": 18, "BG_L": 22, "B_R": 22, "B_L": 37, "PB_R": 22, "PB_L": 26, "P_R": 25, "P_L": 20, "RP_R": 19, "RP_L": 17, "N": 22, 
}
D_Threshold_Hue[StrictMode.Kitume.value] [BackgroundMode.Mei.value] = {
	"R_R": 19, "R_L": 25, "YR_R": 21, "YR_L": 43, "Y_R": 34, "Y_L": 14, "GY_R": 42, "GY_L": 24, "G_R": 28, "G_L": 20, "BG_R": 22, "BG_L": 26, "B_R": 27, "B_L": 40, "PB_R": 32, "PB_L": 29, "P_R": 38, "P_L": 21, "RP_R": 23, "RP_L": 23, "N": 27, 
}
D_Threshold_Hue[StrictMode.Kitume.value] [BackgroundMode.Zentai.value] = {
	"R_R": 19, "R_L": 24, "YR_R": 20, "YR_L": 34, "Y_R": 30, "Y_L": 12, "GY_R": 32, "GY_L": 22, "G_R": 25, "G_L": 21, "BG_R": 20, "BG_L": 23, "B_R": 23, "B_L": 35, "PB_R": 26, "PB_L": 29, "P_R": 33, "P_L": 20, "RP_R": 21, "RP_L": 20, "N": 24, 
}
