import {chroma_Mei_Mei_count, chroma_Mei_Mei_ranking} from "@/utils/analysis/js/chroma_ranking";
import {BackgroundMode, StrictMode} from "@/utils/judge/threshold/judge_data";
import colorJudge from "@/utils/judge/colorjudge";

function check() {
  for (let rank of chroma_Mei_Mei_ranking) {
    console.log(rank.label + ": " + rank.count+ "/" + chroma_Mei_Mei_count)
    colorJudge.colorJudge(rank.color1,
      rank.color2, "c",
      StrictMode.Futu.value, BackgroundMode.Mei.value)
    console.log(" ")
  }
}

function debug() {
  check()
}

debug()
