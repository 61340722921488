// TODO: ryb, rgb変換を直す
/*
function rybToRgb(ryb) {
  let [r, y, b] = ryb.map(v => v / 255);

  const white = Math.min(r, y, b);
  r -= white;
  y -= white;
  b -= white;

  const maxY = Math.max(r, y, b);
  const g = Math.min(y, b);

  y -= g;
  b -= g;

  if (b > 0 && g > 0) {
    b *= 2.0;
    g *= 2.0;
  }

  r += white;
  y += white;
  b += white;
  g += white;

  return [
    Math.round(r * 255),
    Math.round(g * 255),
    Math.round(b * 255)
  ];
}

function rgbToRyb(rgb) {
  let [r, g, b] = rgb.map(v => v / 255);

  const white = Math.min(r, g, b);
  r -= white;
  g -= white;
  b -= white;

  const maxG = Math.max(r, g, b);
  const y = Math.min(r, g);

  r -= y;
  g -= y;

  if (r > 0 && g > 0) {
    r *= 2.0;
    g *= 2.0;
  }

  r += white;
  y += white;
  b += white;

  return [
    Math.round(r * 255),
    Math.round(y * 255),
    Math.round(b * 255)
  ];
}

function clamp(value, min, max) {
  return Math.max(min, Math.min(max, value));
}

function clamp_rgb(values) {
  return {
    r: clamp(values.r, 0, 255),
    g: clamp(values.g, 0, 255),
    b: clamp(values.b, 0, 255)
  }
}

*/

import colorJudge, {isAnyBatsu} from "@/utils/judge/colorjudge";
import * as THREE from 'three';
import {Dichromats} from '@/shaders/Dichromats';

function rgbToHsv(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, v = max;

  let d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [Math.round(h * 360), Math.round(s * 100), Math.round(v * 100)];
}

function hsvToRgb(h, s, v) {
  h /= 360;
  s /= 100;
  v /= 100;

  let r, g, b;

  let i = Math.floor(h * 6);
  let f = h * 6 - i;
  let p = v * (1 - s);
  let q = v * (1 - f * s);
  let t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    case 0:
      r = v, g = t, b = p;
      break;
    case 1:
      r = q, g = v, b = p;
      break;
    case 2:
      r = p, g = v, b = t;
      break;
    case 3:
      r = p, g = q, b = v;
      break;
    case 4:
      r = t, g = p, b = v;
      break;
    case 5:
      r = v, g = p, b = q;
      break;
  }

  return {r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255)};
}

// シーン、カメラ、レンダラーを作成
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
const renderer = new THREE.WebGLRenderer();
renderer.setSize(window.innerWidth, window.innerHeight);

// Dichromatsシェーダーマテリアルを作成
const dichromatShader = new Dichromats();
const material = new THREE.ShaderMaterial({
  uniforms: dichromatShader.uniforms,
  vertexShader: dichromatShader.vertexShader,
  fragmentShader: dichromatShader.fragmentShader
});

// 平面を作成してシェーダーマテリアルを適用
const geometry = new THREE.PlaneGeometry(1, 1);
const plane = new THREE.Mesh(geometry, material);
scene.add(plane);

// カメラの位置を設定
camera.position.z = 5;

// オフスクリーンレンダリングの設定
const renderTarget = new THREE.WebGLRenderTarget(1, 1);

// 色変換関数
function convertRGB(color, mode, fRatio = 1.0) {
  // モードを設定 (例: 0 = Normal, 1 = Protanope, 2 = Deuteranope, 3 = Tritanope, 4 = Grayscale, 5 = Achromatomaly, 6 = 7 = 8 = Custom)
  dichromatShader.uniforms.mode.value = mode;
  dichromatShader.uniforms.fRario.value = fRatio;

  // テクスチャとしてカラーを適用
  const texture = new THREE.DataTexture(new Uint8Array([color.r, color.g, color.b, 255]), 1, 1, THREE.RGBAFormat);
  texture.needsUpdate = true;
  material.uniforms.uSampler = {value: texture};

  // オフスクリーンでレンダリング
  renderer.setRenderTarget(renderTarget);
  renderer.render(scene, camera);
  renderer.setRenderTarget(null);

  // レンダリング結果を読み取る
  const readBuffer = new Uint8Array(4);
  renderer.readRenderTargetPixels(renderTarget, 0, 0, 1, 1, readBuffer);

  // 変換後の色を取得
  return {
    r: readBuffer[0],
    g: readBuffer[1],
    b: readBuffer[2]
  };
}

function judgeColor(RGB, result, strict, background, judgeMode) {
  let judgeC = colorJudge.colorJudge(RGB, result, "c", strict, background, judgeMode)

  let judgeP = colorJudge.colorJudge(convertRGB(RGB, 6), convertRGB(result, 6), "p", strict, background, judgeMode)
  let judgeD = colorJudge.colorJudge(convertRGB(RGB, 7), convertRGB(result, 7), "d", strict, background, judgeMode)
  let judgeS = colorJudge.colorJudge(convertRGB(RGB, 8), convertRGB(result, 8), "s", strict, background, judgeMode)

  return !isAnyBatsu(judgeC, judgeP, judgeD, judgeS)
  // return isAllMaru(judgeC, judgeP, judgeD, judgeS)
}

function getAngularRGBColor(originalHSV, angle) {
  return hsvToRgb(((originalHSV[0] + angle) % 360 + 360) % 360, originalHSV[1], originalHSV[2])
}

// [label: string, angles: [number]]
const CandidateForms = [
  {label: "I", angles: [180]},
  {label: "V", angles: [36]},
  {label: "VV", angles: [-36, 36]},
  {label: "bird", angles: [144, 180, 216]},
  {label: "Y", angles: [144, 216]},
  {label: "triangle", angles: [120, 240]},
  {label: "sq", angles: [120, 180, 240]},
  {label: "square", angles: [90, 180, 270]},
  // {label: "pentagon", angles: [72, 144, 216, 288]},
  {label: "hexagon", angles: [60, 120, 180, 240, 300]},
]

// [{label: string, angles: number[], colors: [{r: number, g: number, b: number}]}]
export function suggestRGB(RGB, strict, background, judgeMode) {
  let results = []
  let originalHSV = rgbToHsv(RGB.r, RGB.g, RGB.b)

  CandidateForms.forEach(function (candidateForm) {
    // if (results.size >= limit) return
    const angularColors = candidateForm.angles.map(function (angle) {
      return getAngularRGBColor(originalHSV, angle)
    })
    if (angularColors.map(function (color) {
      return judgeColor(RGB, color, strict, background, judgeMode)
    }).every(Boolean)) {
      results.push({
        label: candidateForm.label,
        angles: candidateForm.angles,
        colors: angularColors
      })
    }
  })

  return results

  // for (let i = 2; results.size < limit && i <= 10; i++) {
  //   let interval = 360 / i
  //   for (let _ = 1; _ < i; _++) {
  //     let rgb = hsvToRgb((originalHSV[0] + interval * _) % 360, originalHSV[1], originalHSV[2])
  //     let result = {r: rgb[0], g: rgb[1], b: rgb[2]}
  //     if (judgeColor(RGB, result, strict, background)) {
  //       results.add(JSON.stringify(result))
  //     }
  //     if (results.size >= limit) break
  //   }
  // }
  //
  // return Array.from(results).map(JSON.parse)
}
