<template>
  <v-main style='background-color:black'>
    <InformationView/>
    <!--    <v-main-->
    <!--      style='background-color:black;'-->
    <!--    >-->
    <!--    <div class="v-main__wrap">-->
<!--    <v-container>-->
      <SimulatorTop/>
<!--    </v-container>-->
    <!--    </div>-->
    <!--    </v-main>-->
    <FooterView/>
  </v-main>
</template>

<script>
import InformationView from '@/components/Information.vue';
import FooterView from '@/components/Footer.vue';
import SimulatorTop from '@/components/SimulatorTop'

export default {
  name: 'App',
  components: {
    InformationView,
    FooterView,
    SimulatorTop
  },
  data: () => ({}),
};
</script>
