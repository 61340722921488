/* eslint-disable */
import { VERTEX_SHADER } from "./ShaderUtil.js";

// language=GLSL
const FRAGMENT_SHADER = `
#ifdef GL_ES
precision highp float;
#endif

uniform sampler2D uSampler;
varying vec2 vTexcoord;
uniform int mode;
uniform float fRario;

void main()
{
	mat3 matRGBtoLMS = mat3(	// Convert from RGB to LMS
		0.313935103000000,	0.639568792000000,	0.0465221350000000,
		0.155302922000000,	0.757959424000000,	0.0867268040000000,
		0.0177216460000000,	0.109451824000000,	0.872768110000000
	);

	mat3 matLMStoRGB = mat3(		// Convert from LMS to RGB
		5.47213088809908, -4.64189045809668, 0.169576672588330,
		-1.12464246757831, 2.29254888866891, -0.167862078994379,
		0.0299266238769310,	-0.193249175894864,	1.16338764138036
	);

	mat3 matN0 = mat3(			// Normal
		1.0, 0.0, 0.0,
		0.0, 1.0, 0.0,
		0.0, 0.0, 1.0
	);

	mat3 matP0 = mat3(			// Protanope
		0.0, 1.05118653815772, -0.0511520910889411,
		0.0, 1.0, 0.0,
		0.0, 0.0, 1.0
	);

	mat3 matP1 = mat3(			// Protanope
        0.0, 1.20800080913082, -0.207973822152729,
		0.0, 1.0, 0.0,
		0.0, 0.0, 1.0
	);

	mat3 matP2 = mat3(			// Protanope
        0.0, 1.22023002746029, -0.220203622260109,
		0.0, 1.0, 0.0,
		0.0, 0.0, 1.0
	);

	mat3 matP1_2 = mat3(			// Protanope
      // 0.49656501, 0.48134781, 0.03168775, 0.45464618, 0.44005436, 0.11875658, -0.00874817, -0.0075599, 1.04903652
       -0.3510841945109737, 1.4321593109683874, -0.06903784967550317, -0.3238801346986623, 1.3299062666058703, 0.0005514087913408147, -0.04364041408534757, 0.07068845854112385, 0.9438830723535638
       );

    mat3 matP2_2 = mat3(			// Protanope
      //  0.48121365, 0.53540224, -0.04560828, 0.49537036, 0.49249945, -0.00435606, 0.6672364, 0.09364506, 0.38333687
      -0.4148429765402678, 1.4753026343730087, -0.08468596575353204, -0.36072665086706573, 1.3612261302400155, -0.014252151908956304, 0.009289789666518842, 0.021772373873604804, 0.9605957314139573
       );

	mat3 matD0 = mat3(			// Deuteranope
		1.0, 0.0, 0.0,
		0.951305942095275, 0.0, 0.0486612882035085,
		0.0, 0.0, 1.0
	);

	mat3 matD1 = mat3(			// Deuteranope
		1.0, 0.0, 0.0,
        0.827814015058087, 0.0, 0.172163644743227,
		0.0, 0.0, 1.0
	);

	mat3 matD2 = mat3(			// Deuteranope
		1.0, 0.0, 0.0,
        0.819517613479266, 0.0, 0.180460746994094,
		0.0, 0.0, 1.0
	);

	mat3 matD1_2 = mat3(			// Deuteranope2
		0.540038320947501, 0.4860542810603009, -0.024832081520885257, 0.5041122374563839, 0.44132919653090164, 0.054410045381184595, 0.01621357253234187, -0.01363661476293155, 0.9900459929839606
	);

	mat3 matD2_2 = mat3(			// Deuteranope2
		0.4846310395085999, 0.5109761817786412, -0.016474581188412073, 0.4663363345613414, 0.4649436510657433, 0.05604661056884079, 0.009859219093331007, 0.011067310773416055, 0.9705658851185185
	);

	mat3 matT0 = mat3(			// Tritanope
		1.0, 0.0, 0.0,
		0.0, 1.0, 0.0,
		-0.867282128803180, 1.86726654399899, 0.0
	);

	mat3 matT1 = mat3(			// Tritanope
		1.0, 0.0, 0.0,
		0.0, 1.0, 0.0,
        -0.525432694548623, 1.52540450220051, 0.0
	);

	mat3 matT2 = mat3(			// Tritanope
		1.0, 0.0, 0.0,
		0.0, 1.0, 0.0,
        -0.875044108111990, 1.87502880957271, 0.0
	);

	mat3 matA2 = mat3(
	    0.8191661518012187, -0.2805764277317575, 0.004976290747430515,
	    0.22557228361538667, 0.29763573545934907, 0.008744174884758052,
	    0.35797742357292855, -0.5032963321936614, 0.6818058840866207
	);

	vec2 newTex = vTexcoord.xy;
	vec4 col = texture2D(uSampler, newTex).rgba;

	vec3 z = pow((col.rgb + 0.055) / 1.055, vec3(2.4)) * matRGBtoLMS;

    mat3 matN = float(mode == 0) * matN0;
	mat3 matP = float(mode == 1) * (float(z.z <= z.y) * matP1 + float(z.z > z.y) * matP2);
    mat3 matP_2 = float(mode == 6) * (float(z.z <= z.y) * matP1_2 + float(z.z > z.y) * matP2_2);
	mat3 matD = float(mode == 2) * (float(z.z <= z.x) * matD1 + float(z.z > z.x) * matD2);
    mat3 matD_2 = float(mode == 7) * (float(z.z <= z.x) * matD1_2 + float(z.z > z.x) * matD2_2);
	mat3 matT = float(mode == 3) * (float(z.y <= z.x) * matT1 + float(z.y > z.x) * matT2);
    mat3 matA_2 = float(mode == 8) * matA2;
	z = clamp(1.055 * pow(mix(z, z * (matN + matP + matD + matT + matP_2 + matD_2 + matA_2), fRario) * matLMStoRGB, vec3(1.0 / 2.4)) - 0.055, 0.0, 1.0);

	mat3 matG = mat3(			// Grayscale
		0.2126, 0.7152, 0.0722,
		0.2126, 0.7152, 0.0722,
        0.2126, 0.7152, 0.0722
	);

    mat3 matA = mat3(			// achromatomaly
		 0.797385620915033, 0, 0,
         0, 0.797385620915033, 0,
         0, 0, 0.597385620915033
	);

    vec3 res = float(mode == 4) * mix(texture2D(uSampler, newTex).rgb, col.rgb * matG, fRario)
        + float(mode == 5) * mix(texture2D(uSampler, newTex).rgb, col.rgb * matA, fRario)
        + float(mode != 4) * float(mode != 5) * z;
    gl_FragColor.rgb = res;
	gl_FragColor.a = 1.0;
}
`

export class Dichromats {
  constructor() {
    this.uniforms = {
      mode: { type: "i", value: 0 },
      fRario: { type: "f", value: 1.0 }
    };

    this.vertexShader = VERTEX_SHADER;
    this.fragmentShader = FRAGMENT_SHADER;
  }
}
