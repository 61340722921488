<template>
  <v-container
    id="base_container"
    style="height:100%;width:100%;"
    ref="container"
    v-resize="onResize"
  >
    <div
      ref="drawarea"
      class="drawarea"
      style="height:100%;width:100%;margin:0px 0px 56px 0px;"
    >
      <canvas
        id="mycanvas"
        height="100%"
        width="100%"
        style="margin:auto;position:fixed;top:0;right:0;bottom:0;left:0;z-index:1;"
        @click="onMouseClick"
      ></canvas>
      <canvas
        id="iconcanvas"
        height="100%"
        width="100%"
        style="margin:auto;position:fixed;top:0;right:0;bottom:0;left:0;z-index:2;pointer-events: none;"
      ></canvas>
      <canvas
        id="offscreen_canvas"
        style="height:0px;width:0px;display:none;"
      ></canvas>
    </div>
    <v-dialog
      v-model="dialog"
      width="90%"
      height="50%"
    >
      <v-card>
        <v-card-title class="result_title">
          <h3>判定結果</h3>
        </v-card-title>
        <div class="parent">
          <div class="detail_box result_div0">1色目</div>
          <div class="detail_box result_div0-1">
            <div class="col-2 p-0 h-full"
                 :style="{background: this.getRGBHex(clickPosition.length >= 2 ? clickPosition[0].colorC : null)}"></div>
            <div class="col-10 p-0"
                 style="display: flex;
                        justify-content: center;
                        align-items: center;">
              {{ this.getColorStrings(clickPosition.length >= 2 ? clickPosition[0].colorC : null) }}
            </div>
          </div>
          <div class="detail_box result_div1">2色目</div>
          <div class="detail_box result_div1-1">
            <div class="col-2 p-0 h-full"
                 :style="{background: this.getRGBHex(clickPosition.length >= 2 ? clickPosition[1].colorC : null)}"></div>
            <div class="col-10 p-0"
                 style="display: flex;
                        justify-content: center;
                        align-items: center;">
              {{ this.getColorStrings(clickPosition.length >= 2 ? clickPosition[1].colorC : null) }}
            </div>
          </div>
          <div class="box result_div2">総合判定</div>
          <div class="box result_div2-1">型名</div>
          <div class="box result_div2-2">判定</div>
          <div class="detail_box circle_bold result_div3 maru"
               v-if=isAllMaru(this.judgeC,this.judgeP,this.judgeD,this.judgeS)>
            ○
          </div>
          <div class="detail_box circle_bold result_div3 batsu"
               v-else-if="isAnyBatsu(this.judgeC,this.judgeP,this.judgeD,this.judgeS)">
            ×
          </div>
          <div class="detail_box circle_bold result_div3 sankaku"
               v-else>
            △
          </div>
          <div class="detail_box result_div3-1">C型</div>
          <div class="detail_box circle_bold result_div3-2"
               v-if="this.judgeC!==null"
               :style="{color: this.judgeC.color}">{{ this.judgeC.label }}
          </div>
          <div class="detail_box result_div4-1">P型</div>
          <div class="detail_box circle_bold result_div4-2"
               v-if="this.judgeP!==null"
               :style="{color: this.judgeP.color}">{{ this.judgeP.label }}
          </div>
          <div class="detail_box result_div5-1">D型</div>
          <div class="detail_box circle_bold result_div5-2"
               v-if="this.judgeD!==null"
               :style="{color: this.judgeD.color}">{{ this.judgeD.label }}
          </div>
          <div class="detail_box result_div6-1">高齢者</div>
          <div class="detail_box circle_bold result_div6-2"
               v-if="this.judgeS!==null"
               :style="{color: this.judgeS.color}">{{ this.judgeS.label }}
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="default"
            @click="suggestColors"
          >
            <h3>提案</h3>
          </v-btn>
          <v-btn
            color="default"
            @click="suggestOtherWindow()"
          >
            <h3>別タブで提案</h3>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="default"
            @click="init()"
          >
            <h3>閉じる</h3>
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
        <div v-if="suggestedColors !== undefined">
          <div v-if="suggestedColors.length !== 0">
            <div v-for="c of suggestedColors" v-bind:key="c.label"
                 class="flex">
              <div class="detail_box col-3 p-0">
                <suggest-image size="2rem" :angles="[...c.angles, 0]"/>

              </div>
              <div class="detail_box col-2 p-0"
                   :style="{background: getRGBHex(clickPosition.length >= 2 ? clickPosition[0].colorC : null)}"></div>
              <div class="detail_box col-7 p-0">
                <div v-bind:class="'col-4 p-0 h-full grid grid-cols-' + c.colors.length">
                  <div
                    v-for="(rgb, index) in c.colors"
                    v-bind:key="index"
                    @click="updateSuggestionColorIndex(c.label, index)"
                    :style="{background: getRGBHex(rgb), borderColor: '#f00', borderWidth: index === selectedSuggestionIndex[c.label] ? 'thin' : ''}">
                  </div>
                </div>
                <div class="col-8 p-0"
                     style="display: flex;
                        justify-content: center;
                        align-items: center;">
                  {{ getColorStrings(c.colors[selectedSuggestionIndex[c.label]]) }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            候補色が見つかりません。
          </div>
        </div>
      </v-card>
    </v-dialog>
    <div>
      <OffscreenCanvas ref="offcanvas_p" :mode="6"/>
      <OffscreenCanvas ref="offcanvas_d" :mode="7"/>
      <OffscreenCanvas ref="offcanvas_s" :mode="8"/>
    </div>
  </v-container>
</template>

<script>
import OffscreenCanvas from './OffscreenCanvas.vue'
import SuggestImage from "@/components/SuggestImage.vue";

import * as THREE from 'three'
import {CataractShader} from '@/shaders/CataractShader'
import {Dichromats} from '@/shaders/Dichromats'
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer.js'
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js'
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass.js'
import colorJudge, {isAllMaru, isAnyBatsu} from '../utils/judge/colorjudge'
import FocusImage from '../assets/2x_outline_center_focus_strong_black_24dp.png'
import chromajs from 'chroma-js'
import {suggestRGB} from "@/utils/suggest/suggest";

import "@/utils/analysis/analyze"

export default {
  name: 'SimulatorTop',
  components: {
    OffscreenCanvas,
    SuggestImage
  },
  data: () => ({
    isUploading: false,
    video: {},
    canvas: {},
    captures: [],
    min: 0,
    max: 100,
    ratio: 100,
    zoom: 100,
    mode: 0,
    image: undefined,
    mycanvasHeight: 0,
    mycanvasWidth: 0,
    scene: undefined,
    camera: undefined,
    renderer: undefined,
    composer: undefined,
    offscreenCanvas: undefined,
    renderPass: undefined,
    shaderPass: undefined,
    mesh: undefined,
    texture: undefined,
    geometry: undefined,
    material: undefined,
    focusImage: null,
    clickPosition: [],
    scale: 1,
    isMounted: false,
    judgeC: null,
    judgeP: null,
    judgeD: null,
    judgeS: null,
    dialog: false,
    strict: 1, // ふつう
    background: 3,
    judgeMode: 0, // 実用
    colorDisplayMode: "lab",

    suggestedColors: undefined,
    selectedSuggestionIndex: {},
  }),
  mounted: function () {
    /* eslint-disable no-debugger */
    // debugger
    /* eslint-disable no-debugger */


    /*      this.video = this.$refs.video
          if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
              this.video.srcObject = stream
              this.video.play()
            })
          }
    */
    // Vuexの変更を受信
    this.$store.subscribe(
      (mutation, state) => {
        if (mutation.type === 'setImageFile') {
          // 画像ファイルがセットされた
          console.log(state.imageFile)
          this.onImageChange(state.imageFile)
        }
        if (mutation.type === 'setMode') {
          console.log(state.mode)
          this.onChangeMode(state.mode)
        }
        if (mutation.type === "setStrict") {
          this.strict = state.strict
        }
        if (mutation.type === "setBackground") {
          console.log(state.background)
          this.background = state.background
        }
        if (mutation.type === "setJudgeMode") {
          console.log(state.judgeMode)
          this.judgeMode = state.judgeMode
        }
        if (mutation.type === "setColorDisplayMode") {
          this.colorDisplayMode = state.colorDisplayMode
        }
      })

    this.isMounted = true
    this.offscreenCanvas = document.querySelector('#offscreen_canvas')
    this.onResize()
    // 初期画像をロード
    var file = this.$store.getters.imageFile
    this.setImage(file);
    this.setFocusImage();
  },
  watch: {
    zoom() {
      console.log("watchzoom")
      //this.updateImage()
    },
    dialog(old, n) {
      if (n) {
        this.init()
      }
    }
  },
  computed: {
    clickCount: {
      get() {
        return this.$store.getters.clickCount
      },
      set(value) {
        this.$store.commit('setClickCount', value)
      },
    }
  },
  methods: {
    isAnyBatsu,
    isAllMaru,
    // ウィンドウサイズが変更された
    onResize() {
      console.log("onResize")
      if (!this.isMounted) {
        return
      }
      this.updateImage()
    },
    // BASE64デコード
    getBase64(file) {
      console.log("getBase64")
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 画像が変更された
    onImageChange(file) {
      console.log("onImageChange")
      if (!this.isMounted) {
        return
      }
      if (!file) {
        console.log("Error")
        return
      }
      console.log("Success")

      this.getBase64(file)
        .then(image => {
          this.setImage(image)
        })
        .catch(error => this.setError(error, '画像のアップロードに失敗しました。'))
    },
    // 画像のセット
    setImage(imageFile) {
      console.log('setImage')
      this.image = new Image();
      this.image.onload = () => {
        this.updateImage()
      }
      this.image.src = imageFile
    },
    setFocusImage() {
      this.focusImage = new Image();
      this.focusImage.src = FocusImage;  // 画像のURLを指定
      this.focusImage.image.onload = () => {
      }
    },
    // シミュレーションモード変更
    onChangeMode(mode) {
      console.log('onChangeMode')
      if (!this.isMounted) {
        return;
      }
      this.mode = mode
      this.updateImage()
    },
    // 描画更新
    updateImage() {
      console.log('updateImage')
      if (!this.isMounted) {
        return
      }

      if (this.image === undefined) {
        // 画像が読み込まれていないときは何もしない
        return
      }
      var imgWidth = this.image.width
      var imgHeight = this.image.height

      // コンテナのサイズを取得（高さはfooterの高さ分56を引く）
      var containerHeight = this.$refs.drawarea.clientHeight - 56
      var containerWidth = this.$refs.drawarea.clientWidth

      if (this.composer) {
        this.composer.removePass(this.shaderPass)
        this.composer.removePass(this.rednerPass)
        delete this.composer
        this.composer = null
      }
      if (this.shaderPass) {
        delete this.shaderPass
        this.shaderPass = null
      }
      if (this.scene) {
        this.scene.remove(this.mesh);
        this.mesh.material.dispose()
        this.mesh.geometry.dispose()
        delete this.mesh
        delete this.geometry
        delete this.scene
        this.mesh = null
        this.geometry = null
        this.scene = null
      }
      if (this.renderer) {
        this.renderer.dispose()
        delete this.renderer
        this.renderer = null
      }
      if (this.texture) {
        this.texture.dispose()
        delete this.texture
        this.texture = null
      }

      //debugger
      // スケールを取得
      this.scale = Math.min(containerWidth / imgWidth, containerHeight / imgHeight);
      console.log("scale=" + this.scale)

      // 描画するキャンバスを取得
      this.mycanvasHeight = imgHeight * this.scale
      this.mycanvasWidth = imgWidth * this.scale

      var iconCanvas = document.querySelector('#iconcanvas')
      iconCanvas.width = this.mycanvasWidth
      iconCanvas.height = this.mycanvasHeight

      // オフスクリーン領域をcanvasに合わせる
      this.offscreenCanvas.width = this.mycanvasWidth
      this.offscreenCanvas.height = this.mycanvasHeight
      var offscreenContext = this.offscreenCanvas.getContext('2d')
      //offscreenContext.fillStyle = 'gray'
      //offscreenContext.fillRect(0, 0, this.mycanvasWidth, this.mycanvasHeight)
      offscreenContext.clearRect(0, 0, this.mycanvasWidth, this.mycanvasHeight)
      offscreenContext.scale(this.scale, this.scale)
      offscreenContext.drawImage(this.image, 0, 0, imgWidth, imgHeight,
        (this.mycanvasWidth - imgWidth * this.scale) / 2,
        (this.mycanvasHeight - imgHeight * this.scale) / 2,
        imgWidth, imgHeight)
      offscreenContext.scale(1 / this.scale, 1 / this.scale)

      // シーン
      this.scene = new THREE.Scene()

      // レンダラー
      var myCanvas = document.querySelector('#mycanvas')
      this.renderer = new THREE.WebGLRenderer({canvas: myCanvas, preserveDrawingBuffer: true})
      this.renderer.setClearColor(0xffffff, 1)
      this.renderer.setSize(this.mycanvasWidth, this.mycanvasHeight)
      this.renderer.setPixelRatio(window.devicePixelRatio)

      // カメラ
      this.camera = new THREE.OrthographicCamera(this.mycanvasWidth / -2, this.mycanvasWidth / 2, this.mycanvasHeight / 2, this.mycanvasHeight / -2, 1, 1000)
      this.camera.position.set(0, 0, 1)

      // 画像を読み込む
      this.texture = new THREE.CanvasTexture(this.offscreenCanvas)
      this.texture.minFilter = THREE.LinearFilter
      this.texture.magFilter = THREE.LinearFilter
      this.geometry = new THREE.PlaneGeometry(this.mycanvasWidth, this.mycanvasHeight, 1, 1)
      this.material = new THREE.MeshBasicMaterial({map: this.texture})

      this.mesh = new THREE.Mesh(this.geometry, this.material)
      this.mesh.scale.set(3, 3, 3)
      this.scene.add(this.mesh)
      this.composer = new EffectComposer(this.renderer)
      this.renderPass = new RenderPass(this.scene, this.camera)
      this.composer.addPass(this.renderPass)

      let shader;
      if (this.mode === 3) {
        shader = new CataractShader()
      } else {
        shader = new Dichromats()
        shader.uniforms.mode = {type: 'i', value: this.mode}
        shader.uniforms.fRario = {type: 'f', value: this.ratio / 100}
      }
      this.shaderPass = new ShaderPass(shader)
      this.composer.addPass(this.shaderPass)

      // P型用オフスクリーンバッファ
      this.$refs.offcanvas_p.updateImage(
        this.mycanvasWidth,
        this.mycanvasHeight,
        this.renderPass,
      )
      // D型用オフスクリーンバッファ
      this.$refs.offcanvas_d.updateImage(
        this.mycanvasWidth,
        this.mycanvasHeight,
        this.renderPass,
      )
      // T型用オフスクリーンバッファ
      this.$refs.offcanvas_s.updateImage(
        this.mycanvasWidth,
        this.mycanvasHeight,
        this.renderPass,
      )

      this.render()
    },
    // Threejsレンダリング
    render() {
      console.log("render")
      this.composer.render()
      // 以下フレームレートでアニメーションをする場合
      // requestAnimationFrame(this.render)
      this.drawFocusImage()
    },
    onMouseClick(value) {
      console.log("onMouseClick1")
      var rect = value.target.getBoundingClientRect()
      var clickPosition = {x: Math.round(value.clientX - rect.left), y: Math.round(value.clientY - rect.top)}
      console.log("clickPosition.x=" + clickPosition.x + " clickPosition.y=" + clickPosition.y)

      if (this.clickCount < 2) {
        var offscreenContext = this.offscreenCanvas.getContext('2d')
        var imageData = offscreenContext.getImageData(clickPosition.x, clickPosition.y, 1, 1)
        var colorC = {
          r: imageData.data[0],
          g: imageData.data[1],
          b: imageData.data[2],
          a: imageData.data[3],
        }
        console.log("RC=" + colorC.r + " GC=" + colorC.g + " BC=" + colorC.b + " AC=" + colorC.a)

        var colorP = this.$refs.offcanvas_p.onMouseClick(clickPosition.x, clickPosition.y)
        console.log("RP=" + colorP.r + " GP=" + colorP.g + " BP=" + colorP.b + " AP=" + colorP.a)
        var colorD = this.$refs.offcanvas_d.onMouseClick(clickPosition.x, clickPosition.y)
        console.log("RD=" + colorD.r + " GD=" + colorD.g + " BD=" + colorD.b + " AD=" + colorD.a)
        var colorS = this.$refs.offcanvas_s.onMouseClick(clickPosition.x, clickPosition.y)
        console.log("RS=" + colorS.r + " GS=" + colorS.g + " BS=" + colorS.b + " AS=" + colorS.a)

        console.log(this.focusImage.width)
        console.log(this.focusImage.height)
        this.clickPosition[this.clickCount] = {
          pos: clickPosition,
          colorC: colorC,
          colorP: colorP,
          colorD: colorD,
          colorS: colorS
        }
        this.clickCount++
        this.drawFocusImage()

        if (this.clickCount === 2) {
          // console.log(suggestRGB(this.clickPosition[0].colorC))
          this.judgeC = colorJudge.colorJudge(this.clickPosition[0].colorC, this.clickPosition[1].colorC, "c", this.strict, this.background, this.judgeMode)
          this.judgeP = colorJudge.colorJudge(this.clickPosition[0].colorP, this.clickPosition[1].colorP, "p", this.strict, this.background, this.judgeMode)
          this.judgeD = colorJudge.colorJudge(this.clickPosition[0].colorD, this.clickPosition[1].colorD, "d", this.strict, this.background, this.judgeMode)
          this.judgeS = colorJudge.colorJudge(this.clickPosition[0].colorS, this.clickPosition[1].colorS, "s", this.strict, this.background, this.judgeMode)
          console.log(this.judgeC, this.judgeP, this.judgeD, this.judgeS)
          this.dialog = true
        }
      }
    },
    drawFocusImage() {
      for (var i = 0; i < this.clickCount; i++) {
        var iconCanvas = document.querySelector('#iconcanvas')
        var iconContext = iconCanvas.getContext('2d')
        iconContext.drawImage(this.focusImage,
          this.clickPosition[i].pos.x - (this.focusImage.width / 2),
          this.clickPosition[i].pos.y - (this.focusImage.height / 2))
      }
    },
    init() {
      console.log("init")
      this.clickCount = 0
      this.judgeC = false
      this.judgeP = false
      this.judgeD = false
      this.judgeS = false
      this.clickPosition = []
      this.updateImage()
      this.dialog = false
      this.suggestedColors = undefined
    },
    getRGBHex(color) {
      if (color === null || color === undefined) return ""
      let chroma = chromajs(color.r, color.g, color.b)
      return chroma.hex()
    },
    getColorStrings(color) {
      if (color === null || color === undefined) return ""

      const chroma = chromajs(color.r, color.g, color.b)
      switch (this.colorDisplayMode) {
        case "lab":
          var lab = chromajs(color.r, color.g, color.b).lab()
          return `L: ${lab[0].toFixed(0)} a:${lab[1].toFixed(0)} b: ${lab[2].toFixed(0)}`
        case "cmyk":
          var cmyk = chroma.cmyk();
          return `C: ${cmyk[0].toFixed(2) * 100} M:  ${cmyk[1].toFixed(2) * 100} Y: ${cmyk[2].toFixed(2) * 100} K: ${cmyk[3].toFixed(2) * 100}`
        case "rgb":
          return `R: ${color.r} G: ${color.g} B: ${color.b}`
      }
    },
    suggestColors() {
      this.suggestedColors = suggestRGB(this.clickPosition[0].colorC, this.strict, this.background, this.judgeMode)
    },
    updateSuggestionColorIndex(label, index) {
      this.$set(this.selectedSuggestionIndex, label, index)
      // this.selectedSuggestionIndex[label] = index;
      // console.log(this.selectedSuggestionIndex)
    },
    suggestOtherWindow() {
      window.open('./suggest?' + new URLSearchParams({
        r: this.clickPosition[0].colorC.r,
        g: this.clickPosition[0].colorC.g,
        b: this.clickPosition[0].colorC.b,
        strict: this.strict,
        background: this.background,
        judgeMode: this.judgeMode,
        colorDisplayMode: this.colorDisplayMode,
      }).toString())
    }
  },
}
</script>

<style scoped>
.parent {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(1fr);
  font-size: 20px;
  border: 1px solid;
  border-color: black;
}

.box {
  font-size: 20px;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: darkblue;
  border: 1px solid;
  border-color: black;
}

.detail_box {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid;
  border-color: black;
}

.result_title {
  font-size: 20px;
  font-weight: bold;
  color: white;
  background: darkblue;

}

.circle_bold {
  font-size: 30px;
  text-shadow: 1px 1px 0,
  1px -1px 0px,
  -1px 1px 0,
  -1px -1px 0px,
  1px 0px 0px,
  0px 1px 0px,
  -1px 0px 0px,
  0px -1px 0px;
  letter-spacing: 1px;
}

.batsu {
  color: blue;
  font-size: 120px;
}

.sankaku {
  color: #ffdd00;
  font-size: 90px;
}

.maru {
  color: red;
  font-size: 90px;
}


/* 1色目 */
.result_div0 {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

/* 1色目Lab */
.result_div0-1 {
  grid-column: 2 / 4;
  grid-row: 1 / 2;
}

/* 2色目 */
.result_div1 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

/* 2色目Lab */
.result_div1-1 {
  grid-column: 2 / 4;
  grid-row: 2 / 3;
}

/* 総合判定 */
.result_div2 {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

/* 型名  */
.result_div2-1 {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
}

/* 判定  */
.result_div2-2 {
  grid-column: 3 / 4;
  grid-row: 3 / 4;
}

/* 総合判定〇 */
.result_div3 {
  grid-column: 1 / 2;
  grid-row: 4 / 8;
}

/* C型 */
.result_div3-1 {
  grid-column: 2 / 3;
  grid-row: 4 / 5;
}

.result_div3-2 {
  grid-column: 3 / 4;
  grid-row: 4 / 5;
}

/* P型 */
.result_div4-1 {
  grid-column: 2 / 3;
  grid-row: 5 / 6;
}

.result_div4-2 {
  grid-column: 3 / 4;
  grid-row: 5 /6;
}

/* D型 */
.result_div5-1 {
  grid-column: 2 / 3;
  grid-row: 6 / 7;
}

.result_div5-2 {
  grid-column: 3 / 4;
  grid-row: 6 / 7;
}

/* 高齢者 */
.result_div6-1 {
  grid-column: 2 / 3;
  grid-row: 7 / 8;
}

.result_div6-2 {
  grid-column: 3 / 4;
  grid-row: 7 / 8;
}

</style>
