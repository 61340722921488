var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{attrs:{"padless":"","fixed":""}},[_c('v-toolbar',{staticClass:"overflow-x-scroll",staticStyle:{"height":"100%"},attrs:{"align-start":""}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"my-auto mx-2 hidden md:block"},[_vm._v(" シミュレーション ")]),_c('v-select',{staticStyle:{"max-width":"150px"},attrs:{"items":[
      {'text': 'C型', 'value': 0},
      {'text': 'P型②', 'value': 6},
      {'text': 'D型②', 'value': 7},
      {'text': '高齢者②', 'value': 8} ],"hide-details":"auto"},on:{"change":_vm.onChangeMode},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"my-auto mx-2 hidden md:block"},[_vm._v(" 判定基準 ")]),_c('v-select',{staticStyle:{"max-width":"150px"},attrs:{"items":Object.entries(_vm.StrictMode).map(function (ref) {
    var _ = ref[0];
    var sm = ref[1];
return {'obj': _,'text': sm.label, 'value': sm.value}}),"hide-details":"auto"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.strict),callback:function ($$v) {_vm.strict=$$v},expression:"strict"}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"my-auto mx-2 hidden md:block"},[_vm._v(" 背景 ")]),_c('v-select',{staticStyle:{"max-width":"150px"},attrs:{"items":Object.entries(_vm.BackgroundMode).map(function (ref) {
    var _ = ref[0];
    var sm = ref[1];
return {'obj': _,'text': sm.label, 'value': sm.value}}),"hide-details":"auto"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.background),callback:function ($$v) {_vm.background=$$v},expression:"background"}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"my-auto mx-2 hidden md:block"},[_vm._v(" 表示 ")]),_c('v-select',{staticStyle:{"max-width":"150px"},attrs:{"items":[
          {'text': 'Lab', 'value': 'lab'},
          {'text': 'CMYK', 'value': 'cmyk'},
          {'text': 'RGB', 'value': 'rgb'} ],"hide-details":"auto"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.colorDisplayMode),callback:function ($$v) {_vm.colorDisplayMode=$$v},expression:"colorDisplayMode"}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"my-auto mx-2 hidden md:block"},[_vm._v(" 判定方法 ")]),_c('v-select',{staticStyle:{"max-width":"150px"},attrs:{"items":Object.entries(_vm.JudgeMode).map(function (ref) {
        var _ = ref[0];
        var sm = ref[1];
return {'obj': _,'text': sm.label, 'value': sm.value}}),"hide-details":"auto"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.judgeMode),callback:function ($$v) {_vm.judgeMode=$$v},expression:"judgeMode"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","elevation":"2","large":""},on:{"click":_vm.onClickCameraButton}},[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v("mdi-camera")])],1),_c('v-btn',{attrs:{"icon":"","elevation":"2","large":""},on:{"click":_vm.onClickUploadButton}},[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v("mdi-image")])],1)],1),_c('input',{ref:"input_file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/jpeg, image/jpg, image/png"},on:{"change":_vm.onCaptureImage}}),_c('input',{ref:"input_camera",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*","capture":"user"},on:{"change":_vm.onCaptureImage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }