/* eslint-disable */

import chroma from 'chroma-js'
import {getMunsell} from "@/utils/munsell/infer_pccs";
import {C_Threshold_Brightness, C_Threshold_Hue} from "@/utils/judge/threshold/js/C_threshold";
import {P_Threshold_Brightness, P_Threshold_Hue} from "@/utils/judge/threshold/js/P_threshold";
import {D_Threshold_Brightness, D_Threshold_Hue} from "@/utils/judge/threshold/js/D_threshold";
import {T_Threshold_Brightness, T_Threshold_Hue} from "@/utils/judge/threshold/js/T_threshold";
import {JudgeMode} from "@/utils/judge/threshold/judge_data";

const empty_threshold = {
  "R_R": 0, "R_L": 0, "YR_R": 0, "YR_L": 0, "Y_R": 0, "Y_L": 0, "GY_R": 0, "GY_L": 0, "G_R": 0, "G_L": 0, "BG_R": 0, "BG_L": 0, "B_R": 0, "B_L": 0, "PB_R": 0, "PB_L": 0, "P_R": 0, "P_L": 0, "RP_R": 0, "RP_L": 0, "N": 0,
}

// enumのつもり
export const JudgeResult = Object.freeze({
  Maru: {
    label: "◯",
    value: 2,
    color: "#ff0000"
  },
  Sankaku: {
    label: "△",
    value: 1,
    color: "#ffdd00"
  },
  Batsu: {
    label: "×",
    value: 0,
    color: "#0000ff"
  },
})


export function isAllMaru(...res) {
  let r = true
  res.forEach((v) => {
    if (v !== null && v.label !== JudgeResult.Maru.label) {
      r = false
    }
  })
  return r;
}

export function isAnyBatsu(...res) {
  let r = false
  res.forEach((v) => {
    console.log(v)
    if (v !== null && v.label === JudgeResult.Batsu.label) {
      r = true
    }
  })
  return r;
}

// 左は0-5, 右は5-10(マンセルの数字的)
// eが色差(色相差), lが明度差
// 色相差と彩度差は実質的に同じことを表しているので、結局は大きい方を採用することになる。
function judge(color1, color2, de00, dl, threshold_hue, threshold_chroma, threshold_brightness, judgeMode) {
  const munsell1 = getMunsell([color1.r, color1.g, color1.b]) || "N";
  const munsell2 = getMunsell([color2.r, color2.g, color2.b]) || "N";

  const th_e = Math.max(threshold_hue[munsell1], threshold_hue[munsell2], threshold_chroma[munsell1], threshold_chroma[munsell2])
  const th_l = Math.max(threshold_brightness[munsell1], threshold_brightness[munsell2])
  let eres, lres;
  if (de00 >= th_e * 1) {
    eres = JudgeResult.Maru
  } else if (de00 <= th_e * 0.8) {
    eres = JudgeResult.Batsu
  } else {
    eres = JudgeResult.Sankaku
  }
  if (dl >= th_l * 1) {
    lres = JudgeResult.Maru
  } else if (dl <= th_l * 0.8) {
    lres = JudgeResult.Batsu
  } else {
    lres = JudgeResult.Sankaku
  }

  function print_color(color, munsell) {
    console.log(`%c   `, 'background-color: ' + chroma(color.r, color.g, color.b).hex(), `[${color.r}, ${color.g}, ${color.b}] ${munsell}`)
  }

  print_color(color1, munsell1)
  print_color(color2, munsell2)

  console.log("ΔE:", parseInt(de00), "基準:", th_e, eres.label)
  console.log("ΔL:", parseInt(dl), "基準:", th_l, lres.label)

  function getJitsuyou() {
    let val = lres.value + eres.value
    if (val >= 3) {
      return JudgeResult.Maru
    }
    if (val <= 1) {
      return JudgeResult.Batsu
    }
    return JudgeResult.Sankaku;
  }

  function getSaiteigen() {
    if (lres.label === JudgeResult.Maru.label || eres.label === JudgeResult.Maru.label) {
      return JudgeResult.Maru
    }
    if (lres.label === JudgeResult.Batsu.label && eres.label === JudgeResult.Batsu.label) {
      return JudgeResult.Batsu
    }
    return JudgeResult.Sankaku;
  }

  function getGenmitsu() {
    if (lres.label === JudgeResult.Maru.label && eres.label === JudgeResult.Maru.label) {
      return JudgeResult.Maru
    }
    if (lres.label === JudgeResult.Batsu.label || eres.label === JudgeResult.Batsu.label) {
      return JudgeResult.Batsu
    }
    return JudgeResult.Sankaku;
  }

  switch (judgeMode) {
    case JudgeMode.Jitsuyou.value:
      return getJitsuyou()
    case JudgeMode.Saiteigen.value:
      return getSaiteigen()
    case JudgeMode.ChoGenmitsu.value:
      return getGenmitsu()
  }

}

/*
 * ΔE00 の計算
 */
function dE00(L1, a1, b1, L2, a2, b2) {
  L1 = parseFloat(L1);
  a1 = parseFloat(a1);
  b1 = parseFloat(b1);
  L2 = parseFloat(L2);
  a2 = parseFloat(a2);
  b2 = parseFloat(b2);

  var pi = Math.PI;

  function sin_deg(d) {
    return Math.sin(d * pi / 180);
  }

  function cos_deg(d) {
    return Math.cos(d * pi / 180);
  }

  function atan2_deg(x, y) {
    if (y == 0) {
      return (x > 0) ? 0 : 180;
    } else {
      if (x != 0) {
        return Math.atan2(y, x) * 180 / pi;
      } else {
        return (y > 0) ? 90 : -90;
      }
    }
  }

  var aveL = (L1 + L2) / 2;
  var dL = L2 - L1;

  var C1 = Math.sqrt(a1 * a1 + b1 * b1);
  var C2 = Math.sqrt(a2 * a2 + b2 * b2);
  var aveC = (C1 + C2) / 2;
  var G = 0.5 * (1 - Math.sqrt(Math.pow(aveC, 7) / (Math.pow(aveC, 7) + Math.pow(25, 7))));
  var _a1 = a1 * (1 + G);
  var _b1 = b1 * 1;
  var _a2 = a2 * (1 + G);
  var _b2 = b2 * 1;

  var _c1 = Math.sqrt(_a1 * _a1 + _b1 * _b1);
  var _c2 = Math.sqrt(_a2 * _a2 + _b2 * _b2);
  var _aveC = (_c1 + _c2) / 2;
  var _dC = _c2 - _c1;

  var H1 = atan2_deg(_a1, _b1);
  var H2 = atan2_deg(_a2, _b2);
  var dH;

  var aveH;
  if (Math.abs(H2 - H1) <= 180) {
    dH = H2 - H1;
    aveH = (H1 + H2) / 2;
  } else {
    dH = (H2 <= H1) ? H2 - H1 + 360 : H2 - H1 - 360;
    aveH = (H1 + H2 + 360) / 2;
  }

  var _dH = 2 * Math.sqrt(_c1 * _c2) * sin_deg(dH / 2);
  var T = 1
    - 0.17 * cos_deg(aveH - 30)
    + 0.24 * cos_deg(2 * aveH)
    + 0.32 * cos_deg(3 * aveH + 6)
    - 0.2 * cos_deg(4 * aveH - 63);

  var SL = 1 + 0.015 * Math.pow(aveL - 50, 2) / Math.sqrt(20 + Math.pow(aveL - 50, 2));
  var SC = 1 + 0.045 * _aveC;
  var SH = 1 + 0.015 * _aveC * T;

  var RT = -2
    * Math.sqrt(Math.pow(_aveC, 7) / (Math.pow(aveC, 7) + Math.pow(25, 7)))
    * sin_deg(60 * Math.exp(-Math.pow((aveH - 275) / 25, 2)));

  return Math.sqrt(
    Math.pow(dL / SL, 2)
    + Math.pow(_dC / SC, 2)
    + Math.pow(_dH / SH, 2)
    + RT * (_dC / SC) * (_dH / SH));
}

/*
 * 色判定
 * color1 : 1色目
 * color2 : 2色目
 * mode   : 判定モード 'c':通常 'p':p型 'd':D型 's'：高齢者
 * strict : StrictModeのvalue
 * background : BackgroundModeのvalue
 */
function colorJudge(color1, color2, mode, strict, background, judgeMode) {
  let lab1 = chroma(color1.r, color1.g, color1.b).lab()
  let lab2 = chroma(color2.r, color2.g, color2.b).lab()

  let de00 = dE00(lab1[0], lab1[1], lab1[2], lab2[0], lab2[1], lab2[2]);
  let dl = Math.abs(lab1[0] - lab2[0]);

  console.log(mode, strict, background, judgeMode);
  let check = true
  switch (mode) {
    case 'c':
      check = judge(color1, color2, de00, dl, C_Threshold_Hue[strict][background], empty_threshold , C_Threshold_Brightness[strict][background], judgeMode);
      break;
    case 'p':
      check = judge(color1, color2, de00, dl, P_Threshold_Hue[strict][background], empty_threshold, P_Threshold_Brightness[strict][background], judgeMode);
      break;
    case 'd':
      check = judge(color1, color2, de00, dl, D_Threshold_Hue[strict][background], empty_threshold, D_Threshold_Brightness[strict][background], judgeMode)
      break;
    case 's':
      check = judge(color1, color2, de00, dl, T_Threshold_Hue[strict][background], empty_threshold, T_Threshold_Brightness[strict][background], judgeMode)
      break;
  }
  return check
}

export default {
  colorJudge,
};

