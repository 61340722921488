<template>
  <v-app
    style="overflow:hidden"
    id="app">
    <v-app-bar
      app
      color="primary"
      dark
      dense
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="35"
        />
        <v-app-bar-title
          justify="center"
        >
          <h2>MUD Judge</h2>
        </v-app-bar-title>
      </div>
      <button type="button" class="text-white bg-gray-800  font-medium rounded-lg text-sm ml-auto px-4 py-2"
              v-on:click="logout">ログアウト
      </button>
    </v-app-bar>
    <router-view v-if="submitted"></router-view>
    <div v-if="!submitted">
      <br><br><br><br>
      <Login :set-user="setUser"/>
    </div>
  </v-app>
</template>

<script>
import {firebase} from "@/composables/firebaseInit.js";
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import Login from "@/pages/login.vue";

export default {
  name: 'App',
  components: {
    Login
  },
  data: () => ({
    ID: "",
    password: "",
    submitted: false,

    user: {},
    email: "",
  }),
  methods: {
    async beforeMount() {
      const auth = getAuth(firebase)
      const setUser = this.setUser
      await new Promise(function () {
        onAuthStateChanged(auth, (newUser) => {
          setUser(newUser)
        });
      });
    },
    setUser: async function (newUser) {
      const auth = getAuth(firebase)
      if (!newUser) return
      const serviceID = 2
      const token = await newUser.getIdTokenResult()
      if (token.claims[serviceID]) {
        if (token.claims[serviceID].start._seconds <= new Date().getTime() / 1000 && new Date().getTime() / 1000 <= token.claims[serviceID].end._seconds){
          this.user = newUser
          this.email = newUser.email ?? ""
          this.submitted = true
        } else {
          await auth.signOut()
          alert("ご利用可能期間外です。")
        }
      } else {
        await auth.signOut()
        alert("ご利用のプランではこのサービスは利用できません")
      }
    },
    logout: function () {
      if (confirm("ログアウトしますか？")) {
        this.submitted = false
        const auth = getAuth(firebase);
        signOut(auth).then(() => {
          location.href = "/"
        }).catch(() => {
          alert("ログアウト時にエラーが発生しました")
        });
      }
    },
  }
};
</script>
